import request from "@/utils/request.js";
// /member-server/member/shop/page
// 门店分页
export const shopPage = ((data) => {
    return request.postt('/member-server/member/shop/page', data)
})
//添加门店 
export const shopAdd = ((data) => {
    return request.post('/member-server/member/shop/add', data)
})
//门店删除
export const shopDel = ((data) => {
    return request.postt('/member-server/member/shop/del', data)
})
//更新门店信息  
export const shopUpdate = ((data) => {
    return request.post('/member-server/member/shop/update', data)
})
//商品信息
export const goodPage = ((data) => {
    return request.postt('/finance-server/good/page', data)
})
// 新增商品信息
export const newcommodity = ((data) => {
    return request.post('/finance-server/good/add', data)
})
// 商品分类
export const categoryPage = ((data) => {
    return request.postt('/finance-server/good/category/page', data)
})
// 新增商品分类
export const newclassify = ((data) => {
    return request.postt('/finance-server/good/category/add', data)
})
// 更新商品分类状态
export const categoryStatus = ((data) => {
    return request.postt('/finance-server/good/category/update/status', data)
})
// 删除商品信息
export const delgoods = ((data) => {
    return request.postt('/finance-server/good/del', data)
})

// 删除商品分类
export const delcategory = ((data) => {
    return request.postt('/finance-server/good/category/del', data)
})
// 更新商品信息状态
export const goodstatus = ((data) => {
    return request.postt('/finance-server/good/update/status', data)
})
// 更新商品信息
export const goodUpdate = ((data) => {
    return request.post('/finance-server/good/update', data)
})
// 查询商品分类集合
export const goodlist = ((data) => {
    return request.postt('/finance-server/good/category/list', data)
})
//订单分页
export const orderPage = ((data) => {
    return request.post('/finance-server/finance/order/page', data)
})

//商品集合
export const goodList = ((data) => {
    return request.postt('/finance-server/good/list', data)
})
// 同步会员付费服务
export const infoPayServeSyn = ((data) => {
    return request.post('/member-server/member/info/pay/serve/syn', data)
})
// 根据参数查询会员服务分页
export const vipServiceRefPage = ((data) => {
    return request.postt('/member-server/member/vip/service/ref/page', data)
})