<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-select v-model="query.articleType" placeholder="文章类型" class="handle-select mr10" clearable>
          <el-option key="0" label="文本" value="0"></el-option>
          <el-option key="1" label="视频+文本" value="1"></el-option>
          <el-option key="2" label="音频+文本" value="2"></el-option>
          <el-option key="3" label="视频" value="3"></el-option>
          <el-option key="4" label="音频" value="4"></el-option>
        </el-select>
        <el-select v-model="query.isRecommend" placeholder="是否推荐" class="handle-select mr10" clearable>
          <el-option key="0" label="否" value="0"></el-option>
          <el-option key="1" label="是" value="1"></el-option>
        </el-select>
        <el-select v-model="query.pubStatus" placeholder="发布状态" class="handle-select mr10" clearable>
          <el-option key="0" label="草稿" value="0"></el-option>
          <el-option key="1" label="待发布" value="1"></el-option>
          <el-option key="2" label="已发布" value="2"></el-option>
        </el-select>
        <el-select v-model="query.status" placeholder="状态" class="handle-select mr10" clearable>
          <el-option key="0" label="正常" value="0"></el-option>
          <el-option key="1" label="停用" value="1"></el-option>
          <el-option key="2" label="删除" value="2"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addArticle">新增</el-button>
      </div>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        v-loading="loading">
        <el-table-column prop="articleTitle" :show-overflow-tooltip="true" label="文章标题"></el-table-column>
        <el-table-column prop="articleTag" :show-overflow-tooltip="true" label="文章标签"></el-table-column>

        <el-table-column label="发布状态">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.pubStatus == '0'">草稿</span>
              <span v-if="scope.row.pubStatus == '1'">待发布</span>
              <span v-if="scope.row.pubStatus == '2'">已发布</span>
            </p>
          </template>
        </el-table-column>

        <el-table-column label="文章分类">
          <template slot-scope="scope">
            <p v-for="item in articleCatData" :key="item.id">
              <span v-if="scope.row.categoryId == item.id">{{
                item.categoryName
              }}</span>
              <span v-else></span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-switch @change="switchChange($event, scope.$index, scope.row)" v-model="scope.row.status"
              active-color="#13ce66" inactive-color="#ff4949" active-value="0" inactive-value="1">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="230px">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 列表显隐项 -->
      <!-- <el-drawer
        title="列表显示项"
        :visible.sync="drawer"
        direction="rtl"
        size="400px"
      >
        <div class="selectHeader" v-for="item in temporaryData" :key="item.prop">
          <el-checkbox v-model="item.visable" :label="item.label"></el-checkbox>
        </div>
      </el-drawer> -->
      <!-- 测试 -->
      <!-- <el-table
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%"
        border
        stripe
        height="700px"
      >
        <el-table-column
          v-for="column of temporaryData.filter((item) => item.visable == true)"
          :key="column.prop"
          :prop="column.prop"
          :label="column.label"
          :width="column.width ? column.width : ''"
          :align="column.align ? column.align : ''"
        >
          <template slot-scope="scope">
            <div v-if="scope.column.property === 'articleTitle'">
              {{ scope.row[column.prop] ? "_" : "=" }}
            </div>
            <div v-else>
              {{ scope.row[column.prop] ? scope.row[column.prop] : "=" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table> -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageNum"
          :page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" @close="onEditClose">
      <el-form ref="formRules" :rules="formRules" :model="form" label-width="80px" class="editArticleRules">
        <el-form-item label="文章标题" prop="articleTitle">
          <el-input v-model="form.articleTitle" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="文章标签" prop="articleTag">
          <el-input v-model="form.articleTag" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="文章类型" prop="articleType">
          <el-select v-model="form.articleType" placeholder="文章类型" @change="articleTypeChageEdit"
            class="handle-select mr10">
            <el-option key="0" label="文本" value="0"></el-option>
            <el-option key="1" label="视频+文本" value="1"></el-option>
            <el-option key="2" label="音频+文本" value="2"></el-option>
            <el-option key="3" label="视频" value="3"></el-option>
            <el-option key="4" label="音频" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章分类" prop="categoryId">
          <el-select v-model="form.categoryId" placeholder="文章分类" class="handle-select mr10">
            <el-option v-for="item in EditArticleCatData" :key="item.id" :label="item.categoryName"
              :value="Number(item.id)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文章描述">
          <!--  -->
          <el-input type="textarea" v-model="form.articleDesc" style="width: 500px"
            :autosize="{ minRows: 2, maxRows: 6 }"></el-input>
        </el-form-item>

        <el-form-item label="是否推荐" prop="isRecommend">
          <el-select v-model="form.isRecommend" placeholder="是否推荐" class="handle-select mr10">
            <el-option key="0" label="否" value="0"></el-option>
            <el-option key="1" label="是" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="显示顺序">
          <el-input-number v-model="form.orderNum" :min="0" :max="1000"></el-input-number>
        </el-form-item>
        <el-form-item label="发布状态" prop="pubStatus">
          <el-select v-model="form.pubStatus" placeholder="发布状态" class="handle-select mr10">
            <el-option key="0" label="草稿" value="0"></el-option>
            <el-option key="1" label="待发布" value="1"></el-option>
            <el-option key="2" label="已发布" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="iSuploadEdit" label="音视频文件上传" label-width="120px">
          <el-upload class="upload-demo" action :http-request="UpLoadEdit" :limit="1" :file-list="fileListEdit"
            :on-remove="handleRemoveEdit">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <el-progress :percentage="percentEdit"></el-progress>
          <video v-if="iSvideo" :src="url + form.pubAv" class="video-avatar" controls="controls">
            您的浏览器不支持视频播放
          </video>
          <!-- https://yezai.oss-cn-shenzhen.aliyuncs.com/dev/ceshi/yezai_1645587201285_e45d32c4.mp3 -->
          <audio v-if="iSaudio" :src="url + form.pubAv" autoplay="autoplay" controls="controls" ref="audio">
            您的浏览器不支持音频播放
          </audio>
          <!-- <audio
            :src="url + 'dev/ceshi/yezai_1645587201285_e45d32c4.mp3'"
            autoplay="autoplay"
            controls="controls"
            ref="audio"
          >
            您的浏览器不支持音频播放
          </audio> -->
        </el-form-item>

        <el-form-item label="封面图">
          <el-upload action :http-request="UpLoadEditA" list-type="picture-card" :limit="1" :file-list="fileListEditA"
            :on-remove="handleRemoveEditA">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="文章内容" prop="content">
          <quill-editor ref="articleEditor" v-model="form.pubText" :options="editorOption" class="quill-editor-box">
          </quill-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onEditClose">取 消</el-button>
        <el-button type="primary" @click="saveEdit">修改</el-button>
      </span>
    </el-dialog>

    <!-- 新增文章 -->
    <el-dialog title="新增" :visible.sync="addVisible" :close-on-click-modal="false" @close="onAddClose">
      <el-form ref="addArticleRules" :rules="addArticleRules" :model="addform" label-width="80px" class="addArticleRules">
        <el-form-item label="文章标题" prop="articleTitle">
          <el-input v-model="addform.articleTitle" style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item label="文章标签" prop="articleTag">
          <el-input v-model="addform.articleTag" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="文章类型" prop="articleType">
          <el-select v-model="addform.articleType" placeholder="文章类型" @change="articleTypeChage"
            class="handle-select mr10">
            <el-option key="0" label="文本" value="0"></el-option>
            <el-option key="1" label="视频+文本" value="1"></el-option>
            <el-option key="2" label="音频+文本" value="2"></el-option>
            <el-option key="3" label="视频" value="3"></el-option>
            <el-option key="4" label="音频" value="4"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="文章分类" prop="categoryId">
          <el-select v-model="addform.categoryId" placeholder="文章分类" class="handle-select mr10">
            <el-option :label="item.categoryName" v-for="item in articleCatData" :key="item.categoryName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章描述">
          <el-input type="textarea" v-model="addform.articleDesc" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="是否推荐" prop="isRecommend">
          <el-select v-model="addform.isRecommend" placeholder="是否推荐" class="handle-select mr10">
            <el-option key="0" label="否" value="0"></el-option>
            <el-option key="1" label="是" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="显示顺序">
          <el-input-number v-model="addform.orderNum" :min="0" :max="1000"></el-input-number>
        </el-form-item>
        <el-form-item label="发布状态" prop="pubStatus">
          <el-select v-model="addform.pubStatus" placeholder="发布状态" class="handle-select mr10">
            <el-option key="0" label="草稿" value="0"></el-option>
            <el-option key="1" label="待发布" value="1"></el-option>
            <el-option key="2" label="已发布" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="iSupload" label="音视频文件上传" label-width="120px">
          <el-upload class="upload-demo" action :http-request="UpLoad" :limit="1" :on-remove="handleRemove">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <el-progress :percentage="percent"></el-progress>
        </el-form-item>

        <el-form-item label="封面图">
          <el-upload action :http-request="UpLoadA" list-type="picture-card" :limit="1" :file-list="fileListA"
            :on-remove="handleRemoveA">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="文章内容">
          <quill-editor ref="articleEditor" v-model="addform.pubText" :options="editorOption" class="quill-editor-box">
          </quill-editor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onAddClose">取 消</el-button>
        <el-button type="primary" @click="onSubmit">添加文章</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import {
  artPage,
  artDel,
  artUpdateStatus,
  artUpdate,
} from "@/api/article/article.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";

import { catList, artAdd } from "@/api/article/article.js"; //
import { client, getFileNameUUID } from "@/api/common/oss/oss.js"; //client,getFileNameUUID是前面的oss.js文件内的两个封装函数 //
import { ossL } from "@/api/common/oss/ossL.js";

import env from "@/api/env.js";

export default {
  name: "articL",
  data() {
    return {
      url: env.ossUrl,
      // temporaryData: [
      //   {
      //     prop: "articleTitle",
      //     label: "文章标题",
      //     align: "center",
      //     visable: true,
      //   },
      //   {
      //     prop: "articleTag",
      //     label: "文章标签",
      //     align: "center",
      //     visable: true,
      //   },
      //   {
      //     prop: "pubStatus",
      //     label: "发布状态",
      //     align: "center",
      //     visable: true,
      //   },
      // ],
      // drawer:true,
      loading: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        articleTitle: "",
        articleType: "",
        categoryId: "",
        isRecommend: "",
        pubStatus: "",
        status: "",
      },
      pageTotal: 0,
      tableData: [],
      editorOption: {
        placeholder: "",
      },
      /**
       * 编辑
       */
      editVisible: false,
      form: {
        articleDesc: "",
        articleTag: "",
        articleType: "",
        articleTitle: "",
        categoryId: "",
        isRecommend: "",
        orderNum: 0,
        pubAv: "",
        pubStatus: "",
        pubText: "",
        coverImg: "",
      },

      fileListEdit: [],
      fileListEditA: [],
      percentEdit: 100,
      iSvideo: false,
      iSaudio: false,
      iSuploadEdit: true,
      formRules: {
        articleTitle: [
          { required: true, message: "文章标题为空", trigger: "blur" },
        ],
        articleTag: [
          { required: true, message: "文章标签为空", trigger: "blur" },
        ],
        articleType: [
          { required: true, message: "文章类型为空", trigger: "blur" },
        ],
        categoryId: [
          { required: true, message: "文章分类为空", trigger: "blur" },
        ],
        isRecommend: [
          { required: true, message: "推荐状态为空", trigger: "blur" },
        ],
        pubStatus: [
          { required: true, message: "发布状态为空", trigger: "blur" },
        ],
      },
      EditArticleCatData: [],
      /**
       * 新增
       */
      addVisible: false,
      addform: {
        articleDesc: "",
        articleTag: "",
        articleType: "",
        articleTitle: "",
        categoryId: "",
        isRecommend: "",
        orderNum: 0,
        pubAv: "",
        pubStatus: "",
        pubText: "",
        coverImg: "",
      },
      articleCatData: [], //文章分类
      iSeditor: true,
      iSupload: true,
      percent: 0,
      addArticleRules: {
        articleTitle: [
          { required: true, message: "文章标题为空", trigger: "blur" },
        ],
        articleTag: [
          { required: true, message: "文章标签为空", trigger: "blur" },
        ],
        articleType: [
          { required: true, message: "文章类型为空", trigger: "blur" },
        ],
        categoryId: [
          { required: true, message: "文章分类为空", trigger: "blur" },
        ],
        isRecommend: [
          { required: true, message: "推荐状态为空", trigger: "blur" },
        ],
        pubStatus: [
          { required: true, message: "发布状态为空", trigger: "blur" },
        ],
      },
      /**
       * 封面图
       */
      fileListA: [],
    };
  },
  created() {
    this.getData();
    this.tkL();
  },
  components: {
    quillEditor,
  },
  methods: {
    changeMethod(item) {
      console.log(item); //这里就是选中的option对象
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 获取getData 数据
    async getData() {
      try {
        this.loading = true;
        let d = {
          articleTitle: "",
          articleType: this.query.articleType,
          categoryId: "",
          isRecommend: this.query.isRecommend,
          pageNum: this.query.pageNum,
          pageSize: this.query.pageSize,
          pubStatus: this.query.pubStatus,
          status: this.query.status,
        };
        const { code, data } = await artPage(d);
        if (code == 0) {
          this.loading = false;
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.ArticleCatData();
        }
      } finally {
        this.loading = false;
      }
    },
    //修改文章状态
    async switchChange(e, index, row) {
      console.log(e, index, row);
      const { code, msg } = await artUpdateStatus({
        id: row.id,
        status: e,
      });
      if (code == 0) {
        this.tkL();
        this.getData();
        this.$message.success("文章状态变更成功!");
      } else {
        this.tkL();
        this.$message.error(msg);
      }
    },
    /**
     *  删除操作
     */

    handleDelete(index, row) {
      console.log("ss", row, index);
      this.$confirm(
        `此操作将永久删除该标题为${row.articleTitle}, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          let a = {
            id: row.id,
          };
          const { code, data } = await artDel(a);
          if (code == 0) {
            console.log(data);
            this.$message.success("删除成功");
            // this.tableData.splice(index, 1);
            this.getData();
          }
          this.tkL();
          // this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 编辑操作
     */
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.editVisible = true;
      this.ArticleCatData();
      if (this.form.coverImg) {
        this.fileListEditA.push({
          name: row.coverImg,
          url: ossL(row.coverImg),
        });
      }
      if (this.form.pubAv) {
        this.percentEdit = 100;
        const num = this.form.pubAv.substr(this.form.pubAv.length - 3);
        this.fileListEdit = [
          {
            name: JSON.parse(JSON.stringify(row.pubAv)),
            url: ossL(JSON.parse(JSON.stringify(row.pubAv))),
          },
        ];

        if (num === "mp3") {
          this.iSaudio = true;
        } else if (num === "mp4") {
          this.iSvideo = true;
        }
      } else {
        this.percentEdit = 0;
      }
    },
    onEditClose() {
      this.editVisible = false;
      this.form = {
        articleDesc: "",
        articleTag: "",
        articleType: "",
        articleTitle: "",
        categoryId: "",
        isRecommend: "",
        orderNum: 0,
        pubAv: "",
        pubStatus: "",
        pubText: "",
        coverImg: "",
      };
      this.fileListEdit = [];
      this.fileListEditA = [];
      this.iSvideo = false;
      this.iSaudio = false;
    },
    handleRemoveEdit() {
      this.form.pubAv = "";
      this.percentEdit = 0;
      this.iSvideo = false;
      this.iSaudio = false;
    },
    articleTypeChageEdit(value) {
      //文本,视频和文本,音频和文本,视频,音频,
      if (value == 0) {
        this.iSupload = false;
        this.iSeditor = true;
      } else if (value == 1) {
        this.iSupload = true;
        this.iSeditor = true;
      } else if (value == 2) {
        this.iSupload = true;
        this.iSeditor = true;
      } else if (value == 3) {
        this.iSupload = true;
        this.iSeditor = false;
      } else if (value == 4) {
        this.iSupload = true;
        this.iSeditor = false;
      }
    },
    UpLoadEdit(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 500;
      const iSformat =
        file.file.type === "audio/mpeg" || file.file.type === "video/mp4";
      if (!iSformat) {
        this.$message.error("上传内容有误");
        return false;
      }

      if (!isLtSize) {
        return this.$message({
          message: "上传大小不能超过500MB!",
          type: "error",
        });
      }
      let checkpoint;
      let temporary = file.file.name.lastIndexOf(".");
      let fileNameLength = file.file.name.length;
      let fileFormat = file.file.name.substring(temporary + 1, fileNameLength);
      let fileName = getFileNameUUID() + "." + fileFormat;
      const that = this;
      async function multipartUpload() {
        try {
          const result = await client().multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/back/video/${fileName}`
            //   : `dev/back/video/${fileName}`,
            `${env.uploadUrlVideo}${fileName}`,
            file.file,
            {
              checkpoint,
              async progress(p, cpt) {
                // p进度条的值
                checkpoint = cpt;
                that.percentEdit = Math.floor(p * 100);
              },
            }
          );
          that.form.pubAv = result.name;
          // break;
        } catch (e) {
          setTimeout(function () { }, 1000);
          console.log(e);
        }
        // }
      }
      multipartUpload();
    },
    //修改
    saveEdit() {
      console.log("_", this.form, this.fileListEditA.length);
      if (this.fileListEditA.length > 0) {
        this.form.coverImg = this.fileListEditA[0].name;
      } else {
        this.form.coverImg = "";
      }
      this.$refs["formRules"].validate(async (valid) => {
        if (!valid) return;
        const { code, msg } = await artUpdate({
          articleDesc: this.form.articleDesc,
          articleTag: this.form.articleTag,
          articleTitle: this.form.articleTitle,
          articleType: this.form.articleType,
          categoryId: this.form.categoryId,
          isRecommend: this.form.isRecommend,
          orderNum: this.form.orderNum,
          pubAv: this.form.pubAv,
          id: this.form.id,
          pubStatus: this.form.pubStatus,
          pubText: this.form.pubText,
          coverImg: this.form.coverImg,
        });
        if (code == 0) {
          this.getData();
          this.editVisible = false;
          this.tkL();
          this.form = {
            articleDesc: "",
            articleTag: "",
            articleType: "",
            articleTitle: "",
            categoryId: "",
            isRecommend: "",
            orderNum: 0,
            pubAv: "",
            pubStatus: "",
            pubText: "",
            coverImg: "",
          };
          this.$message.success("修改成功");
        } else {
          this.tkL();
          this.$message.error(msg);
        }
      });
    },
    handleRemoveEditA(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileListEditA, b);
    },
    UpLoadEditA(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }
      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/back/cover/${fileName}`
            //   : `dev/back/cover/${fileName}`,
            `${env.uploadUrlCover}${fileName}`,
            // `dev/ceshi/${fileName}`, uploadUrlCover
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileListEditA.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    /**
     * 新增
     */
    //文章新增
    addArticle() {
      this.addVisible = true;
      this.ArticleCatData();
    },
    onAddClose() {
      this.addform = {
        articleDesc: "",
        articleTag: "",
        articleType: "",
        articleTitle: "",
        categoryId: "",
        isRecommend: "",
        orderNum: 0,
        pubAv: "",
        pubStatus: "",
        pubText: "",
        coverImg: "",
      };
      this.addVisible = false;
    },
    removeByValue(arr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    UpLoad(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 500;
      const iSformat =
        file.file.type === "audio/mpeg" || file.file.type === "video/mp4";
      if (!iSformat) {
        this.$message.error("上传内容有误");
        return false;
      }

      if (!isLtSize) {
        return this.$message({
          message: "上传大小不能超过500MB!",
          type: "error",
        });
      }
      let checkpoint;
      let temporary = file.file.name.lastIndexOf(".");
      let fileNameLength = file.file.name.length;
      let fileFormat = file.file.name.substring(temporary + 1, fileNameLength);
      let fileName = getFileNameUUID() + "." + fileFormat;
      const that = this;
      async function multipartUpload() {
        try {
          const result = await client().multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/back/video/${fileName}`
            //   : `dev/back/video/${fileName}`,
            // `dev/ceshi/${fileName}`,
            `${env.uploadUrlVideo}${fileName}`,
            file.file,
            {
              checkpoint,
              async progress(p, cpt) {
                // p进度条的值
                checkpoint = cpt;
                that.percent = Math.floor(p * 100);
              },
            }
          );
          that.addform.pubAv = result.name;
          // break;
        } catch (e) {
          setTimeout(function () { }, 1000);
          console.log(e);
        }
        // }
      }
      multipartUpload();
    },
    handleRemove() {
      this.addform.pubAv = "";
    },
    //上传列表图 (showImgUrl)
    UpLoadA(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }
      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/back/cover/${fileName}`
            //   : `dev/back/cover/${fileName}`,
            `${env.uploadUrlCover}${fileName}`,
            // `dev/ceshi/${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileListA.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    handleRemoveA(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileListA, b);
    },
    articleTypeChage(value) {
      this.addform.pubAv = "";
      if (value == 0) {
        this.iSupload = false;
        this.iSeditor = true;
        this.addform.pubAv = "";
        this.addform.pubText = "";
      } else if (value == 1) {
        this.iSupload = true;
        this.iSeditor = true;
      } else if (value == 2) {
        this.iSupload = true;
        this.iSeditor = true;
      } else if (value == 3) {
        this.iSupload = true;
        this.iSeditor = false;
      } else if (value == 4) {
        this.iSupload = true;
        this.iSeditor = false;
      }
    },
    async ArticleCatData() {
      const { code, data } = await catList({
        status: "0",
      });
      if (code == 0) {
        this.articleCatData = JSON.parse(JSON.stringify(data));
        this.EditArticleCatData = JSON.parse(JSON.stringify(data));
      }
      this.tkL();
    },
    async onSubmit() {
      if (this.fileListA.length > 0) {
        this.addform.coverImg = this.fileListA[0].name;
      }

      this.$refs["addArticleRules"].validate(async (valid) => {
        if (!valid) return;
        try {
          if (this.addform.articleType == "1") {
            if (this.percent == 100) {
              const { code, msg } = await artAdd({
                articleDesc: this.addform.articleDesc,
                articleTag: this.addform.articleTag,
                articleTitle: this.addform.articleTitle,
                articleType: this.addform.articleType,
                categoryId: this.addform.categoryId,
                isRecommend: this.addform.isRecommend,
                orderNum: this.addform.orderNum,
                pubAv: this.addform.pubAv,
                pubStatus: this.addform.pubStatus,
                pubText: this.addform.pubText,
                coverImg: this.addform.coverImg,
              });
              if (code == 0) {
                this.$message.success("添加文章成功!");
                this.addform = {
                  articleDesc: "",
                  articleTag: "",
                  articleType: "",
                  articleTitle: "",
                  categoryId: "",
                  isRecommend: "",
                  orderNum: 0,
                  pubAv: "",
                  pubStatus: "",
                  pubText: "",
                  coverImg: "",
                };
                this.addVisible = false;
                this.getData();
              } else {
                this.$message.error(msg);
              }
              this.tkL();
            } else {
              this.$message.error("文件未上传成功,切勿提交");
            }
          } else {
            const { code, msg } = await artAdd({
              articleDesc: this.addform.articleDesc,
              articleTag: this.addform.articleTag,
              articleTitle: this.addform.articleTitle,
              articleType: this.addform.articleType,
              categoryId: this.addform.categoryId,
              isRecommend: this.addform.isRecommend,
              orderNum: this.addform.orderNum,
              pubAv: this.addform.pubAv,
              pubStatus: this.addform.pubStatus,
              pubText: this.addform.pubText,
              coverImg: this.addform.coverImg,
            });
            if (code == 0) {
              this.$message.success("添加文章成功!");
              this.addform = {
                articleDesc: "",
                articleTag: "",
                articleType: "",
                articleTitle: "",
                categoryId: "",
                isRecommend: "",
                orderNum: 0,
                pubAv: "",
                pubStatus: "",
                pubText: "",
                coverImg: "",
              };
              this.addVisible = false;
              this.getData();
            } else {
              this.$message.error(msg);
            }
            this.tkL();
          }
        } catch (e) {
          console.log(e);
        }
      });
    },

    // 分页导航
    handlePageChange(val) {
      console.log(val);
      this.query.pageNum = val;
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.quill-editor-box {
  /deep/ .ql-container {
    min-height: 400px;
  }
}

.editArticleRules {
  height: 500px;
  padding-right: 10px;
  overflow: auto;
}

.video-avatar {
  width: 500px;
}

.addArticleRules {
  // width: 500px;
  height: 500px;
  padding-right: 10px;
  // overflow: hidden;
  overflow: auto;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  // width:12px;
  width: 8px;
  // background-color: aqua;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ececec;
}

/deep/ .el-progress {
  width: 300px !important;
}
</style>
