
import env from "@/api/env.js"

export const ossL = (res) => {
    // if (process.env.NODE_ENV == "development") {
    //     return 'https://yezai.oss-cn-shenzhen.aliyuncs.com/' + res
    // } else if (process.env.NODE_ENV == "production") {
    //     // instance.defaults.baseURL = 'http://192.168.100.113:8081';
    //     return 'https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/' + res
    //     // return 'https://img.yezai.love/' + res
    // }
    /**
     * 测试
     */
    return env.ossUrl + res 
}