<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button
          v-permission="'sys:emoque:add'"
          icon="el-icon-plus"
          type="primary"
          @click="Newfeelings"
          >新增话题</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column label="话题图片" width="100px" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.showImgUrl"
              style="width: 32px; height: 32px"
              fit="cover"
              :preview-src-list="[url + scope.row.showImgUrl]"
              :src="url + scope.row.showImgUrl"
            ></el-image>
            <el-image
              v-else
              style="width: 32px; height: 32px"
              fit="cover"
              :preview-src-list="[url + scope.row.imgUrl]"
              :src="url + scope.row.imgUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="话题标题"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="joinNum" label="加入人数"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>

        <el-table-column label="话题状态">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column
          label="操作"
          class="table-hand"
          align="center"
          width="300px"
        >
          <template slot-scope="scope">
            <el-button
              v-permission="'sys:emoque:update'"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleQueEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 新增情感弹出框 -->
    <el-dialog
      title="新增话题"
      :visible.sync="editVisible"
      width="30%"
      @close="onAddVisibleClose"
    >
      <el-form
        ref="formRules"
        :model="form"
        label-width="100px"
        :rules="formRules"
      >
        <el-form-item label="话题标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="话题介绍" prop="introduce">
          <el-input v-model="form.introduce" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="主图" prop="imgUrl">
          <el-upload
            action
            :http-request="UpLoad"
            list-type="picture-card"
            :limit="numLL"
            :file-list="fileList"
            :on-remove="handleRemoveLL"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-upload
            action
            :http-request="UpLoad"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.imgUrl" :src="url + form.imgUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->
        </el-form-item>
        <el-form-item label="列表图" prop="showImgUrl">
          <el-upload
            action
            :http-request="UpLoadA"
            list-type="picture-card"
            :limit="numLL"
            :file-list="fileListA"
            :on-remove="handleRemoveA"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch
            @change="switchC($event)"
            v-model="form.isShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="0"
            inactive-value="1"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="序列">
          <el-input-number
            v-model="form.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改话题 width="30%" -->
    <el-dialog
      title="修改话题"
      :visible.sync="revise"
      width="600px"
      @close="onEditClose"
    >
      <el-form
        ref="amendRules"
        :model="amend"
        label-width="100px"
        :rules="amendRules"
        style="width: 500px"
      >
        <el-form-item label="话题标题" prop="title">
          <el-input v-model="amend.title"></el-input>
        </el-form-item>
        <el-form-item label="话题介绍" prop="introduce">
          <el-input v-model="amend.introduce" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="主图" prop="imgUrl">
          <!--   -->
          <el-upload
            action
            :http-request="UpLoadEdit"
            list-type="picture-card"
            :limit="numLL"
            :file-list="fileListEdit"
            :on-remove="handleRemoveLLEdit"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-upload
            action
            :http-request="UpLoadEdit"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="amend.imgUrl" :src="url + amend.imgUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->
        </el-form-item>
        <el-form-item label="列表图" prop="showImgUrl">
          <el-upload
            action
            :http-request="UpLoadEditA"
            list-type="picture-card"
            :limit="numLL"
            :file-list="fileListEditA"
            :on-remove="handleRemoveAEditA"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="是否显示">
          <el-switch
            @change="switchEdit($event)"
            v-model="amend.isShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="0"
            inactive-value="1"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="序列">
          <el-input-number
            v-model="amend.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revise = false">取 消</el-button>
        <el-button type="primary" @click="modify">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { topicPage, topicAdd, topicUpdate } from "@/api/emotion/emotion.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
import { client, getFileNameUUID } from "@/api/common/oss/oss.js";
import { ossL } from "@/api/common/oss/ossL.js";

import env from "@/api/env.js";
export default {
  // name: 'basetable',
  data() {
    return {
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
           url:env.ossUrl,
      query: {
        pageSize: 10,
        pageNum: 1,
      },
      pageTotal: 0,
      tableData: [],
      loading: false,
      /**
       * 上传图片
       */
      numLL: 1,
      fileList: [],
      fileListA: [],
      /**
       * 新增
       */
      editVisible: false,
      form: {
        imgUrl: "",
        introduce: "",
        isShow: "0",
        showImgUrl: "",
        title: "",
        orderNum: 0,
      },
      formRules: {
        introduce: [
          { required: true, message: "话题介绍名称为空", trigger: "blur" },
        ],
        title: [{ required: true, message: "话题标题为空", trigger: "blur" }],
        imgUrl: [{ required: true, message: "主图片为空", trigger: "blur" }],
        showImgUrl: [
          { required: true, message: "列表图片为空", trigger: "blur" },
        ],
      },
      /**
       * 编辑
       */
      fileListEdit: [],
      fileListEditA: [],
      revise: false,
      amend: {
        id: "",
        imgUrl: "",
        introduce: "",
        isShow: "0",
        showImgUrl: "",
        title: "",
        orderNum: 0,
      },
      amendRules: {
        introduce: [
          { required: true, message: "话题介绍名称为空", trigger: "blur" },
        ],
        title: [{ required: true, message: "话题标题为空", trigger: "blur" }],
        imgUrl: [{ required: true, message: "主图片为空", trigger: "blur" }],
        showImgUrl: [
          { required: true, message: "列表图片为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getData();
    this.tkL()
  },
  methods: {
    // handleAvatarSuccess() {},
    // 获取提交令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 获取话题分页
    async getData() {
      try {
        this.loading = true;

        const { code, data } = await topicPage({
          pageSize: this.query.pageSize,
          pageNum: this.query.pageNum,
        });
        if (code == 0) {
          this.loading = false;
          this.tableData = data.records;
          this.pageTotal = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
    //话题状态修改
    async switchChange(e, index, row) {
      console.log(e, index, row);
      const { code, data } = await topicUpdate({
        id: row.id,
        status: e,
        imgUrl: row.imgUrl,
        introduce: row.introduce,
        isShow: row.isShow,
        orderNum: row.orderNum,
        showImgUrl: row.showImgUrl,
        title: row.title,
      });
      if (code == 0) {
        console.log(data);
        this.tkL();
        // this.$set(row, "status", e);
        this.$message.success("更改状态成功");
        this.getData();
      } else {
        this.tkL();
        this.$message.error("更改状态失败");
      }
    },
    /**
     * 删除图片(公用函数)
     */
    removeByValue(arr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    /**
     * 修改话题
     */
    // 打开修改话题
    handleQueEdit(row) {
      this.amend = JSON.parse(JSON.stringify(row));
      this.revise = true;
      this.fileListEdit = [
        {
          name: JSON.parse(JSON.stringify(row)).imgUrl,
          url: ossL(JSON.parse(JSON.stringify(row)).imgUrl),
        },
      ];
      this.fileListEditA = [
        {
          name: JSON.parse(JSON.stringify(row)).showImgUrl,
          url: ossL(JSON.parse(JSON.stringify(row)).showImgUrl),
        },
      ];
    },
    onEditClose() {
      this.revise = false;
      this.fileListEdit = [];
      this.fileListEditA = [];
    },
    //提交话题
    async modify() {
      if (this.fileListEdit.length > 0) {
        this.amend.imgUrl = this.fileListEdit[0].name;
      }
      if (this.fileListEditA.length > 0) {
        this.amend.showImgUrl = this.fileListEditA[0].name;
      }
      this.$refs["amendRules"].validate(async (valid) => {
        if (!valid) return false;
        const { code, msg } = await topicUpdate({
          id: this.amend.id,
          imgUrl: this.amend.imgUrl,
          introduce: this.amend.introduce,
          isShow: this.amend.isShow,
          orderNum: this.amend.orderNum,
          showImgUrl: this.amend.showImgUrl,
          title: this.amend.title,
        });
        if (code == 0) {
          this.$message.success("修改成功");
          this.revise = false;
          this.fileListEdit = [];
          this.fileListEditA = [];
        } else {
          this.$message.error(msg);
        }
      });
    },
    //上传主图 (imgUrl)
    UpLoadEdit(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }

      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/topic/banner/${fileName}`
            //   : `dev/topic/banner/${fileName}`,
            `${env.uploadUrlTopicBanner}${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileListEdit.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();

      console.log("__", this.fileListEdit);
    },
    //上传列表图 (showImgUrl)
    UpLoadEditA(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }

      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/topic/list/${fileName}`
            //   : `dev/topic/list/${fileName}`,
            `${env.uploadUrlTopicList}${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileListEditA.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    switchEdit(e) {
      this.amend.isShow = e;
    },
    //编辑
    handleRemoveLLEdit(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileListEdit, b);
      this.amend.imgUrl = "";
    },
    handleRemoveAEditA(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileListEditA, b);
      this.amend.isShow = "";
    },
    /**
     * 添加话题
     */
    // 打开新增问题弹框
    Newfeelings() {
      this.editVisible = true;
    },
    //新增状态修改
    switchC(e) {
      this.form.isShow = e;
    },
    //关闭添加话题
    onAddVisibleClose() {
      this.editVisible = false;
      this.form = {
        imgUrl: "",
        introduce: "",
        isShow: "0",
        showImgUrl: "",
        title: "",
        orderNum: 0,
      };
    },
    // 添加话题
    async saveEdit() {
      if (this.fileList.length > 0) {
        this.form.imgUrl = this.fileList[0].name;
      }
      if (this.fileListA.length > 0) {
        this.form.showImgUrl = this.fileListA[0].name;
      }
      this.$refs["formRules"].validate(async (valid) => {
        if (!valid) return false;

        const { code, msg } = await topicAdd({
          imgUrl: this.form.imgUrl,
          introduce: this.form.introduce,
          isShow: this.form.isShow,
          showImgUrl: this.form.showImgUrl,
          title: this.form.title,
          orderNum: this.form.orderNum,
        });
        if (code == 0) {
          this.$message.success("添加话题成功");
          this.tkL();
          this.getData();
          this.editVisible = false;
          this.fileList = [];
          this.fileListA = [];
        } else {
          this.$message.error(msg);
          this.tkL();
        }
      });
    },
    //上传主图 (imgUrl)
    UpLoad(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }

      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/topic/banner/${fileName}`
            //   : `dev/topic/banner/${fileName}`,
            `${env.uploadUrlTopicBanner}${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileList.push({
              name: res.name,
              url: ossL(res.name),
            });
            that.form.imgUrl = res.name;
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    //上传列表图 (showImgUrl)
    UpLoadA(file) {
      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }
      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/topic/list/${fileName}`
            //   : `dev/topic/list/${fileName}`,
            `${env.uploadUrlTopicList}${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileListA.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    handleRemoveLL(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileList, b);
      this.form.imgUrl = "";
    },
    handleRemoveA(file) {
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileListA, b);
      this.form.isShow = "";
    },
  },
};
</script>

<style lang="less" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  /* width: 100%; */
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.table-hand {
  flex: 1;
}
/deep/ .el-card__body {
  padding: 0%;
}
/deep/ .el-dialog__body {
  padding-top: 10px;
}
.clearfix {
  display: flex;
  justify-content: center;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  // height: ;
  width: 148px !important;
  height: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  // height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  // height: 178px;
  display: block;
}
</style>
