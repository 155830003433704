import request from "@/utils/request.js"
//情感问题分页
export const quePage = ((data) => {
    return request.postt('/system-server/sys/emo/que/page', data)
})
// 添加情感问题
export const queAdd = ((data) => {
    return request.post('/system-server/sys/emo/que/add', data)
})
// 修改情感问题
export const queUpdate = ((data) => {
    return request.post('/system-server/sys/emo/que/update', data)
})
//删除情感问题 
export const queDel = ((data) => {
    return request.postt('/system-server/sys/emo/que/del', data)
})
//获取情感问题详情
export const queInfo = ((data) => {
    return request.gettLLX(`/system-server/sys/emo/que/info/${data}`)
})

//添加情感问题答案 
export const queAnsAdd = ((data) => {
    return request.post('/system-server/sys/emo/que/ans/add', data)
})
//情感问题答案修改
export const queAnsUpdate = ((data) => {
    return request.post('/system-server/sys/emo/que/ans/update', data)
})
//删除情感问题答案 
export const queAnsDel = ((data) => {
    return request.postt('/system-server/sys/emo/que/ans/del', data)
})

//情感分析 
export const analStdList = ((data) => {
    return request.postt('/system-server/sys/emo/anal/std/list', data)
})
/**
话题
 */
//话题分页
export const topicPage = ((data) => {
    return request.postt('/member-server/member/topic/page', data)
})
//话题增加
export const topicAdd = ((data) => {
    return request.post('/member-server/member/topic/add', data)
})
//话题删除
// export const topicPage = ((data) => {
//     return request.postt('/member-server/member/topic/page', data)
// })
//话题修改
export const topicUpdate = ((data) => {
    return request.post('/member-server/member/topic/update', data)
})