<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.status"
          placeholder="账号状态"
          class="handle-select mr10"
          clearable
        >
          <el-option key="0" label="正常" value="0"></el-option>
          <el-option key="1" label="停用" value="1"></el-option>
        </el-select>
        <el-input
          v-model="query.searchCond"
          placeholder="根据用户ID或昵称搜索"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column label="头像" width="60px" align="center">
          <!-- :preview-src-list="AlbumL" :preview-src-list="[url + scope.row.avatar]" -->
          <template slot-scope="scope">
            <el-image
              style="width: 32px; height: 32px"
              fit="cover"
              :preview-src-list="AlbumL"
              :src="url + scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="会员ID" width="120px" align="center">
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="handleDetails(scope.row)"
              >{{ scope.row.id }}</span
            >
          </template>
        </el-table-column>
        <!-- width="150px" -->
        <el-table-column
          prop="nickName"
          label="会员昵称"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="age"
          label="年龄"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          align="center"
          :formatter="sfktFormate"
        >
        </el-table-column>
        <!-- width="120px" -->
        <el-table-column
          label="出生年月"
          prop="birthday"
          :show-overflow-tooltip="true"
          width="100px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="height"
          label="身高"
          align="center"
        ></el-table-column>
        <el-table-column label="学历" width="130px" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.education == 0">高中及以下</span>
            <span v-if="scope.row.education == 1">中专</span>
            <span v-if="scope.row.education == 2">大专</span>
            <span v-if="scope.row.education == 3">大学本科</span>
            <span v-if="scope.row.education == 4">硕士</span>
            <span v-if="scope.row.education == 5">博士</span>
          </template>
        </el-table-column>
        <!-- width="130px" -->
        <el-table-column
          label="月收入"
          align="center"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.monthIncome == 0">3000元以下</span>
            <span v-if="scope.row.monthIncome == 1">3001-5000元</span>
            <span v-if="scope.row.monthIncome == 2">5001-8000元</span>
            <span v-if="scope.row.monthIncome == 3">8001-12000元</span>
            <span v-if="scope.row.monthIncome == 4">12001-20000元</span>
            <span v-if="scope.row.monthIncome == 5">20000-50000元</span>
            <span v-if="scope.row.monthIncome == 6">50000元以上</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="maritalStatus"
          label="婚姻状况"
          :formatter="marr"
          width="80px"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="phonenumber"
          label="手机号"
          width="150px"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="workAddrCityName"
          label="工作地址"
          width="80px"
          align="center"
        ></el-table-column>

        <el-table-column label="账号状态" class="table-hand" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!--  -->
        <!-- <el-table-column
          label="会员信息刷新"
          class="table-hand"
         width="150px"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-refresh"
              @click="InfoRefreshInner(scope.row)"
              >刷新</el-button
            >
          </template>
        </el-table-column> -->
        <el-table-column
          prop="createTime"
          label="注册时间"
          width="180px"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="400px" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-setting"
              @click="handleEdit(scope.row)"
              >开通会员</el-button
            >
            <el-button
              icon="el-icon-user"
              @click="MemberInfo(scope.row)"
              size="mini"
              type="warning"
              >会员信息</el-button
            >
            <el-button
              icon="el-icon-view"
              @click="handleDetails(scope.row)"
              size="mini"
              type="info"
              >详情</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-refresh"
              @click="InfoRefreshInner(scope.row)"
              >刷新</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <!-- 开通会员 -->
      <el-dialog
        title="开通会员"
        :visible.sync="editVisible"
        width="30%"
        @close="onEditVisible"
      >
        <el-form ref="form" :model="goodForm" label-width="70px">
          <el-form-item label="商品">
            <template>
              <el-select
                v-model="goodForm.good"
                placeholder="请选择"
                class="aaa"
                value-key="id"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.goodName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item label="服务来源">
            <el-select
              v-model="goodForm.serviceSource"
              placeholder="服务来源"
              class="handle-select mr10"
            >
              <el-option key="0" label="购买支付" value="0"></el-option>
              <el-option key="1" label="服务赠送" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { infoUserPage } from "@/api/member/member.js";
import {
  memberInfoUpdateStatus,
  memberInfoRefreshInner,
} from "@/api/member/member.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";

import env from "@/api/env.js";

import { goodList, infoPayServeSyn } from "@/api/store/store.js";
export default {
  name: "puAccount",
  data() {
    return {
      query: {
        searchCond: "",
        pageSize: 20,
        pageNum: 1,
        status: "",
        current: 1,
      },
      tableData: [],
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
      url: env.ossUrl,
      pageTotal: 0,
      loading: false,
      editVisible: false,
      goodForm: {},
      AlbumL: [],
      options: [],
    };
  },
  created() {
    this.getData();
    this.tkL();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 获取会员信息
    async getData() {
      try {
        this.loading = true;
        let d = {
          status:this.query.status,
          pageSize: this.query.pageSize,
          pageNum: this.query.pageNum,
        };
        this.AlbumL = [];
        const { code, data } = await infoUserPage(d);
        if (code == 0) {
          this.loading = false;
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.tableData.forEach((i) => {
            this.AlbumL.push(`${this.url + i.avatar}`);
          });
          // this.query.current = data.current;
        }
      } finally {
        this.loading = false;
      }
    },
    async InfoRefreshInner(row) {
      try {
        this.loading = true;
        const { code, data } = await memberInfoRefreshInner({
          memberId: row.id,
        });
        if (code == 0) {
          console.log(data);
          this.loading = false;
          this.$message.success(`刷新信息成功`);
        } else {
          this.$message.error("刷新信息失败");
        }
      } finally {
        this.loading = false;
      }
    },
    // 性别处理
    sfktFormate(row) {
      if (row.sex == 1) {
        return "女";
      } else if (row.sex == 0) {
        return "男";
      }
    },
    // 婚姻处理
    marr(row) {
      if (row.maritalStatus == 0) {
        return "未婚";
      } else if (row.maritalStatus == 1) {
        return "离异";
      } else if (row.maritalStatus == 2) {
        return "丧偶";
      }
    },
    //会员状态修改
    async switchChange(e, index, row) {
      console.log(e, index, row);
      let a = {
        userId: row.id,
        status: e,
      };
      const { code, data } = await memberInfoUpdateStatus(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.$set(row, "status", e);
        this.$message.success("更改状态成功");
      } else {
        this.tkL();
        this.$message.error("更改状态失败");
      }
    },
    // 触发搜索按钮
    async handleSearch() {
      // this.getData();
      var reg = /^\s*$/g;
      if (!reg.test(this.query.searchCond)) {
        let d = {
          status: this.query.status,
          cond: this.query.searchCond,
          pageSize: this.query.pageSize,
          pageNum: this.query.pageNum,
        };
        const { code, data } = await infoUserPage(d);
        if (code == 0) {
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.query.current = data.current;
        }
      } else {
        let d = {
          status: this.query.status,
          pageSize: this.query.pageSize,
          pageNum: this.query.pageNum,
        };
        const { code, data } = await infoUserPage(d);
        if (code == 0) {
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.query.current = data.current;
        }
      }
    },
    // 删除操作
    handleDelete(index, row) {
      console.log("ss", row, index);
      // 二次确认删除
      // this.$confirm("确定要删除吗？", "提示", {
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.$message.success("删除成功");
      //     this.tableData.splice(index, 1);
      //     //调用接口
      //   })
      //   .catch(() => {});
    },

    /**
     * 设置会员
     */
    handleEdit(row) {
      console.log("ss", row);
      // this.idx = index;
      this.goodForm.id = row.id;
      this.editVisible = true;
      this.payServeSyn();
    },
    async payServeSyn() {
      // goodList  infoPayServeSyn
      const { code, data } = await goodList();
      if (code == 0) {
        console.log(data);
        this.options = data;
      }
    },
    onEditVisible() {
      this.goodForm = {};
      this.editVisible = false;
    },
    //确定
    saveEdit() {
      // this.editVisible = false;
      console.log("__", this.goodForm);

      // infoPayServeSyn
      this.$confirm(`确定给会员添加${this.goodForm.good.goodName},`, "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code, data } = await infoPayServeSyn({
            goodCategoryId: this.goodForm.good.categoryId,
            goodCategoryName: this.goodForm.good.categoryName,
            goodId: this.goodForm.good.id,
            goodKey: this.goodForm.good.goodKey,
            goodName: this.goodForm.good.goodName,
            memberId: this.goodForm.id,
            payMonthNum: this.goodForm.good.goodDesc,
            serviceSource: this.goodForm.serviceSource,
          });
          if (code == 0) {
            console.log("_", data);
            this.onEditVisible();
            this.$message.success("添加会员成功!");
          } else {
            this.onEditVisible();
            this.$message.error("添加会员失败!");
          }
        })
        .catch(() => {});
    },
    // 详情
    handleDetails(row) {
      // console.log('_',row);
      this.$router.push({
        path: "/puAccountDetails",
        query: {
          id: row.id,
        },
      });
    },
    //会员信息
    MemberInfo(row) {
      this.$router.push({
        path: "/MemberInformation",
        query: {
          id: row.id,
          // pageNum: this.query.pageNum,
        },
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.bule {
  color: blue;
}
.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  /* width: 100%; */
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.table-hand {
  flex: 1;
}
</style>
