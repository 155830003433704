<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.memberId"
          placeholder="点赞会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-input
          v-model="query.likeMemberId"
          placeholder="被点赞会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <!-- header-cell-class-name="table-header"
        @selection-change="handleSelectionChange" -->
      <el-table :data="tableData" border class="table" ref="multipleTable">
        <!-- <el-table-column prop="id" label="#"></el-table-column> -->
        <el-table-column prop="memberId" label="点赞会员ID"></el-table-column>
        <el-table-column
          prop="likeMemberId"
          label="被点赞会员ID"
        ></el-table-column>
        <el-table-column label="点赞类型">
          <template slot-scope="scope">
            <p v-if="scope.row.objType == 1">动态</p>
            <p v-if="scope.row.objType == 2">相册</p>
            <p v-if="scope.row.objType == 3">兴趣爱好</p>
            <p v-if="scope.row.objType == 4">视频</p>
            <p v-if="scope.row.objType == 5">内心独白</p>
            <p v-if="scope.row.objType == 6">封面视频</p>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <p>{{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-view"
              @click="handleDetails(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="详情" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="点赞会员ID">
          <el-input v-model="form.memberId"></el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { memberLikePage } from "@/api/member/member.js";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        likeMemberId: "",
        memberId: "",
        pageNum: 1,
        pageSize: 10,
      },
      form: {},
      tableData: [],
      editVisible: false,
      pageTotal: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { code, data } = await memberLikePage({
        likeMemberId: this.query.likeMemberId,
        memberId: this.query.memberId,
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    handleDetails(index, row) {
      console.log(index, row);
      this.form = row;
      //   this.editVisible = true;
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageNum", 1);
      this.getData();
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageNum = val;
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
