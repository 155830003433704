<template>
  <div class="user-container">
    <div class="table-top20">
      <el-card>
        <div class="add-btn-row">
          <el-button
            type="primary"
            v-permission="'sys:menu:add'"
            @click="showAddPermissionDialog"
            size="small"
            >添加字典</el-button
          >
        </div>
        <!-- 表格 -->
        <el-table
          :data="permissionsList"
          style="width: 100%; margin-bottom: 20px; margin-top: 20px"
          :row-key="getRowKeys"
          border
          :tree-props="{ children: 'children' }"
        >
          <el-table-column
            prop="dictLabel"
            label="字典名称"
            width="200px"
            align="left"
          >
          </el-table-column>
          <el-table-column prop="dictKey" label="字典标识"> </el-table-column>
          <el-table-column prop="dictKey" label="字典状态">
            <template slot-scope="scope">
              <!-- v-permission="'sys:dict:updatestatus'" -->
              <el-switch
                @change="switchChange($event, scope.$index, scope.row)"
                v-model="scope.row.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="0"
                inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column width="220px" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="editPermission(scope.row)"
                type="primary"
                icon="el-icon-edit"
                v-permission="'member:album:list'"
                >编辑</el-button
              >
              <el-button
                size="mini"
                @click="delPermission(scope.row.id)"
                type="danger"
                icon="el-icon-delete"
                v-permission="'sys:dict:del'"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <!-- 添加权限弹窗 -->
    <el-dialog
      title="添加字典"
      class="permission-dialog"
      :visible.sync="permissionDialog"
      @close="onPermissionsDialogClose"
    >
      <el-form
        ref="permissionForm"
        :model="permissionForm"
        :rules="permissionFormRules"
        label-width="100px"
        class="permission-form"
        label-position="left"
      >
        <el-form-item label="字典名称" prop="dictValue">
          <el-input v-model="permissionForm.dictValue"></el-input>
        </el-form-item>
        <span class="notice">一级权限不用选择</span>
        <el-form-item label="父级选择">
          <el-cascader
            v-model="selectPids"
            :options="parentPermissionsTree"
            :props="permissionsProps"
            clearable
            @change="onParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="字典顺序">
          <el-input-number
            v-model="permissionForm.orderNum"
            @change="handleChange"
            :min="0"
            :max="1000"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="字典编码" prop="dictKey">
          <el-input v-model="permissionForm.dictKey"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="permissionForm.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeAddPermissionDialog">取消</el-button>
          <el-button type="primary" @click="submitPermission"
            >添加字典</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 修改权限弹窗 -->
    <el-dialog
      title="修改字典"
      :visible.sync="editPermissionDialog"
      @close="onEditPermissionsDialogClose"
    >
      <el-form
        ref="editPermissionForm"
        :model="editPermissionForm"
        :rules="editPermissionFormRules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="字典名称" prop="dictValue">
          <el-input v-model="editPermissionForm.dictValue"></el-input>
        </el-form-item>
        <span class="notice">一级字典不用选择</span>
        <el-form-item label="父级选择">
          <el-cascader
            v-model="editSelectPids"
            :options="editParentPermissionsTree"
            :props="permissionsProps"
            clearable
            @change="onEditParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="字典顺序">
          <el-input-number
            v-model="editPermissionForm.dictSort"
            :min="0"
            :max="1000"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="字典编码" prop="dictKey">
          <el-input v-model="editPermissionForm.dictKey"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editPermissionForm.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="onEditPermissionsDialogClose">取消</el-button>
          <el-button type="primary" @click="subEditPermission"
            >修改字典</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  dictTree,
  dictAdd,
  dictDel,
  dictUpdate,
  dictUpdateStatus,
  dictManageList,
} from "@/api/system/system.js"; //dictAdd
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  name: "permissions",
  data() {
    return {
      //---------修改
      editPermissionDialog: false,
      editPermissionForm: {
        id: "",
        dictValue: "",
        parentId: 0,
        dictKey: "",
        icon_path: "",
        orderNum: 0,
        remark: "",
      },
      editPermissionFormRules: {
        dictValue: [
          { required: true, message: "字典名称为空", trigger: "blur" },
        ],
        dictKey: [{ required: true, message: "字典key为空", trigger: "blur" }],
      },
      editSelectPids: [],
      editParentPermissionsTree: [],
      //------------end
      permissionsList: [],
      keywords: "",
      currentPage: 1,
      lastPage: 1,
      listRows: 10, // 一页显示条数
      total: 0,
      pageTotal: 0,
      pageSizes: [10, 20, 30, 40],
      permissionDialog: false,
      parentPermissionsTree: [], // 父级权限列表数据
      // 父级联动选择器props配置
      permissionsProps: {
        label: "dictLabel",
        value: "id",
        checkStrictly: true,
      },
      selectPids: [], // 选中的父id数组
      permissionForm: {
        id: "",
        dictValue: "",
        pid: 0,
        parentId: 0,
        dictKey: "",
        icon_path: "",
        orderNum: 0,
        remark: "",
      },
      permissionFormRules: {
        dictValue: [
          { required: true, message: "字典名称为空", trigger: "blur" },
        ],
        dictKey: [{ required: true, message: "字典key为空", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.getPermissionsList();
    this.tkL();
  },
  methods: {
    /**
     * 修改
     */
    //关闭弹窗
    onEditPermissionsDialogClose() {},
    onEditParentNodeChange(selectPids) {
      this.editPermissionForm.parentId = selectPids[selectPids.length - 1]; //parentId
    },
    subEditPermission() {
      this.$message.success("修改成功");
      this.$refs["editPermissionForm"].validate(async (valid) => {
        if (!valid) return false;
        if (this.editPermissionForm.id == this.editPermissionForm.parentId) {
          return this.$message.warning('自己不能为自己的父级')
        } else {
          const { code, msg } = await dictUpdate({
            dictKey: this.editPermissionForm.dictKey,
            dictLabel: this.editPermissionForm.dictValue,
            dictSort: this.editPermissionForm.orderNum,
            dictValue: this.editPermissionForm.dictValue,
            parentId: this.editPermissionForm.parentId,
            remark: this.editPermissionForm.remark,
            id: this.editPermissionForm.id,
            dictType: "1",
          });

          if (code == 0) {
            this.$message.success("修改成功");
            this.editPermissionDialog = false;
            this.editPermissionForm = {};
            this.editSelectPids = [];
            this.getPermissionsList();
          } else {
            this.$message.error(msg);
            this.editPermissionForm = {};
            this.editSelectPids = [];
          }
          this.tkL();
        }
      });
    },
    //end
    getRowKeys(row) {
      return row.id;
    },
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    //树结构扁平化(未使用)
    flatTree(data, childName = "children") {
      if (!Array.isArray(data)) {
        console.warn("只支持传入数组");
        return [];
      }
      return data.reduce((prev, curt) => {
        // 有子节点的话把子节点作为一级节点递归遍历
        const childs = curt[childName]?.length
          ? this.flatTree(curt[childName])
          : [];
        return [...prev, curt, ...childs];
      }, []);
    },
    //将数组转为树形结构
    fn(list) {
      // filter筛选符合条件的元素，返回一个包含所有符合条件的元素的新数组
      return list.filter(
        (item1) =>
          !list.find((item2, index) => {
            // 如果有父节点
            if (item1.parentId === item2.id) {
              // 放进它父节点的children数组中；如果children不存在，初始化为空数组
              list[index].children = list[index].children || [];
              list[index].children.push(item1);
            }
            // find返回第一个符合条件的元素，找到后，剩余的元素不再判断
            return item1.parentId === item2.id;
          })
      );
    },
    async getPermissionsList() {
      try {
        // const { code, data } = await dictTree();
        const { code, data } = await dictManageList();
        if (code === 0) {
          this.permissionsList = this.fn(data);
          // this.permissionsList = data;
        }
      } catch (e) {
        if (e && e.response) {
          this.$message.error("请求异常");
        }
      }
    },
    onCurrentPageChange(page) {
      this.currentPage = page;
      this.getPermissionsList();
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getPermissionsList();
    },
    // 当前一页显示个数改变
    onSizeChange(num) {
      this.listRows = num;
      this.getPermissionsList();
    },
    showAddPermissionDialog() {
      this.permissionDialog = true;
      this._getParentPermissionsTree();
    },
    closeAddPermissionDialog() {
      this.permissionDialog = false;
    },
    //字典状态
    async switchChange(e, index, row) {
      console.log(e, index, row);
      const { code, msg } = await dictUpdateStatus({
        ids: row.id,
        status: e,
      });
      if (code == 0) {
        this.tkL();
        this.$message.success("更改状态成功");
      } else {
        this.tkL();
        this.$message.error(msg);
      }
    },
    handleChange(value) {
      console.log(value);
      console.log(this.permissionForm);
    },
    // 选择父级权限改变
    onParentNodeChange(selectPids) {
      // this.permissionForm.pid = selectPids[selectPids.length - 1]; //parentId
      this.permissionForm.parentId = selectPids[selectPids.length - 1]; //parentId
      console.log(selectPids, selectPids[selectPids.length - 1]);
    },
    // 添加字典
    submitPermission() {
      this.$refs["permissionForm"].validate(async (valid) => {
        if (!valid) return false;
        let params = this.permissionForm;
        //dictValue  //dictLabel
        console.log(params);
        this.tkL();
        try {
          if (!this.permissionForm.id) {
            // 添加权限
            // let { data: res } = await addPermission(params);
            // if (res.code === 201) {
            //   this.$message.success("添加成功");
            // }
            //          permissionForm: {
            //   id: "",
            //   dictValue: "",
            //   pid: 0,
            //   parentId:0,
            //   dictKey: "",
            //   icon_path: "",
            //   orderNum: 0,
            //   remark:''
            // },
            const { code, msg } = await dictAdd({
              dictKey: this.permissionForm.dictKey,
              dictLabel: this.permissionForm.dictValue,
              dictSort: this.permissionForm.orderNum,
              dictValue: this.permissionForm.dictValue,
              parentId: this.permissionForm.parentId,
              remark: this.permissionForm.remark,
              dictType: "1",
            });
            if (code == 0) {
              this.$message.success("添加成功");
            } else {
              this.$message.error(msg);
            }
          } else {
            // 更新权限
            // let { data: res } = await updatePermission(
            //   this.permissionForm.id,
            //   params
            // );
            // if (res.code === 202) {
            //   this.$message.success("更新成功");
            // }
          }
          this.permissionDialog = false;
          this.getPermissionsList();
          //   this.$store.dispatch("renderNavigationPermissionByRoleId");
        } catch (e) {
          if (e && e.response) {
            this.$message.error(e.response.data.message);
          }
        }
      });
    },
    searchPermission() {
      this.getPermissionsList();
    },
    // 权限弹窗关闭触发
    onPermissionsDialogClose() {
      this.selectPids = [];
      this.selectPid = 0;
      this.permissionForm = {
        id: "",
        auth_name: "",
        pid: 0,
        parentId: 0,
        dictKey: "",
        icon_path: "",
        orderNum: 0,
      };
    },
    editPermission(row) {
      this.editPermissionDialog = true;
      this.editPermissionForm = JSON.parse(JSON.stringify(row));
      // this._getParentPermissionsTree();
      this._getPermissionInfoById(row.parentId);
    },
    async delPermission(id) {
      try {
        let res = await this.$confirm("你确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        if (res === "confirm") {
          this.tkL();
          try {
            console.log(id);
            // let { data: res } = await delPermission(id);
            // if (res.code === 203) {
            //   this.$message.success("删除成功");
            //   this.getPermissionsList();
            //   this.$store.dispatch("renderNavigationPermissionByRoleId");
            // }
            const { code } = await dictDel({
              dictId: id,
            });
            if (code == 0) {
              this.$message.success("删除成功");
              this.getPermissionsList();
              this.tkL();
            }
          } catch (e) {
            this.$message.error("删除异常");
          }
        }
      } catch (e) {
        this.$message.success("已取消");
      }
    },
    // 获取包含层次限制权限列表请求
    async _getParentPermissionsTree() {
      //   let { data: res } = await getPermissionsLevelLimit({
      //     level: 2,
      //   });
      //   if (res.code === 200) {
      //     this.parentPermissionsTree = res.data;
      //   }
      const { code, data } = await dictTree();
      if (code === 0) {
        this.parentPermissionsTree = data;
        console.log(this.permissionsList);
      }
    },
    // 通过id获取权限信息
    async _getPermissionInfoById(permissionId) {
      console.log("____", permissionId);
      const { code, data } = await dictTree();
      if (code == 0) {
        this.editParentPermissionsTree = data;
        if (permissionId !== "0") {
          let selectPids = [];
          this._setSelectPidsByTargetId(
            this.editParentPermissionsTree,
            permissionId,
            selectPids
          );
          this.editSelectPids = selectPids;
        }
      }
    },
    // 设置默认选中的父节点
    _setSelectPidsByTargetId(categoryList, targetId, selectPids) {
      let parentNode = this._getParentNodeByTargetId(categoryList, targetId);
      console.log("________", parentNode);
      selectPids.unshift(parentNode["id"]);

      if (parentNode["parentId"] == 0) {
        return;
      }
      return this._setSelectPidsByTargetId(
        categoryList,
        parentNode["parentId"],
        selectPids
      );
    },
    // 获取父节点对象
    _getParentNodeByTargetId(categoryList, targetId) {
      let _this = this;
      for (let i = 0; i < categoryList.length; i++) {
        let category = categoryList[i];
        if (category["id"] === targetId) {
          return category;
        } else {
          if (category["children"] && category["children"].length > 0) {
            // 未找到遍历子级
            let res = _this._getParentNodeByTargetId(
              category["children"],
              targetId
            );
            if (res) {
              return res;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-row {
  .search-input {
    width: 350px;
  }
}
.paginate-row {
  margin-top: 15px;
}
// .add-btn-row {
//   margin-top: 20px;
//   margin-bottom: 20px;
// }
.permission-form {
  height: 360px;
}
.notice {
  font-size: 12px;
  color: red;
}
</style>