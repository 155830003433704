<template>
  <div class="header" v-loading="loading">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>
    <div class="logo">也在网后台管理系统</div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen ? `取消全屏` : `全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank"></i>
          </el-tooltip>
        </div>
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
                    <el-tooltip effect="dark" :content="message ? `有${message}条未读消息` : `消息中心`" placement="bottom">
                        <router-link to="/tabs">
                            <i class="el-icon-bell"></i>
                        </router-link>
                    </el-tooltip>
                    <span class="btn-bell-badge" v-if="message"></span>
                </div> -->
        <!-- 用户头像 -->
        <div class="user-avator">
          <img :src="avatar || deAvatar" />
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided command="PersonalCenter"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item divided command="loginout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import { userInfo } from "@/api/common.js";
import { logout } from "@/api/login/login.js";
import local from "@/api/common/local.js";
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  data() {
    return {
      collapse: false,
      fullscreen: false,
      name: "linxin",
      message: 2,
      username: "",
      avatar: "",
      deAvatar: require("@/assets/img/img.jpg"),
      loading: false,
    };
  },
  computed: {
    // username() {
    //   let username = localStorage.getItem("ms_username");
    //   return username ? username : this.name;
    //   //   console.log('__-',this.cur());
    //   //   return this.cur();
    // },
  },
  created() {
    this.cur();
      // this.collapseChage();
  },
  methods: {
    // 用户名下拉菜单选择事件
    async handleCommand(command) {
      if (command == "loginout") {
        // localStorage.removeItem("ms_username");

        await logout();
        // if (code == 0) {
        local.clear();
        this.$router.push("/login");
        //   console.log('00');
        // }

        // console.log("___", code);
      } else if (command == "PersonalCenter") {
        this.$router.push("/personal");
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
      this.$store.dispatch("GET_USER_PERMISSION");
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    async cur() {
      // try {
      //   this.loading = true;
        let token = local.get("access_token");
        const { code, data } = await userInfo(token);
        if (code == 0) {
          // console.log('___',data.user.userName);
          if (data.user) {
            this.username = data.user.userName;
            if (data.user.avatar) {
              this.avatar = ossL(data.user.avatar);
              // this.avatar = ossL("dev/back/avatar/default/default_avatar.png");
            }
          } else {
            this.username = "管理员";
          }
          this.loading = false;
        }
      // } finally {
      //   this.loading = false;
      // }
    },
  },
  mounted() {
    // if (document.body.clientWidth < 1500) {
    if (document.body.clientWidth < 3000) {
      this.collapseChage();
    }
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
  /* display: flex;
    justify-items: center; */
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  float: left;
  width: 250px;
  line-height: 70px;
}
.header-right {
  float: right;
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #fff;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}
</style>
