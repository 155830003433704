<template>
  <div class="container">
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-plus" @click="newly"
        >新增</el-button
      >
    </div>
    <el-table
      v-loading="loading"
      :data="permissionsList"
      style="width: 100%; margin-bottom: 20px; margin-top: 20px"
      :row-key="getRowKeys"
      border
      :expand-row-keys="expands"
      :tree-props="{ children: 'children' }"
    >
      <el-table-column prop="orgName" label="机构名称"></el-table-column>
      <el-table-column label="机构类型">
        <template slot-scope="scope">
          <p>
            <span v-if="scope.row.orgType == '0'">公司</span>
            <span v-if="scope.row.orgType == '1'">部门</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="机构状态">
        <template slot-scope="scope">
          <el-switch
            v-if="scope.row.id != 1"
            @change="switchChange($event, scope.$index, scope.row)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="0"
            inactive-value="1"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column width="220px" label="操作">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            v-if="scope.row.id != 1"
            @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            v-if="scope.row.id != 1"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑机构 -->
    <el-dialog
      title="编辑机构"
      class="permission-dialog"
      :visible.sync="editFormVisible"
      @close="onPermissionsDialogClose"
    >
      <el-form
        ref="editFormRules"
        :model="editForm"
        :rules="editFormRules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="机构名称" prop="orgName">
          <el-input v-model="editForm.orgName"></el-input>
        </el-form-item>
        <el-form-item label="机构类型" prop="orgType">
          <el-select v-model="editForm.orgType" placeholder="机构类型">
            <el-option key="0" label="公司" value="0"></el-option>
            <el-option key="1" label="部门" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构状态" prop="status">
          <el-select v-model="editForm.status" placeholder="机构状态">
            <el-option key="0" label="正常" value="0"></el-option>
            <el-option key="1" label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构选择" prop="parentId">
          <el-cascader
            v-model="editSelectPids"
            :options="editParentPermissionsTree"
            :props="permissionsProps"
            @change="onEditParentNodeChange"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="机构电话">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="机构邮箱">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="onPermissionsDialogClose">取消</el-button>
          <el-button type="primary" @click="submitEditPermission"
            >修改机构</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 新增部门 -->
    <el-dialog
      title="新增机构"
      class="permission-dialog"
      :visible.sync="permissionDialog"
      @close="onPermissionsDialogClose"
      width="30%"
    >
      <el-form
        ref="permissionForm"
        :model="permissionForm"
        :rules="permissionFormRules"
        label-width="100px"
        class="permission-form"
        label-position="left"
      >
        <el-form-item label="机构名称" prop="orgName">
          <el-input v-model="permissionForm.orgName"></el-input>
        </el-form-item>
        <el-form-item label="机构类型" prop="orgType">
          <el-select v-model="permissionForm.orgType" placeholder="机构类型">
            <el-option key="0" label="公司" value="0"></el-option>
            <el-option key="1" label="部门" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构状态" prop="status">
          <el-select v-model="permissionForm.status" placeholder="机构状态">
            <el-option key="0" label="正常" value="0"></el-option>
            <el-option key="1" label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构选择" prop="parentId">
          <el-cascader
            v-model="permissionForm.parentId"
            :options="parentPermissionsTree"
            :props="permissionsProps"
            @change="onParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="机构电话">
          <el-input v-model="permissionForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="机构邮箱">
          <el-input v-model="permissionForm.email"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="onPermissionsDialogClose">取消</el-button>
          <el-button type="primary" @click="submitPermission"
            >添加机构</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import { orgPage, roleAdd, roleDel, orgList } from "@/api/system/system.js";
import {
  orgPage,
  orgList,
  orgAdd,
  orgDel,
  orgUpdateStatus,
  orgUpdate,
} from "@/api/system/system.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  data() {
    return {
      //编辑
      editFormVisible: false,
      formLabelWidth: "80px",
      editForm: {},
      editFormRules: {
        orgName: [{ required: true, message: "机构名称为空", trigger: "blur" }],
        parentId: [
          { required: true, message: "机构选择为空", trigger: "blur" },
        ],
        orgType: [{ required: true, message: "机构类型为空", trigger: "blur" }],
        status: [{ required: true, message: "机构状态为空", trigger: "blur" }],
        selectPids: [
          { required: true, message: "机构选择为空", trigger: "blur" },
        ],
      },
      editParentPermissionsTree: [],
      editSelectPids: [],
      //----end
      tableData: [],
      permissionsList: [],
      expands: ["1"],
      pageTotal: 0,
      //新增角色
      addForm: {
        dataScope: "",
        remark: "",
        roleKey: "",
        roleName: "",
      },
      loading: false,
      permissionDialog: false,
      permissionForm: {
        parentId: "",
        orgName: "",
        orgType: "",
        phone: "",
        email: "",
        orderNum: 0,
        status: "",
      },
      permissionFormRules: {
        orgName: [{ required: true, message: "机构名称为空", trigger: "blur" }],
        parentId: [
          { required: true, message: "机构选择为空", trigger: "blur" },
        ],
        orgType: [{ required: true, message: "机构类型为空", trigger: "blur" }],
        status: [{ required: true, message: "机构状态为空", trigger: "blur" }],
        selectPids: [
          { required: true, message: "机构选择为空", trigger: "blur" },
        ],
      },
      selectPids: [], // 选中的父id数组
      parentPermissionsTree: [],
      // 父级联动选择器props配置
      permissionsProps: {
        label: "orgName",
        value: "id",
        checkStrictly: true,
      },
    };
  },
  created() {
    this.tkL();
    this.OrgList();
  },
  methods: {
    //------------------------新增---------------------
    //关闭新增部门
    onPermissionsDialogClose() {
      console.log("关闭新增部门");
      this.selectPids = []; // 选中的父id数组
      this.permissionForm = {
        parentId: "",
        orgName: "",
        orgType: "",
        phone: "",
        email: "",
        orderNum: 0,
        status: "",
      };
    },
    // 选择父级权限改变
    onParentNodeChange(selectPids) {
      // console.log("selectPids", selectPids, this.selectPids);
      if (selectPids) {
        this.permissionForm.parentId = selectPids[selectPids.length - 1];
        console.log("=", this.permissionForm.parentId);
      }

      // console.log(selectPids, selectPids[selectPids.length - 1]);
    },
    submitPermission() {
      this.$refs["permissionForm"].validate(async (valid) => {
        if (!valid) return false;
        let params = this.permissionForm;
        console.log(params);
        this.tkL();
        try {
          const that = this;
          const { code, data } = await orgAdd({
            email: this.permissionForm.email,
            orderNum: this.permissionForm.orderNum,
            orgName: this.permissionForm.orgName,
            orgType: this.permissionForm.orgType,
            parentId: this.permissionForm.parentId,
            phone: this.permissionForm.phone,
            status: this.permissionForm.status,
          });
          if (code == 0) {
            that.permissionDialog = false;
            that.tkL();
            that.OrgList();

            console.log(data);
          }
        } catch (e) {
          if (e && e.response) {
            this.$message.error(e.response.data.message);
          }
        }
      });
    },
    //------------------------新增end---------------------
    //---------编辑
    submitEditPermission() {
      this.$refs["editFormRules"].validate(async (valid) => {
        if (!valid) return false;
        const { code, msg } = await orgUpdate({
          email: this.editForm.email,
          orderNum: this.editForm.orderNum,
          orgName: this.editForm.orgName,
          orgType: this.editForm.orgType,
          parentId: this.editForm.parentId,
          phone: this.editForm.phone,
          status: this.editForm.status,
          id:this.editForm.id
        });
        if (code == 0) {
          this.$message.success("修改成功");
          this.editFormVisible = false;
          this.editForm = {};
          this.editSelectPids = [];
          this.OrgList();
        } else {
          this.$message.error(msg);
          this.editForm = {};
          this.editSelectPids = [];
        }
        this.tkL()
      });
    },
    onEditParentNodeChange(selectPids) {
      if (selectPids) {
        this.editForm.parentId = selectPids[selectPids.length - 1];
      }
    },
    //----------------------
    async switchChange(e, index, row) {
      console.log(e, index, row);
      let a = {
        id: row.id,
        status: e,
      };
      const { code, data } = await orgUpdateStatus(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        // this.$set(row, "status", e);
        this.$message.success("更改状态成功");
      } else {
        this.tkL();
        this.loading = false;
        this.$message.error("更改状态失败");
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    //分页
    async getData() {
      try {
        this.loading = true;
        let d = {
          pageNum: 1,
          pageSize: 10,
        };
        const { code, data } = await orgPage(d);
        if (code == 0) {
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
    },
    newly() {
      // this.addFormVisible = true;
      this.permissionDialog = true;
    },
    //组织列表
    async OrgList() {
      try {
        this.loading = true;
        const { code, data } = await orgList({
          // status: 0,
        });
        if (code == 0) {
          this.loading = false;
          this.permissionsList = this.conversionDataTool(data);
          this.parentPermissionsTree = this.permissionsList;
        }
      } finally {
        this.loading = false;
      }
    },
    /**
     * 数据转化 (工具)
     * 列表转为树形
     */
    conversionDataTool(list) {
      // filter筛选符合条件的元素，返回一个包含所有符合条件的元素的新数组
      return list.filter(
        (item1) =>
          !list.find((item2, index) => {
            // 如果有父节点
            //parentId 为父id
            if (item1.parentId === item2.id) {
              // 放进它父节点的children数组中；如果children不存在，初始化为空数组
              list[index].children = list[index].children || [];
              list[index].children.push(item1);
            }
            // find返回第一个符合条件的元素，找到后，剩余的元素不再判断
            return item1.parentId === item2.id;
          })
      );
    },
    //编辑
    handleEdit(row) {
      this.editFormVisible = true;
      this.editForm = JSON.parse(JSON.stringify(row));
      this._getPermissionInfoById(row.parentId);
    },
    //------
    async _getPermissionInfoById(permissionId) {
      const { code, data } = await orgList();
      if (code == 0) {
        this.loading = false;
        this.editParentPermissionsTree = this.conversionDataTool(data);
        if (permissionId !== "0") {
          let selectPids = [];
          this._setSelectPidsByTargetId(
            this.editParentPermissionsTree,
            permissionId,
            selectPids
          );
          this.editSelectPids = selectPids;
        }
      }
    },
    // 设置默认选中的父节点
    _setSelectPidsByTargetId(categoryList, targetId, selectPids) {
      let parentNode = this._getParentNodeByTargetId(categoryList, targetId);
      selectPids.unshift(parentNode["id"]);
      if (parentNode["parentId"] == 0) {
        return;
      }
      return this._setSelectPidsByTargetId(
        categoryList,
        parentNode["parentId"],
        selectPids
      );
    },
    // 获取父节点对象
    _getParentNodeByTargetId(categoryList, targetId) {
      let _this = this;
      for (let i = 0; i < categoryList.length; i++) {
        let category = categoryList[i];
        if (category["id"] === targetId) {
          return category;
        } else {
          if (category["children"] && category["children"].length > 0) {
            // 未找到遍历子级
            let res = _this._getParentNodeByTargetId(
              category["children"],
              targetId
            );
            if (res) {
              return res;
            }
          }
        }
      }
    },
    //----------------------------
    editRowSave(index, row) {
      console.log("editRowSave", index, row);
      console.log("editForm", this.editForm);
      // const {code,data} =
    },
    //删除角色
    async handleDelete(index, row) {
      console.log(index, row);
      this.tkL();
      this.$confirm("此操作将永久删除该机构, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, data, msg } = await orgDel({
            orgId: row.id,
          });
          if (code == 0) {
            console.log(data);
            // this.tableData.splice(index, 1);
            this.tkL();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.OrgList();
          } else if (code == 1) {
            this.tkL();
            this.$message({
              type: "error",
              message: msg,
            });
            this.OrgList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}
.red {
  color: red;
}
</style>