<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">也在网后台管理系统</div>
      <el-form
        :model="param"
        :rules="rules"
        ref="login"
        label-width="0px"
        class="ms-content"
      >
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="用户名"> </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="密码"
            v-model="param.password"
            @keyup.enter.native="submitForm()"
          >
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
      </el-form>

      <!-- <el-form
        class="login-form"
        :label-position="labelPosition"
        label-width="80px"
        :rules="formRules"
        :model="formInfo"
        ref="loginForm"
      >
        <h3 class="title">用户登录</h3>
        <el-form-item label="登录账号" prop="loginAccount">
          <el-input v-model="formInfo.loginAccount"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input
            @keyup.enter.native="postForm"
            v-model="formInfo.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="remember-item">
          <el-checkbox-group v-model="formInfo.remember">
            <el-checkbox label="记住密码"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item class="form-btn">
          <el-button
            class="login-btn"
            :loading="loading"
            @click="postForm"
            type="primary"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form> -->
    </div>
    <div class="Filing" @click="jump" style="cursor: pointer;">粤ICP备20068138号-2</div>
  </div>
</template>

<script>
import { loginAuth } from "@/api/login/login.js"; //获取用户信息,登录
import local from "@/api/common/local.js";
// import { subTk } from "@/api/common.js";
export default {
  data: function () {
    return {
      param: {
        // username: 'admin',
        // password: '123123',
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      // loading: false,
      // labelPosition: "right",
      // formInfo: {
      //   loginAccount: "",
      //   password: "",
      //   remember: false, // 记住密码
      // },
      // formRules: {
      //   loginAccount: [
      //     { required: true, message: "请填写账号", trigger: "blur" },
      //   ],
      //   password: [{ required: true, message: "请填写密码", trigger: "blur" }],
      // },
    };
  },
  created() {
    // this.Tkl();
    if(localStorage.getItem('access_token')){
       this.$router.push("/dashboard");
    }
  },
  methods: {
    jump() {
      window.open(`https://beian.miit.gov.cn/#/Integrated/index`, "_blank");
    },
    // async Tkl() {
    //   const { code, data } = await subTk();
    //   if (code == 0) {
    //     local.SessionSet("sub_tk", data);
    //     console.log(data, "临时令牌");
    //   }
    // },
    submitForm() {
      this.$refs.login.validate(async (valid) => {
        // console.log("d", valid);

        if (valid) {
          //测试

          let Data = {
            account: this.param.username,
            password: this.param.password,
            imeiCode: "",
            loginMethod: "P",
            loginSource: "0",
            loginType: "AM",
            smsCode: "",
          };
          const { code, data } = await loginAuth(Data);
          if (code == 0) {
            this.$message.success("登录成功");
            // const that = this;
            // console.log("__====___", data.access_token);
            // local.set("access_token", data.access_token);
            // localStorage.setItem("access_token", data.access_token);
            local.set("access_token", data.access_token); //存储令牌
            this.$router.push("/dashboard");
          } else {
            this.$message.error("登录失败");
          }
        } else {
          this.$message.error("请输入账号和密码");
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 1200px;
  background-image: url(../../assets/img/login-bg.jpg);
  // background-image: url(../../assets/img/01.jpg);
  /* background-repeat: no-repeat; */
  background-size: 100%;
}
.Filing{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
  // font-size: 18px;

  color: #fff;
}
.Filing:hover{
  text-decoration: underline; 
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  // .login-form {
  //   width: 350px;
  //   padding: 30px 35px;
  //   background-color: #fff;
  //   border-radius: 10px;
  //   box-shadow: 0 0 20px 2px #f6f6f6;
  //   border: 1px solid #eaeaea;
  //   .title {
  //     color: #505458;
  //     margin: 0 auto 40px;
  //     width: 100%;
  //     text-align: center;
  //   }
  //   .remember-item {
  //     /deep/ .el-form-item__content {
  //       margin-left: 0 !important;
  //     }
  //   }
  //   .form-btn {
  //     width: 100%;
  //     text-align: center;
  //     margin-top: 20px;
  //     /deep/ .el-form-item__content {
  //       margin-left: 0 !important;
  //     }
  //     .login-btn {
  //       width: 100%;
  //     }
  //   }
  // }
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>