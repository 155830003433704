import request from "@/utils/request.js"

//会员分页
export const infoUserPage = ((data) => {
    return request.post('/member-server/member/info/manage/page', data)
})
//会员处理

//会员状态 
export const memberInfoUpdateStatus = ((data) => {
    return request.postt('/member-server/member/info/update/status', data)
})
//会员用户相册列表
export const albumList = ((data) => {
    return request.postt('/member-server/member/album/list', data)
})

//会员基本信息审核 
export const auditor = ((data) => {
    return request.postt('/member-server/member/info/history/page', data)
})
// 会员待审核数据信息
export const updateAapprove = ((data) => {
    return request.post('/member-server/member/info/update/approve', data)
})
// 会员消息记录
export const message = ((data) => {
    return request.postt('/member-server/member/message/view/message/page', data)
})
//会员举报
export const report = ((data) => {
    return request.postt('/member-server/member/inform/page', data)
})
// 处理会员举报
export const reportAudit = ((data) => {
    return request.post('/member-server/member/inform/hand', data)
})
//-------------
//会员观点 
export const memberPointList = ((data) => {
    return request.postt('/member-server/member/point/list', data)
})
//会员推荐 /member-server/member/recommend/page
export const memberRecommendPage = ((data) => {
    return request.postt('/member-server/member/recommend/page', data)
})
//添加会员推荐
export const memberRecommendAdd = ((data) => {
    return request.post('/member-server/member/recommend/add', data)
})
//删除会员推荐
export const memberRecommendDel = ((data) => {
    return request.post('/member-server/member/recommend/del', data)
})

// 会员服务申请分页
export const serviceAppPage = ((data) => {
    return request.postt('/member-server/member/service/app/page', data)
})
//会员服务申请审核
export const serviceAppUpdate = ((data) => {
    return request.post('/member-server/member/service/app/update', data)
})
//会员相互喜欢列表 
export const memberLoveList = ((data) => {
    return request.postt('/member-server/member/love/list', data)
})
//会员屏蔽列表  shieldData
export const memberShieldList = ((data) => {
    return request.postt('/member-server/member/shield/list', data)
})

//当前用户信息
export const query = ((data) => {
    return request.gettLLX(`/member-server/member/info/info/${data}`)
})
/**
 * 看过,关注, 赞过,评论
 */
//会员点赞  
export const memberLikePage = ((data) => {
    return request.postt('/member-server/member/like/page', data)
})
// 会员关注
// export const attention = ((data) => {
//     return request.postt('/member-server/member/follow/page', data)
// })
export const memberFollowPage = ((data) => {
    return request.postt('/member-server/member/follow/page', data)
})
//看过
export const memberViewBrowingPage = ((data) => {
    return request.postt('/im-server/member/view/browsing/page', data)
})
//评论 
export const memberCommentPage = ((data) => {
    return request.postt('/member-server/member/comment/page', data)
})

/**
 * 查询视频分页 - 个人动态主页(如果不传会员ID，默认查询当前登录人的动态分页)
 * /member/video/page
 */
export const memberVideoPage = ((data) => {
    return request.postt('member-server/member/video/page', data)
})
//视频删除 /member/video/del/manage
export const memberVideoDel = ((data) => {
    return request.post('member-server/member/video/del/manage', data)
})
/**
 *查询动态分页 - 个人动态主页(如果不传会员ID，默认查询当前登录人的动态分页) 
 */
export const memberDynamicPage = ((data) => {
    return request.postt('member-server/member/dynamic/page', data)
})
//动态删除
export const memberDynamicDel = ((data) => {
    return request.post('member-server/member/dynamic/del/manage', data)
})
/**
 * 刷新会员缓存信息
 */
 export const memberInfoRefreshInner = ((data) => {
    return request.gettLL('member-server/member/info/refresh_inner', data)
})

/**
 * 删除用户相册
 */

//删除相册 /member/album/del 
export const albumDel = ((data) => {
    // 
    // /member-server/member/album/del
    return request.postt('/member-server/member/album/manage/del', data)
})