<template>
  <div class="user-container">
    <div class="table-top20">
      <el-card>
        <!-- 搜索框 -->
        <!-- <div class="search-row">
          <el-input
            placeholder="请输入权限名称"
            v-model="keywords"
            class="search-input"
          >
            <el-button
              slot="append"
              @click="searchPermission"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div> -->
        <div class="add-btn-row">
          <el-button
            type="primary"
            v-permission="'sys:menu:add'"
            @click="showAddPermissionDialog"
            size="small"
            >添加权限</el-button
          >
        </div>
        <el-table
          v-loading="loading"
          :data="permissionsList"
          style="width: 100%; margin-bottom: 20px; margin-top: 20px"
          :row-key="getRowKeys"
          border
          :tree-props="{ children: 'children' }"
        >
          <el-table-column prop="menuName" label="权限名称"> </el-table-column>
          <el-table-column prop="path" label="权限路径"></el-table-column>
          <el-table-column prop="orderNum" label="排序"></el-table-column>
          <el-table-column prop="icon" label="图标路径"></el-table-column>
          <el-table-column prop="perms" label="权限标识"></el-table-column>
          <el-table-column prop="menuType" label="菜单类型">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.menuType == "M"
                    ? "目录"
                    : scope.row.menuType == "C"
                    ? "菜单"
                    : "按钮"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column width="220px" label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="editPermission(scope.row)"
                type="primary"
                icon="el-icon-edit"
                v-permission="'sys:menu:update'"
                >编辑</el-button
              >
              <el-button
                size="mini"
                @click="delPermission(scope.row.id)"
                type="danger"
                icon="el-icon-delete"
                v-permission="'sys:menu:del'"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <!-- 添加权限弹窗 -->
    <el-dialog
      title="添加权限"
      class="permission-dialog"
      :visible.sync="permissionDialog"
      @close="onPermissionsDialogClose"
    >
      <el-form
        ref="permissionForm"
        :model="permissionForm"
        :rules="permissionFormRules"
        label-width="100px"
        class="permission-form"
        label-position="left"
      >
        <el-form-item label="菜单类型" prop="menuName">
          <el-radio-group v-model="permissionForm.menuType">
            <el-radio :label="'C'">菜单</el-radio>
            <el-radio :label="'F'">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="permissionForm.menuName"></el-input>
        </el-form-item>
        <el-form-item label="菜单顺序">
          <el-input-number
            v-model="permissionForm.orderNum"
            :min="0"
            :max="1000"
          ></el-input-number>
        </el-form-item>
        <span class="notice">一级权限不用选择</span>
        <el-form-item label="父级选择">
          <el-cascader
            v-model="selectPids"
            :options="parentPermissionsTree"
            :props="permissionsProps"
            clearable
            @change="onParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="菜单路径" prop="path">
          <el-input v-model="permissionForm.path"></el-input>
        </el-form-item>
        <el-form-item label="权限标识" prop="perms">
          <el-input v-model="permissionForm.perms"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="permissionForm.icon"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeAddPermissionDialog">取消</el-button>
          <el-button type="primary" @click="submitPermission"
            >添加权限</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 编辑权限弹窗 -->
    <el-dialog
      title="编辑权限"
      class="permission-dialog"
      :visible.sync="editPermissionDialog"
      @close="onEditPermissionsDialogClose"
    >
      <el-form
        ref="editPermissionFormRules"
        :model="editPermissionForm"
        :rules="editPermissionFormRules"
        label-width="100px"
        class="permission-form"
        label-position="left"
      >
        <el-form-item label="菜单类型" prop="menuName">
          <el-radio-group v-model="editPermissionForm.menuType">
            <el-radio :label="'C'">菜单</el-radio>
            <el-radio :label="'F'">按钮</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="editPermissionForm.menuName"></el-input>
        </el-form-item>
        <el-form-item label="菜单顺序">
          <el-input-number
            v-model="editPermissionForm.orderNum"
            :min="0"
            :max="1000"
          ></el-input-number>
        </el-form-item>
        <span class="notice">一级权限不用选择</span>
        <el-form-item label="父级选择">
          <el-cascader
            v-model="editSelectPids"
            :options="editParentPermissionsTree"
            :props="permissionsProps"
            clearable
            @change="onEditParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="菜单路径" prop="path">
          <el-input v-model="editPermissionForm.path"></el-input>
        </el-form-item>
        <el-form-item label="权限标识" prop="perms">
          <el-input v-model="editPermissionForm.perms"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标">
          <el-input v-model="editPermissionForm.icon"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="onEditPermissionsDialogClose">取消</el-button>
          <el-button type="primary" @click="submitEditPermission"
            >修改权限</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  sysMenuListRecursion,
  sysMenuAdd,
  sysMenuDel,
  sysMenuUpdate,
  // sysMenuList,
} from "@/api/system/system.js"; //
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  name: "permissions",
  data() {
    return {
      loading:false,
      permissionsList: [],
      keywords: "",
      currentPage: 1,
      lastPage: 1,
      listRows: 10, // 一页显示条数
      total: 0,
      pageTotal: 0,
      pageSizes: [10, 20, 30, 40],
      permissionDialog: false,
      parentPermissionsTree: [], // 父级权限列表数据
      // 父级联动选择器props配置
      permissionsProps: {
        label: "menuName",
        value: "id",
        checkStrictly: true,
      },
      selectPids: [], // 选中的父id数组
      permissionForm: {
        component: "", //组件路径
        icon: "", //菜单图标
        menuName: "", //菜单名称
        menuType: "C", //菜单类型（M目录 C菜单 F按钮）
        orderNum: 0, //显示顺序
        parentId: 0,
        path: "", //路由地址
        perms: "", //权限标识
      },
      permissionFormRules: {
        menuName: [
          { required: true, message: "菜单名称为空", trigger: "blur" },
        ],
        path: [{ required: true, message: "菜单路径为空", trigger: "blur" }],
        perms: [{ required: true, message: "权限标识为空", trigger: "blur" }],
      },

      //编辑 editPermissionDialog
      editPermissionDialog: false,
      editPermissionForm: {},
      editParentPermissionsTree: [],
      editSelectPids: [],
      editPid: "",
      editPermissionFormRules: {
        menuName: [
          { required: true, message: "菜单名称为空", trigger: "blur" },
        ],
        path: [{ required: true, message: "菜单路径为空", trigger: "blur" }],
        perms: [{ required: true, message: "权限标识为空", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {
    this.getPermissionsList();
    this.tkL();
  },
  methods: {
    //关闭
    onEditPermissionsDialogClose() {
      this.editSelectPids = [];
      this.editPid = "";
      this.editPermissionDialog = false;
    },
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    submitEditPermission() {
      console.log(this.editPermissionForm, " this.editPermissionForm");
      this.$refs["editPermissionFormRules"].validate(async (valid) => {
        if (!valid) return false;
        const { code, msg } = await sysMenuUpdate({
          component: this.editPermissionForm.component,
          icon: this.editPermissionForm.icon,
          id: this.editPermissionForm.id,
          menuName: this.editPermissionForm.menuName,
          menuType: this.editPermissionForm.menuType,
          orderNum: this.editPermissionForm.orderNum,
          parentId: this.editPermissionForm.parentId || "0",
          path: this.editPermissionForm.path,
          perms: this.editPermissionForm.perms,
          visible: this.editPermissionForm.visible,
        });
        if (code == 0) {
          this.$message.success("修改成功");
          this.editPermissionDialog = false;
          this.editPermissionForm = {};
          this.editSelectPids = [];
          this.getPermissionsList();
        } else {
          this.$message.error(msg);
          this.editPermissionForm = {};
          this.editSelectPids = [];
        }
        this.tkL();
      });
    },
    //------------------------------------
    getRowKeys(row) {
      return row.id;
    },
    //树结构扁平化
    flatTree(data, childName = "children") {
      if (!Array.isArray(data)) {
        console.warn("只支持传入数组");
        return [];
      }
      return data.reduce((prev, curt) => {
        // 有子节点的话把子节点作为一级节点递归遍历
        const childs = curt[childName]?.length
          ? this.flatTree(curt[childName])
          : [];
        return [...prev, curt, ...childs];
      }, []);
    },
    async getPermissionsList() {
      try {
        this.loading = true;
        const { code, data } = await sysMenuListRecursion();
        if (code === 0) {
          this.permissionsList = data;
          this.loading = false;
          // this.editParentPermissionsTree = JSON.parse(JSON.stringify(data));
        }
      } catch (e) {
        this.loading = false;
        if (e && e.response) {
          this.$message.error("请求异常");
        }
      }
    },
    showAddPermissionDialog() {
      this.permissionDialog = true;
      this._getParentPermissionsTree();
    },
    closeAddPermissionDialog() {
      this.permissionDialog = false;
    },
    // 选择父级权限改变
    onParentNodeChange(selectPids) {
      this.permissionForm.parentId = selectPids[selectPids.length - 1];
      // console.log(selectPids, selectPids[selectPids.length - 1]);
    },
    onEditParentNodeChange(selectPids) {
      this.editPermissionForm.parentId = selectPids[selectPids.length - 1];
    },
    // 添加权限
    submitPermission() {
      // console.log(this.permissionForm);
      this.$refs["permissionForm"].validate(async (valid) => {
        if (!valid) return false;
        let params = this.permissionForm;
        // console.log(params);
        this.tkL();
        try {
          if (this.permissionForm.parentId == 0) {
            const { code, data, msg } = await sysMenuAdd({
              component: params.component,
              icon: params.icon,
              menuName: params.menuName,
              menuType: params.menuType,
              orderNum: params.orderNum,
              parentId: params.parentId,
              path: params.path,
              perms: params.perms,
              visible: 0,
            });
            if (code == 0) {
              console.log(data);
              this.tkL();
              this.getPermissionsList();
              this.$message.success("添加成功");
              this.permissionForm = {
                component: "", //组件路径
                icon: "", //菜单图标
                menuName: "", //菜单名称
                menuType: "C", //菜单类型（M目录 C菜单 F按钮）
                orderNum: 0, //显示顺序
                parentId: 0,
                path: "", //路由地址
                perms: "", //权限标识
              };
            } else {
              this.$message.error(msg);
            }
          } else {
            const { code, data, msg } = await sysMenuAdd({
              component: this.permissionForm.component,
              icon: this.permissionForm.icon,
              menuName: this.permissionForm.menuName,
              menuType: this.permissionForm.menuType,
              // menuType: this.permissionForm.menuType,
              orderNum: this.permissionForm.orderNum,
              parentId: this.permissionForm.parentId,
              path: this.permissionForm.path,
              perms: this.permissionForm.perms,
              visible: 0,
            });
            if (code == 0) {
              console.log(data);
              this.tkL();
              this.getPermissionsList();
              this.$message.success("添加成功");
              // this.$message.success("有父");
              this.permissionForm = {
                component: "", //组件路径
                icon: "", //菜单图标
                menuName: "", //菜单名称
                menuType: "C", //菜单类型（M目录 C菜单 F按钮）
                orderNum: 0, //显示顺序
                parentId: 0,
                path: "", //路由地址
                perms: "", //权限标识
              };
            } else {
              this.$message.error(msg);
              this.permissionForm = {
                component: "", //组件路径
                icon: "", //菜单图标
                menuName: "", //菜单名称
                menuType: "C", //菜单类型（M目录 C菜单 F按钮）
                orderNum: 0, //显示顺序
                parentId: 0,
                path: "", //路由地址
                perms: "", //权限标识
              };
            }
          }
          this.permissionDialog = false;
          // this.getPermissionsList();

          //   dispatch("renderNavigationPermissionByRoleId");
          this.$store.dispatch("GET_USER_PERMISSION");
        } catch (e) {
          if (e && e.response) {
            this.$message.error(e.response.data.message);
          }
        }
      });
    },
    //搜索
    // searchPermission() {
    //   this.getPermissionsList();
    // },
    // 权限弹窗关闭触发
    onPermissionsDialogClose() {
      this.selectPids = [];
      this.selectPid = 0;
      this.permissionForm = {
        component: "", //组件路径
        icon: "", //菜单图标
        menuName: "", //菜单名称
        menuType: "C", //菜单类型（M目录 C菜单 F按钮）
        orderNum: 0, //显示顺序
        parentId: 0,
        path: "", //路由地址
        perms: "", //权限标识
      };
    },
    editPermission(row) {
      // this._getPermissionInfoById(row.parentId);
      this.editPid = row.parentId;
      this._getPermissionInfoById(row.parentId);
      this.editPermissionForm = JSON.parse(JSON.stringify(row));
      this.editPermissionDialog = true;
    },
    async delPermission(id) {
      this.tkL();
      try {
        let res = await this.$confirm("你确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        if (res === "confirm") {
          try {
            // let { data: res } = await delPermission(id);
            // if (res.code === 203) {
            //   this.$message.success("删除成功");
            //   this.getPermissionsList();
            //   this.$store.dispatch("renderNavigationPermissionByRoleId");
            // }
            const { code, msg } = await sysMenuDel({
              id: id,
            });
            if (code == 0) {
              this.$message.success("删除成功");
              this.getPermissionsList();
              this.tkL();
            } else if (code == 1) {
              this.$message.error(msg);
              this.getPermissionsList();
              this.tkL();
            }
          } catch (e) {
            this.$message.error("删除异常");
          }
        }
      } catch (e) {
        this.$message.info("已取消");
      }
    },
    // 获取包含层次限制权限列表请求
    async _getParentPermissionsTree() {
      const { code, data } = await sysMenuListRecursion();
      if (code === 0) {
        this.parentPermissionsTree = data;
      }
    },
    // 通过id获取权限信息
    async _getPermissionInfoById(permissionId) {
      // console.log(permissionId);
      // if(this.editPid !== "0"){
      //   console.log('____');
      // }else{
      //   console.log('=======');
      // }
      const { code, data } = await sysMenuListRecursion();
      if (code == 0) {
        this.editParentPermissionsTree = data;
        if (permissionId !== "0") {
          let selectPids = [];
          this._setSelectPidsByTargetId(
            this.editParentPermissionsTree,
            permissionId,
            selectPids
          );
          this.editSelectPids = selectPids;
        }
      }
    },
    // 设置默认选中的父节点
    _setSelectPidsByTargetId(categoryList, targetId, selectPids) {
      let parentNode = this._getParentNodeByTargetId(categoryList, targetId);

      selectPids.unshift(parentNode["id"]);

      if (parentNode["parentId"] == 0) {
        return;
      }
      return this._setSelectPidsByTargetId(
        categoryList,
        parentNode["parentId"],
        selectPids
      );
    },
    // 获取父节点对象
    _getParentNodeByTargetId(categoryList, targetId) {
      let _this = this;
      for (let i = 0; i < categoryList.length; i++) {
        let category = categoryList[i];
        if (category["id"] === targetId) {
          return category;
        } else {
          if (category["children"] && category["children"].length > 0) {
            // 未找到遍历子级
            let res = _this._getParentNodeByTargetId(
              category["children"],
              targetId
            );
            if (res) {
              return res;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-row {
  .search-input {
    width: 350px;
  }
}
.paginate-row {
  margin-top: 15px;
}
// .add-btn-row {
//   margin-top: 20px;
//   margin-bottom: 20px;
// }
// .permission-form {
//   height: 360px;
// }
.notice {
  font-size: 12px;
  color: red;
}
</style>