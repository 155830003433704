<template>
  <div>
    <el-card>
      <!-- v-permission="'sys:menu:add'" -->
      <div class="add-btn-row">
        <el-button
          type="primary"
          v-permission="'sys:config:add'"
          @click="showAddPermissionDialog"
          size="small"
          >添加参数</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        style="margin-top: 20px"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="configName" label="参数名称"> </el-table-column>
        <el-table-column prop="configKey" label="参数标识"></el-table-column>
        <el-table-column prop="configType" label="系统内置"></el-table-column>
        <el-table-column prop="configValue" label="参数内容"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column width="220px" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="editPermission(scope.row)"
              type="primary"
              icon="el-icon-edit"
              v-permission="'sys:menu:update'"
              >编辑</el-button
            >
            <el-button
              size="mini"
              @click="delPermission(scope.row)"
              type="danger"
              icon="el-icon-delete"
              v-permission="'sys:menu:del'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="form.pageNum"
          :page-size="form.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      width="500px"
      class="dialogForm"
      :visible.sync="editFormVisible"
      @close="onPermissionsDialogClose"
    >
      <el-form
        :model="editForm"
        label-width="100px"
        class="permission-form"
        label-position="left"
        :rules="editFormRules"
        ref="editFormRules"
      >
        <el-form-item label="参数名称" prop="configName">
          <el-input v-model="editForm.configName"></el-input>
        </el-form-item>
        <el-form-item label="参数标识" prop="configKey">
          <el-input v-model="editForm.configKey"></el-input>
        </el-form-item>
        <el-form-item label="参数内容" prop="configValue">
          <el-input v-model="editForm.configValue"></el-input>
        </el-form-item>
        <el-form-item label="系统内置" prop="configType">
          <el-radio-group v-model="editForm.configType">
            <el-radio label="Y">是</el-radio>
            <el-radio label="N">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="参数状态" prop="status">
          <el-radio-group v-model="editForm.status">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="editForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRowSave()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 新增 -->
    <el-dialog
      title="新增"
      width="500px"
      class="dialogForm"
      :visible.sync="addFormVisible"
      @close="addPermissionsDialogClose"
    >
      <el-form
        :model="addForm"
        label-width="100px"
        class="permission-form"
        label-position="left"
        :rules="addFormRules"
        ref="addFormRules"
      >
        <el-form-item label="参数名称" prop="configName">
          <el-input v-model="addForm.configName"></el-input>
        </el-form-item>
        <el-form-item label="参数标识" prop="configKey">
          <el-input v-model="addForm.configKey"></el-input>
        </el-form-item>
        <el-form-item label="参数内容" prop="configValue">
          <el-input v-model="addForm.configValue"></el-input>
        </el-form-item>
        <el-form-item label="系统内置" prop="configType">
          <el-radio-group v-model="addForm.configType">
            <el-radio label="Y">是</el-radio>
            <el-radio label="N">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="参数状态" prop="status">
          <el-radio-group v-model="addForm.status">
            <el-radio label="0">正常</el-radio>
            <el-radio label="1">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRowSave()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  sysConfigPage,
  sysConfigAdd,
  sysConfigDel,
  sysConfigUpdate,
} from "@/api/system/system.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  data() {
    return {
      tableData: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      loading: false,
      editForm: {},
      editFormVisible: false,
      addForm: {},
      addFormVisible: false,
      addFormRules: {
        configName: [
          { required: true, message: "参数名称为空", trigger: "blur" },
        ],
        configKey: [
          { required: true, message: "参数标识为空", trigger: "blur" },
        ],
        configValue: [
          { required: true, message: "参数内容为空", trigger: "blur" },
        ],
        configType: [
          { required: true, message: "系统内置为空", trigger: "blur" },
        ],
        status: [{ required: true, message: "参数状态为空", trigger: "blur" }],
      },
      editFormRules: {
        configName: [
          { required: true, message: "参数名称为空", trigger: "blur" },
        ],
        configKey: [
          { required: true, message: "参数标识为空", trigger: "blur" },
        ],
        configValue: [
          { required: true, message: "参数内容为空", trigger: "blur" },
        ],
        configType: [
          { required: true, message: "系统内置为空", trigger: "blur" },
        ],
        status: [{ required: true, message: "参数状态为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    showAddPermissionDialog() {
      this.addFormVisible = true;
    },
    async getData() {
      try {
        this.loading = true;
        const { code, data } = await sysConfigPage({
          pageNum: this.form.pageNum,
          pageSize: this.form.pageSize,
        });
        if (code == 0) {
          console.log(data);
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
    },
    handlePageChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    //编辑
    editPermission(row) {
      this.editForm = JSON.parse(JSON.stringify(row));
      this.editFormVisible = true;
    },
    //删除
    delPermission(row) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, data } = await sysConfigDel({
            id: row.id,
          });
          if (code == 0) {
            console.log(data);
            // this.tableData.splice(index, 1);
            this.getData();
          }
          this.tkL();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //确认修改
    editRowSave() {
      console.log("___", this.editForm);
      this.$refs["editFormRules"].validate(async (valid) => {
        if (!valid) return false;
        console.log("___", this.editForm);
        try {
          const { code, msg } = await sysConfigUpdate({
            id: this.editForm.id,
            configName: this.editForm.configName, //- 参数名称
            configKey: this.editForm.configKey, //- 参数键名
            configValue: this.editForm.configValue, //- 参数键值
            configType: this.editForm.configType, // - 系统内置（Y是 N否）
            status: this.editForm.status, //- 状态（0正常 1停用）
            remark: this.editForm.remark, // 备注
          });

          if (code == 0) {
            this.tkL();
            this.getData();
            this.$message.success("修改成功");
          } else {
            this.tkL();
            this.$message.error(msg);
          }
          this.editFormVisible = false;
        } catch (e) {
          this.tkL();
          console.log(e);
        }
      });
    },
    onPermissionsDialogClose() {},
    //新增确认
    addRowSave() {
      // this.tkL();
      this.$refs["addFormRules"].validate(async (valid) => {
        if (!valid) return false;
        console.log("___", this.addForm);
        try {
          const { code, msg } = await sysConfigAdd({
            configName: this.addForm.configName, //- 参数名称
            configKey: this.addForm.configKey, //- 参数键名
            configValue: this.addForm.configValue, //- 参数键值
            configType: this.addForm.configType, // - 系统内置（Y是 N否）
            status: this.addForm.status, //- 状态（0正常 1停用）
            remark: this.addForm.remark, // 备注
          });

          if (code == 0) {
            this.tkL();
            this.getData();
            this.$message.success("添加成功");
          } else {
            this.tkL();
            this.$message.error(msg);
          }
        } catch (e) {
          this.tkL();
          console.log(e);
        }
        this.addFormVisible = false;
        this.addForm = {};
      });
    },
    async fn() {
      const { code, data } = await sysConfigAdd({
        configName: "测试", //- 参数名称
        configKey: "ceshi", //- 参数键名
        configValue: "测试测试", //- 参数键值
        configType: "Y", // - 系统内置（Y是 N否）
        status: "0", //- 状态（0正常 1停用）
        remark: "12345", // 备注
      });

      if (code == 0) {
        this.tkL();
        console.log(data);
      } else {
        this.tkL();
      }
    },
    addPermissionsDialogClose() {},
  },
};
</script>

<style lang="scss" scoped>
</style>