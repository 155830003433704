let OSS = require('ali-oss')

import env from "@/api/env.js"
//开发环境 和 生产环境
export function client() {
    return new OSS({
        region: 'oss-cn-shenzhen',
        accessKeyId: 'LTAI5tBGEb6ZBpSU96fV5o4B',
        accessKeySecret: 'Qu5bIjZ2exoSx0chpzm1uNRsXF4D6E',
        /**
         * 测试
         */
        bucket: env.bucket
    })
}

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
    function rx() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return `yezai_${+new Date()}_${rx()}${rx()}`
}