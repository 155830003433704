import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
// import local from "@/api/common/local.js";

import './assets/css/main.css'
//  import "./assets/css/main.css"; 
 import "./assets/css/color-dark.css"; 

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
    size: 'small'
});

import { message } from '@/assets/js/resetMessage.js'
Vue.prototype.$message = message;
// import { Message } from 'element-ui'
// Vue.use(Message)
// Vue.prototype.$message = Message

import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('zh-cn');

import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
Vue.use(VueQuillEditor);

//自定义指令
import permission from '@/directive/index'
Vue.use(permission)

//引入矢量图
import './assets/icons/iconfont.css'


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
    // created() {
    //     if (this.$store.state.btnPermission == "" && local.SessionGet("menuList")) {
    //         this.$store.state.btnPermission = sessionStorage.getItem("menuList");
    //     } else {
    //         //刷新 如果本地存储没有值 跳转到首页
    //         this.$router.push("/login");
    //         local.clear();
    //     }
    // }
}).$mount('#app');

