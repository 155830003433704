import Vue from 'vue'
import Vuex from 'vuex'
// import {sysMenuListRecursion,} from "@/api/system/system.js";
import { userInfo } from "@/api/common.js";
import local from "@/api/common/local.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    adminToken: localStorage.getItem('token') || '', // 管理员token
    adminAuthId: localStorage.getItem('auth_id') || '',
    // 管理员信息
    adminInfo: localStorage.getItem('admin_info') ? JSON.parse(localStorage.getItem('admin_info')) : {},
    permissionNavTreeList: [],
    btnPermission: [],
    permission: ['aliyun', 'ecs', 'PURCHASE'],
  },
  mutations: {
    saveAdminInfo(state, data) {
      state.adminToken = data.token;
      state.adminAuthId = data.auth_id;
      state.adminInfo = data;
    },
    clearLoginInfo(state) {
      state.adminToken = '';
      state.adminAuthId = '';
      state.adminInfo = {};
      state.permissionNavTreeList = [];
    },
    savePermissionsNavTree(state, data) {
      state.permissionNavTreeList = data;
    },
    saveAuthTokenResult(state, data) {
      state.authTokenResult = data;
    },
    getUserPermission(state) {
      sessionStorage.setItem('menuList', JSON.stringify(state.btnPermission))

    },

    //测试
    setNumber(state, payload) {
      state.number = payload.number
    }
  },
  getters: {
    permissions(state) {
      return state.btnPermission
    },
  },
  actions: {
    //ceshi11
    setNum(content, payload) {   // 增加payload参数
      return new Promise(resolve => {
        setTimeout(() => {
          content.commit('setNumber', { number: payload.number });
          resolve();
        }, 1000);
      });
    },
    //end
    GenerateRoutes({ commit }, asyncRouter) {
      commit('SET_ROUTERS', asyncRouter)
    },
    SetSidebarRouters({ commit }, sidebarRouter) {
      commit('SET_SIDEBAR_ROUTERS', sidebarRouter)
    },
    // 请求渲染左侧权限导航
    // async renderNavigationPermissionByRoleId({ commit, state }) {
    //   const { data: res } = await getPermissionsByRoleId(state.adminInfo.role_id, {
    //     type: 'tree'
    //   });
    //   if (res.code === 200) {
    //     commit('savePermissionsNavTree', res.data);
    //   }
    // async GET_USER_PERMISSION({ commit, state }, payload) {
    async GET_USER_PERMISSION({ commit, state }) {
      let token = local.get("access_token");
      const { code, data } = await userInfo(token);
      if (code == 0) {
        var dest = [];
        const dataPermissions = data.permissions
        for (var i = 0; i < dataPermissions.length; i++) {
          var ai = dataPermissions[i];
          dest.push(ai.perms)
        }
        state.btnPermission = dest;
        state.adminInfo = data.user;
        commit("getUserPermission");
      }
    },



  },
  modules: {
  }
})
export const filterAsyncRouter = (routers, lastRouter = false, type = false) => { // 遍历后台传来的路由字符串，转换为组件对象
  console.log(lastRouter);
  return routers.filter(router => {
    if (type && router.children) {
      router.children = filterChildren(router.children)
    }
    // if (router.component) {
    //   if (router.component === 'Layout') { // Layout组件特殊处理
    //     router.component = Layout
    //   } else if (router.component === 'ParentView') {
    //     router.component = ParentView
    //   } else {
    //     const component = router.component
    //     router.component = loadView(component)
    //   }
    // }
    if (router.children != null && router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router, type)
    } else {
      delete router['children']
      delete router['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    console.log(index);
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

/**
 *       // console.log(commit,state);

      // const flatTree = function (data, childName = "children") {
      //   if (!Array.isArray(data)) {
      //     console.warn("只支持传入数组");
      //     return [];
      //   }
      //   return data.reduce((prev, curt) => {
      //     // 有子节点的话把子节点作为一级节点递归遍历
      //     const childs = curt[childName]?.length
      //       ? flatTree(curt[childName])
      //       : [];
      //     return [...prev, curt, ...childs];
      //   }, []);
      // }
      // const { code, data } = await sysMenuListRecursion();
      // if (code === 0) {
      //   // state.btnPermission = flatTree(data)

      //   //测试start
      //   // var map = {},
      //   var dest = [];
      //   for (var i = 0; i < flatTree(data).length; i++) {
      //     var ai = flatTree(data)[i];
      //     dest.push(ai.perms)
      //   }
      //   state.btnPermission = dest
      //   //---------------------测试end
      //   commit("getUserPermission");
      // }
      //====================
 */