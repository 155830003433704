<template>
  <div>
    <div class="error-page">
      <div class="error-handle">
        <el-button class="error-btn" type="primary" size="large" @click="goBack"
          >返回上一页</el-button
        >
      </div>
    </div>
    <div class="container" v-loading="loading">
      <div class="container-et">
        <p>基本资料</p>
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          class="container-img"
        >
          <el-form-item label="会员头像">
            <el-image
              :preview-src-list="UserAlbumL"
              style="width: 100px; height: 100px"
              :src="form.htImg"
            ></el-image>
          </el-form-item>
        </el-form>

        <el-form
          ref="form"
          class="container-et-info"
          :model="form"
          label-width="100px"
        >
          <el-form-item label="会员昵称">
            <el-input
              v-model="form.nickName"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="会员ID">
            <el-input
              v-model="form.id"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="会员年龄">
            <el-input
              v-model="form.age"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="会员性别">
            <el-select
              v-model="form.sex"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in sexIons"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出生日期">
            <el-date-picker
              v-model="form.birthday"
              type="date"
              disabled
              placeholder="选择日期"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="买车状态">
            <el-select
              v-model="form.buyCarStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in buyCarStatus"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="星座">
            <el-select
              v-model="form.constellatory"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in constellations"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="脱单状态">
            <el-select
              v-model="form.deSingleStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in deSingleStatusions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否喝酒">
            <el-select
              v-model="form.doDrink"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in doDrinks"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否吸烟">
            <el-select
              v-model="form.doSmake"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in doSmakes"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="学历">
            <el-select
              v-model="form.education"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in educations"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否有孩子">
            <el-select
              v-model="form.existsChildren"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in existsChildrens"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="人脸认证">
            <el-select
              v-model="form.faceAuthStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in faceAuthStatusions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="住房状况">
            <el-select
              v-model="form.houseStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in houseStatus"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否上传头像">
            <el-select
              v-model="form.isAvatar"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in isAvatarions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否VIP会员">
            <el-select
              v-model="form.isVipMember"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in isVipMemberions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会员注册来源">
            <el-input
              v-model="form.remark"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="婚姻状况">
            <el-select
              v-model="form.maritalStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in marriages"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="何时结婚">
            <el-select
              v-model="form.marriedTime"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in marriedTimes"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="月收入">
            <el-select
              v-model="form.monthIncome"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in monthIncomes"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="民族">
            <el-input
              v-model="form.nation"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="籍贯(区)">
            <el-input v-model="native" disabled style="width: 200px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="籍贯(省)">
            <el-input
              v-model="form.nativePlaceProvinceName"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="籍贯(市)">
            <el-input
              v-model="form.nativePlaceCityName"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="籍贯(区)">
            <el-input
              v-model="form.nativePlaceDistrictName"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item> -->

          <el-form-item label="职业类别-大">
            <el-input
              v-model="form.occBigCategory"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="职业类别-小">
            <el-input
              v-model="form.occSmallCategory"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码">
            <el-input
              v-model="form.phonenumber"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="实名认证">
            <el-select
              v-model="form.realAuthStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in realAuthStatusions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="网证认证">
            <el-select
              v-model="form.networkAuthStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in networkAuthStatusions"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="体型">
            <el-select
              v-model="form.shape"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in shapes"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否想要孩子">
            <el-select
              v-model="form.wantChildren"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in wantChildrens"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="体重">
            <el-input
              v-model="form.weight"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作地区">
            <el-input
              v-model="workAddr"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="内心独白">
            <!-- <el-input  v-model="form.introduce" disabled >
            </el-input> -->
            <!-- <el-input
              type="textarea"
              disabled
              v-model="form.introduce"
            ></el-input> -->

            <el-input
              style="width: 500px"
              type="textarea"
              disabled
              autosize
              v-model="form.introduce"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-form label-width="100px">
          <el-form-item label="会员相册" v-if="!iSalbum">
            <div class="form-img">
              <div
                style="position: relative"
                v-for="item in album"
                :key="item.id"
              >
                <el-image
                  :preview-src-list="albumL"
                  style="width: 100px; height: 100px"
                  :src="item.Img"
                >
                </el-image>
                <i @click="delImg(item)">X</i>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="会员相册" v-if="iSalbum" class="form-img_o">
            <span>该用户的相册为空</span>
          </el-form-item>
        </el-form>
      </div>
      <div class="container-zeou">
        <p>择偶条件</p>
        <el-form :model="mateSelection" class="ze">
          <el-form-item label="年龄" class="zeou">
            <el-input
              v-model="mateSelection.ageMin"
              disabled
              style="width: 100px"
            ></el-input>
            <!-- <p>-</p> -->
            <el-input
              v-model="mateSelection.ageMax"
              disabled
              style="width: 100px; margin-left: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item label="身高" class="zeou">
            <el-input
              v-model="mateSelection.heightMin"
              disabled
              style="width: 100px"
            ></el-input>
            <!-- <p>-</p> -->
            <el-input
              v-model="mateSelection.heightMax"
              disabled
              style="width: 100px; margin-left: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item label="月收入" class="zeou">
            <el-input
              v-model="mateSelection.monthIncomeMin"
              disabled
              style="width: 100px"
            ></el-input>
            <!-- <p>-</p> -->
            <el-input
              v-model="mateSelection.monthIncomeMax"
              disabled
              style="width: 100px; margin-left: 10px"
            ></el-input>
          </el-form-item>

          <el-form-item label="学历" class="zeou">
            <el-select
              v-model="mateSelection.education"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in educations"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="婚姻状况" class="zeou">
            <el-select
              v-model="mateSelection.maritalStatus"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in marriages"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="体型" class="zeou">
            <el-select
              v-model="mateSelection.shape"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in shapes"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="工作地区" class="zeou">
            <el-input
              v-model="mateSelection.workAddr"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否有孩子" class="zeou">
            <el-select
              v-model="mateSelection.existsChildren"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in existsChildrens"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否想要孩子" class="zeou">
            <el-select
              v-model="mateSelection.wantChildren"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in wantChildrens"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否吸烟" class="zeou">
            <el-select
              v-model="mateSelection.doSmake"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in doSmakess"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否喝酒" class="zeou">
            <el-select
              v-model="mateSelection.doDrink"
              disabled
              placeholder=""
              style="width: 200px"
            >
              <el-option
                v-for="item in doDrinkss"
                :key="item.value"
                :label="item.value"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="container-xq">
        <p>兴趣爱好</p>

        <el-form
          ref="form"
          :model="form"
          class="container-xq_a"
          label-width="150px"
        >
          <el-form-item label="喜欢的一道菜：">
            <el-input
              v-model="form.favoriteDishes"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="欣赏的一个名人：">
            <el-input
              v-model="form.favoriteCelebrities"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="喜欢的一首歌：">
            <el-input
              v-model="form.favoriteSongs"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="喜欢的一本书：">
            <el-input
              v-model="form.favoriteBooks"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="喜欢做的事：">
            <el-input
              v-model="form.likeDoing"
              disabled
              style="width: 200px"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="container-table">
        <p>会员相互喜欢</p>
        <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
        >
          <el-table-column prop="UserId" label="互相ID"></el-table-column>
          <el-table-column prop="nickName" label="互相昵称"></el-table-column>
          <el-table-column label="互相头像" width="130px">
            <template slot-scope="scope">
              <el-image
                style="width: 100px; height: 100px"
                fit="cover"
                :src="scope.row.Avatar"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="container-table">
        <p>会员屏蔽列表</p>
        <div class="shield">
          <el-table
            :data="shieldData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              prop="shieldMemberId"
              label="屏蔽人ID"
            ></el-table-column>
            <el-table-column
              prop="shieldMemberName"
              label="屏蔽人昵称"
            ></el-table-column>
            <el-table-column
              prop="updateTime"
              label="操作时间"
            ></el-table-column>
          </el-table>

          <el-table
            :data="shieldedData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              prop="shieldMemberId"
              label="被屏蔽人ID"
            ></el-table-column>
            <el-table-column
              prop="shieldMemberName"
              label="被屏蔽人昵称"
            ></el-table-column>
            <el-table-column
              prop="updateTime"
              label="操作时间"
            ></el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 动态列表 -->
      <div class="container-table">
        <p>会员动态列表</p>
        <div class="shield">
          <el-table
            :data="DynamicPage"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              prop="pubText"
              label="文本内容"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="pubAddr"
              label="发布地点"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="likeNum"
              label="创建时间"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">
                  {{
                    $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300px" align="center">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-view"
                  @click="handleDynamicLook(scope.row)"
                  size="mini"
                  type="warning"
                  >查看</el-button
                >
                <el-button
                  @click="handleDynamicDel(scope.row)"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" v-if="DynamicPageTotal">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="queryDynamic.pageNum"
            :page-size="queryDynamic.pageSize"
            :total="DynamicPageTotal"
            @current-change="handlePageChangeDynamic"
          ></el-pagination>
        </div>
      </div>

      <!-- 视频列表 -->
      <div class="container-table">
        <p>会员视频列表</p>
        <div class="shield">
          <el-table
            :data="VideoPage"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              prop="pubContent"
              label="文本内容"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="pubAddr"
              label="发布地点"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="likeNum"
              label="创建时间"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">
                  {{
                    $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300px" align="center">
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-view"
                  @click="handleVideoLook(scope.row)"
                  size="mini"
                  type="warning"
                  >查看</el-button
                >
                <el-button
                  @click="handleVideoDel(scope.row)"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" v-if="VideoPageTotal">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="queryVideo.pageNum"
            :page-size="queryVideo.pageSize"
            :total="VideoPageTotal"
            @current-change="handlePageChangeVideo"
          ></el-pagination>
        </div>
      </div>

      <!-- 个人观点 -->
      <div class="container-table">
        <p>会员观点</p>
        <div class="shield">
          <el-table
            :data="tableDataPointList"
            border
            class="table"
            ref="multipleTable"
          >
            <!-- <el-table-column prop="id" label="#"></el-table-column> -->
            <!-- <el-table-column prop="memberId" label="会员ID"></el-table-column>  -->
            <el-table-column prop="title" label="问题"></el-table-column>
            <el-table-column prop="content" label="观点"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                <p v-if="scope.row.updateTime">
                  {{
                    $moment(scope.row.updateTime).format("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 视频 -->
    <el-dialog
      title="查看视频"
      :visible.sync="videoEditVisible"
      @close="onLookClose"
    >
      <el-form :model="formVideo" label-width="70px" class="editArticleRules">
        <el-form-item label="内容" v-if="formVideo.pubContent">
          <p>{{ formVideo.pubContent }}</p>
        </el-form-item>
        <el-form-item label="视频">
          <video
            v-if="videoEditVisible"
            :src="url + formVideo.pubVideoUrl"
            class="video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 动态 -->
    <el-dialog
      title="查看动态"
      :visible.sync="DynamicEditVisible"
      @close="onDynamicLookClose"
    >
      <el-form :model="formDynamic" label-width="70px" class="editArticleRules">
        <el-form-item label="内容" v-if="formDynamic.pubText">
          <p>{{ formDynamic.pubText }}</p>
        </el-form-item>
        <el-form-item label="视频" v-if="formDynamic.pubType == 1">
          <video
            v-if="DynamicEditVisible"
            :src="url + formDynamic.pubImg"
            class="video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
        </el-form-item>
        <!--width: 100px;  -->
        <el-form-item
          label="图片"
          v-if="formDynamic.pubType != 1 && formDynamic.pubImg"
        >
          <el-image
            v-for="item in srcList"
            style="height: 100px; margin-right: 10px"
            :src="item"
            :preview-src-list="srcList"
            :key="item"
          >
          </el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  albumList,
  memberLoveList,
  memberShieldList,
  query,
  memberVideoPage,
  memberVideoDel, //删除视频
  memberDynamicPage,
  memberDynamicDel, //删除动态
  memberPointList,
  albumDel, //图片删除
} from "@/api/member/member.js";
import { ossL } from "@/api/common/oss/ossL.js";
import env from "@/api/env.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  data() {
    return {
      /**
       * 测试
       */
      url: env.ossUrl,
      form: {},
      mateSelection: {},
      loading: false,
      album: [],
      albumL: [],
      UserAlbumL: [],
      iSalbum: false,
      tableData: [],
      shieldData: [],
      shieldedData: [],
      tableDataPointList: [], //观点
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      constellations: [
        {
          value: "牡羊座(03.21-04.20)",
          label: "0",
        },
        {
          value: "金牛座(04.21-05.20)",
          label: "1",
        },
        {
          value: "双子座(05.21-06.21)",
          label: "2",
        },
        {
          value: "巨蟹座(06.22-07.22)",
          label: "3",
        },
        {
          value: "狮子座(07.23-08.22)",
          label: "4",
        },
        {
          value: "处女座(08.23-09.22)",
          label: "5",
        },
        {
          value: "天秤座(09.23-10.22)",
          label: "6",
        },
        {
          value: "天蝎座(10.23-11.21)",
          label: "7",
        },
        {
          value: "射手座(11.22-12.21)",
          label: "8",
        },
        {
          value: "摩羯座(12.22-01.19)",
          label: "9",
        },
        {
          value: "水瓶座(01.20-02.19)",
          label: "10",
        },
        {
          value: "双鱼座(02.20-03.20)",
          label: "11",
        },
      ], //星座
      deSingleStatusions: [
        {
          value: "否",
          label: "0",
        },
        {
          value: "是",
          label: "1",
        },
      ],
      faceAuthStatusions: [
        {
          value: "未认证",
          label: "0",
        },
        {
          value: "认证通过",
          label: "1",
        },
      ],
      isAvatarions: [
        {
          value: "否",
          label: "0",
        },
        {
          value: "是",
          label: "1",
        },
      ],
      sexIons: [
        {
          value: "男",
          label: "0",
        },
        {
          value: "女",
          label: "1",
        },
      ],
      networkAuthStatusions: [
        {
          value: "未认证",
          label: "0",
        },
        {
          value: "认证通过",
          label: "1",
        },
      ],
      realAuthStatusions: [
        {
          value: "未认证",
          label: "0",
        },
        {
          value: "认证通过",
          label: "1",
        },
      ],
      isVipMemberions: [
        {
          value: "否",
          label: "0",
        },
        {
          value: "是",
          label: "1",
        },
      ],
      shapes: [
        {
          value: "保密",
          label: "0",
        },
        {
          value: "一般",
          label: "1",
        },
        {
          value: "瘦长",
          label: "2",
        },
        {
          value: "运动员型",
          label: "3",
        },
        {
          value: "比较胖",
          label: "4",
        },
        {
          value: "体格魁梧",
          label: "5",
        },
        {
          value: "壮实",
          label: "6",
        },
        {
          value: "苗条",
          label: "7",
        },
        {
          value: "高大美丽",
          label: "8",
        },
        {
          value: "丰满",
          label: "9",
        },
        {
          value: "富线条美",
          label: "10",
        },
      ], //体型
      existsChildrens: [
        {
          value: "没有孩子",
          label: "0",
        },
        {
          value: "有孩子且住在一起",
          label: "1",
        },
        {
          value: "有孩子且偶尔会在一起住",
          label: "2",
        },
        {
          value: "有孩子但不在身边",
          label: "3",
        },
      ], //有无孩子
      wantChildrens: [
        {
          value: "视情况而定",
          label: "0",
        },
        {
          value: "想要孩子",
          label: "1",
        },
        {
          value: "不想要孩子",
          label: "2",
        },
        {
          value: "以后再告诉你",
          label: "4",
        },
      ], //是否想要孩子
      //是否吸烟
      doSmakess: [
        {
          value: "不要吸烟",
          label: "0",
        },
        {
          value: "可以吸烟",
          label: "1",
        },
      ],
      doSmakes: [
        {
          value: "不吸烟",
          label: "0",
        },
        {
          value: "稍微抽一点烟",
          label: "1",
        },
        {
          value: "烟抽得很多",
          label: "2",
        },
        {
          value: "社交场合会抽烟",
          label: "3",
        },
      ],
      doDrinks: [
        {
          value: "不喝酒",
          label: "0",
        },
        {
          value: "稍微喝一点酒",
          label: "1",
        },
        {
          value: "酒喝得很多",
          label: "2",
        },
        {
          value: "社交场合会喝酒",
          label: "3",
        },
      ],
      //是否喝酒
      doDrinkss: [
        {
          value: "不要喝酒",
          label: "0",
        },
        {
          value: "可以喝酒",
          label: "1",
        },
      ],
      //有无照片
      avaters: [
        {
          value: "有",
          label: "1",
        },
      ],
      marriedTimes: [
        {
          value: "认同闪婚",
          label: 0,
        },
        {
          value: "一年内",
          label: 1,
        },
        {
          value: "两年内",
          label: 2,
        },
        {
          value: "三年内",
          label: 3,
        },
        {
          value: "时机成熟就结婚",
          label: 4,
        },
      ], //何时结婚
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ], //买车
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      marriages: [
        {
          value: "未婚",
          label: "0",
        },
        {
          value: "离异",
          label: "1",
        },
        {
          value: "丧偶",
          label: "2",
        },
      ], //婚姻
      monthIncomes: [
        {
          value: "3000元以下",
          label: "0",
        },
        {
          value: "3001-5000元",
          label: "1",
        },
        {
          value: "5001-8000元",
          label: "2",
        },
        {
          value: "8001-12000元",
          label: "3",
        },
        {
          value: "12001-20000元",
          label: "4",
        },
        {
          value: "20000-50000元",
          label: "5",
        },
        {
          value: "50000元以上",
          label: "6",
        },
      ],
      native: "",
      workAddr: "",
      // pageNum: 1,
      /**
       * 视频列表
       */

      VideoPage: [],
      VideoPageTotal: 0,
      queryVideo: {
        pageNum: 1,
        pageSize: 10,
      },
      videoEditVisible: false,
      formVideo: {},

      /**
       * 动态
       */
      DynamicPage: [],
      DynamicPageTotal: 0,
      queryDynamic: {
        pageNum: 1,
        pageSize: 10,
      },
      DynamicEditVisible: false,
      formDynamic: {},
      srcList: [],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.albumLi();
      this.LoveList();
      this.ShieldList();
      this.UserInfo();
      this.memberVideo();
      this.memberDynamic();
      this.getData();
    } else {
      this.$router.go(-1); //返回上一层
    }
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async getData() {
      const { code, data } = await memberPointList({
        memberId: this.$route.query.id,
      });
      if (code == 0) {
        this.tableDataPointList = data;
      }
    },
    //获取用户相册
    async albumLi() {
      try {
        this.loading = true;

        const { code, data } = await albumList({
          memberId: this.$route.query.id,
        });
        if (code == 0) {
          console.log(data);

          this.loading = false;
          if (data.length == 0) {
            this.iSalbum = true;
          } else {
            data.map((item) => {
              this.album.push(
                Object.assign({}, item, {
                  Img: ossL(item.imgUrl),
                })
              );
              this.albumL.push(ossL(item.imgUrl));
            });
          }
        }
      } finally {
        this.loading = false;
      }
    },
    //删除
    delImg(item) {
      console.log("当前选中图片", item);
      if (item.imgFlag == 1) {
        this.$message.error("头像不可删");
      } else {
        this.$confirm("此操作将永久删除该图片, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(async () => {
            this.tkL();
            const { code } = await albumDel({
              id: item.id,
            });
            if (code == 0) {
              this.tkL();
              this.$message({
                message: "删除相册成功",
                type: "success",
                offset: 100,
              });
              // setTimeout(function () {
              //   window.location.reload(); //02
              // }, 1000);
              this.albumLi();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 获取用户详情
    async UserInfo() {
      const { code, data } = await query(this.$route.query.id);
      if (code == 0) {
        console.log(data);
        this.form = data.user;
        this.mateSelection = data.user.mateSelection;
        for (let i in this.mateSelection) {
          if (this.mateSelection[i] == null) {
            this.mateSelection[i] = "不限";
          }
        }
        if (this.mateSelection.workAddrProvinceName == "不限") {
          this.mateSelection.workAddr = "不限";
        } else if (this.mateSelection.workAddrCityName == "不限") {
          this.mateSelection.workAddr = `${this.mateSelection.workAddrProvinceName}/不限`;
        } else {
          this.mateSelection.workAddr = `${this.mateSelection.workAddrProvinceName}/${this.mateSelection.workAddrCityName}/${this.mateSelection.workAddrDistrictName}`;
        }

        if (this.form.avatar) {
          this.form.htImg = ossL(this.form.avatar);
          this.UserAlbumL.push(ossL(this.form.avatar));
        }
        if (this.form.nativePlaceProvinceName) {
          this.native = `${this.form.nativePlaceProvinceName}/${this.form.nativePlaceCityName}/${this.form.nativePlaceDistrictName}`;
        }
        if (this.form.workAddrProvinceName) {
          this.workAddr = `${this.form.workAddrProvinceName}/${this.form.workAddrCityName}/${this.form.workAddrDistrictName}`;
        }
      }
    },
    async ShieldList() {
      this.shieldedList();
      this.shieldList();
    },
    /**
     * 视频
     */
    async memberVideo() {
      const { code, data } = await memberVideoPage({
        memberId: this.$route.query.id,
        pageNum: this.queryVideo.pageNum,
        pageSize: this.queryVideo.pageSize,
      });
      if (code == 0) {
        console.log("__", data);
        this.VideoPage = data.records;
        this.VideoPageTotal = data.total;
      }
    },
    handlePageChangeVideo(val) {
      console.log(val);
      this.queryVideo.pageNum = val;
      this.memberVideo();
    },
    //查看视频
    handleVideoLook(row) {
      this.formVideo = row;
      this.videoEditVisible = true;
    },
    //删除视频
    handleVideoDel(row) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, msg } = await memberVideoDel({
            id: row.id,
          });
          if (code == 0) {
            this.tkL();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.memberVideo();
          } else {
            this.tkL();
            this.$message.error(msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onLookClose() {
      this.videoEditVisible = false;
      // this.formVideo = {};
    },

    /**
     * 动态
     */
    async memberDynamic() {
      const { code, data } = await memberDynamicPage({
        memberId: this.$route.query.id,
        pageNum: this.queryDynamic.pageNum,
        pageSize: this.queryDynamic.pageSize,
      });
      if (code == 0) {
        console.log(data);
        this.DynamicPage = data.records;
        this.DynamicPageTotal = data.total;
      }
    },
    handlePageChangeDynamic(val) {
      console.log(val);
      this.queryDynamic.pageNum = val;
      this.memberDynamic();
    },
    //查看动态
    handleDynamicLook(row) {
      this.formDynamic = row;
      this.DynamicEditVisible = true;
      if (this.formDynamic.pubType != 1 && this.formDynamic.pubImg) {
        let arr = this.formDynamic.pubImg.split(",");

        for (let i = 0; i < arr.length; i++) {
          arr[i] = this.url + arr[i];
        }
        this.srcList = arr;
      }
    },
    //删除该动态
    handleDynamicDel(row) {
      //   this.tkL();
      this.$confirm("此操作将永久删除该动态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, msg } = await memberDynamicDel({
            id: row.id,
          });
          if (code == 0) {
            this.tkL();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.memberDynamic();
          } else {
            this.tkL();
            this.$message.error(msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onDynamicLookClose() {
      this.DynamicEditVisible = false;
      // this.formDynamic = {};
      this.srcList = [];
    },

    async shieldList() {
      const { code, data } = await memberShieldList({
        memberId: this.$route.query.id,
      });
      if (code == 0) {
        this.shieldData = data;
      }
    },
    async shieldedList() {
      const { code, data } = await memberShieldList({
        shieldMemberId: this.$route.query.id,
      });
      if (code == 0) {
        this.shieldedData = data;
      }
    },
    async LoveList() {
      const { code, data } = await memberLoveList({
        memberId: this.$route.query.id,
        num: 100,
      });
      if (code == 0) {
        if (data) {
          data.map((item) => {
            if (this.$route.query.id == item.oneMemberId) {
              this.tableData.push(
                Object.assign({}, item, {
                  Avatar: ossL(item.twoAvatar),
                  nickName: item.twoNickName,
                  UserId: item.twoMemberId,
                })
              );
            } else {
              this.tableData.push(
                Object.assign({}, item, {
                  Avatar: ossL(item.oneAvatar),
                  nickName: item.oneNickName,
                  UserId: item.oneMemberId,
                })
              );
            }
          });
        }
      }
    },
    goBack() {
      this.$router.go(-1); //返回上一层
    },
  },
  // beforeRouteLeave(to,form,next){
  //   to.meta.keepAlive = true;
  //   next()
  // }
};
</script>

<style lang="less" scoped>
.container {
  margin-top: 20px;
  // padding-top: 20px;
  // padding:20px 0px;

  .container-et {
    display: flex;
    flex-wrap: wrap;
    p {
      padding: 10px;
      color: #606266;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      color: #fd686e;
    }
    // .container-img {
    //   height: 300px;
    // }
    .container-et-info {
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      // flex: 1;
      // position: relative;
      // .el-form-item:nth-child(1) {
      //   // width: 100%;
      //   height: 300px;
      // }
    }
  }
  .container-zeou {
    // margin-left: 30px;
    p {
      padding: 10px;
      color: #606266;
      font-weight: bold;
      color: #fd686e;
      font-size: 14px;
    }
    .ze {
      display: flex;
      flex-wrap: wrap;
      .zeou {
        display: flex;
        align-items: center;
        width: 300px;
        justify-content: space-around;
        // margin-left: 10px;
        p {
          padding: 0px;
        }
        /deep/.el-form-item__content {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .container-xq {
    // display: flex;
    p {
      padding: 10px;
      color: #606266;
      font-size: 14px;
      font-weight: bold;
      color: #fd686e;
    }
    .container-xq_a {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .container-table {
    // padding: 20px;
    p {
      padding: 10px;
      color: #606266;
      font-size: 14px;
      color: #fd686e;
      font-weight: bold;
    }
  }
}

.form-img {
  display: flex;
  // flex-wrap: nowrap;
  flex-wrap: wrap;

  .el-image {
    margin-right: 20px;
    margin-bottom: 10px;
    background-color: cadetblue;
  }
  div {
    i {
      cursor: pointer;

      position: absolute;
      right: 10px;
      top: -10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #ececec;
    }
  }
}
.form-img_o {
  box-sizing: border-box;
  span {
    color: red;
  }
}
.table {
  width: 700px;
}
.shield {
  display: flex;
  flex-wrap: wrap;
  .table {
    margin-left: 10px;
  }
}

/**视频查看框 */
.editArticleRules {
  height: 500px;
  padding-right: 10px;
  overflow: auto;
}
// .video-avatar {
//   width: 500px;
//   height: 300px;
// }
.video-avatar {
  // width: 500px;
  height: 400px;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  // width:12px;
  width: 8px;
  // background-color: aqua;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ececec;
}
</style>