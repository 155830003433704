<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.disposeStatus"
          placeholder="待处理"
          class="handle-select mr10"
          clearable
        >
          <el-option key="0" label="待处理" value="0"></el-option>
          <el-option key="1" label="处理中" value="1"></el-option>
          <el-option key="2" label="处理完成" value="2"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="informedId" label="被举报id"></el-table-column>
        <el-table-column prop="realName" label="姓名"></el-table-column>
        <el-table-column prop="cellphone" label="手机号"></el-table-column>
        <el-table-column prop="createId" label="举报人id"></el-table-column>
        <el-table-column prop="informReason" label="举报原因"></el-table-column>
        <!-- 认0-待处理、1-处理中、2-处理完成) -->
        <el-table-column label="举报处理状态">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.disposeStatus == '0'">待处理</span>
              <span v-if="scope.row.disposeStatus == '1'">处理中</span>
              <span v-if="scope.row.disposeStatus == '2'">处理完成</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="举报时间">
          <template slot-scope="scope">
            <p>
              {{ $moment(scope.row.createTime).format("lll") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="show">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog title="审核" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="处理意见">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDetails()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
import { report } from "@/api/member/member.js";
import { reportAudit } from "@/api/member/member.js";
export default {
  data() {
    return {
      query: {
        disposeStatus: "",
        pageNum: 1,
        pageSize: 10,
      },
      memberId: "",
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {
        remark: "",
      },
      idx: -1,
      id: -1,
      show: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    async getData() {
      let a = {
        disposeStatus: this.query.disposeStatus,
        pageNum: this.query.pageNum,
        pageSize: 10,
      };
      const { code, data } = await report(a);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
        if (this.query.disposeStatus == 0 || this.query.disposeStatus == 1) {
          this.show = true;
        } else if (this.query.disposeStatus == 2) {
          this.show = false;
          console.log("不显示处理");
        }
        console.log(this.tableData, "商品列表");
      }
    },
    async subTk() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    // 编辑操作弹出审核框
    handleEdit(index, row) {
      this.memberId = row.id;
      console.log(index, row.id, this.memberId, "点击当前按钮");
      this.editVisible = true;
    },
    async handleDetails() {
      console.log("提交审核");
      this.subTk();
      console.log("888", "7777777777777");
      let a = {
        id: this.memberId,
        disposeStatus: 2,
        remark: this.form.remark,
      };
      const { code } = await reportAudit(a);
      if (code == 0) {
        // console.log(data, "提交申请");
        this.editVisible = false;
        this.getData();
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageNum", 1);
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      console.log("ss", row);
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },

    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      // this.$set(this.query, "pageNum", val);
      this.query.pageNum = val
      this.getData();
    },
  },
};
</script>

<style scoped>
/* .cancel {
  margin-right: 85px;
} */
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
