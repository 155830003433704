import Vue from 'vue';
import Router from 'vue-router';
import Store from '../store/index.js'
Vue.use(Router);

// import Router from 'vue-router'原因：vue-router路由版本更新产生的问题,导致路由跳转失败抛出该错误，但并不影响程序功能
// 全局解决：替换路由的Push和replace方法，放在src/router/index.js中：
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/dashboard',
        component: () => import('../components/common/Home.vue'),
        meta: { title: '自述文件' },
        children: [
            {
                path: '/dashboard',
                component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
                meta: { title: '系统首页' }
            },
            {
                path: '/also',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/also/index.vue'),
                meta: { title: '也在币使用记录' }
            },
            {
                path: '/good',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/commodity/good.vue'),
                meta: { title: '商品信息' }
            }, {
                path: '/goodCategory',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/commodity/goodCategory.vue'),
                meta: { title: '商品分类' }
            },
            {
                path: '/order',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/commodity/order.vue'),
                meta: { title: '订单信息' }
            },
            {
                path: '/vipServiceRef',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/commodity/vipServiceRef.vue'),
                meta: { title: '开通信息' }
            },
            {
                path: '/404',
                component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
                meta: { title: '404' }
            },
            {
                path: '/403',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
                meta: { title: '403' }
            },
            {
                path: '/ArticleQuery',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/article/articleL.vue'),
                meta: { title: '文章查询' }
            },
            {
                path: '/ArticleClassification',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/article/ArticleClassification.vue'),
                meta: { title: '文章分类' }
            },
            {
                path: '/guAccount',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/guAccount.vue'),
                meta: { title: '系统用户' }
            },

            {
                path: '/puAccount',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/puAccount.vue'),
                meta: { title: '会员用户' }
            },//puAccountDetails.vue

            /**
             * 会员信息 Member information
             */
            {
                path: '/MemberInformation',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/MemberInformation.vue'),
                meta: { title: '会员信息' }
            },
            {
                path: '/puAccountDetails',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/puAccountDetails.vue'),
                meta: { title: '用户详情' }
            },
            {
                path: '/role',//power
                component: () => import(/* webpackChunkName: "403" */ '../components/page/system/Role.vue'),
                meta: { title: '角色管理' }
            },
            {
                path: '/power',//power
                component: () => import(/* webpackChunkName: "403" */ '../components/page/system/power.vue'),
                meta: { title: '权限管理' }
            },
            {
                path: '/DigitalDictionary',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/system/DigitalDictionary.vue'),
                meta: { title: '字典管理' }
            },
            //门店详情
            {
                path: '/storePage',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/store/storeMange.vue'),
                meta: { title: '门店管理' }
            },
            {
                path: '/storeDetails',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/store/storeDetails.vue'),
                meta: { title: '门店详情' }
            },
            {
                path: '/org',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/system/org.vue'),
                meta: { title: '机构管理' }
            },

            //用户管理
            {
                path: '/memberLikePage',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/memberLikePage.vue'),
                meta: { title: '会员点赞' }
            },
            {
                path: '/memberPointList',
                component: () => import(/* webpackChunkName: "403" */ '../components/page/account/memberPointList.vue'),
                meta: { title: '会员观点' }
            },
            {
                path: '/memberRecommendList',
                component: () => import('../components/page/account/memberRecommendList.vue'),
                meta: { title: '会员推荐' }
            },
            {
                path: '/report',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/report/index.vue'),
                meta: { title: '会员举报' }
            },
            {
                path: '/memberServiceAppPage',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/account/memberServiceAppPage.vue'),
                meta: { title: '会员服务申请' }
            },
            {
                path: '/verify',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/verify/verify.vue'),
                meta: { title: '会员信息审核' }
            },
            {
                path: '/message',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/message/message.vue'),
                meta: { title: '会员消息' }
            },
            {
                path: '/memberFollowPage',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/account/memberFollowPage.vue'),
                meta: { title: '会员关注' }
            },
            {
                path: '/verifyDetails',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/verify/details.vue'),
                meta: { title: '审核详情' }
            },
            {
                path: '/feeling',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/feeling/feeling.vue'),
                meta: { title: '问题管理' }
            },
            {
                path: '/stdList',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/feeling/stdList.vue'),
                meta: { title: '情感分析' }
            },
            {
                path: '/topic',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/feeling/topic.vue'),
                meta: { title: '话题' }
            },
            {
                path: '/videoMange',
                component: () => import(/* webpackChunkName: "form" */'../components/page/system/videoMange.vue'),
                meta: { title: '视频管理' }
            },
            {
                path: '/dynamic',
                component: () => import(/* webpackChunkName: "form" */'../components/page/system/dynamic.vue'),
                meta: { title: '动态管理' }
            },

            {
                path: '/personal',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/system/personal.vue'),
                meta: { title: '个人中心' }
            },
            {
                path: '/feedback',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/feedback/feedback.vue'),
                meta: { title: '意见反馈' }
            }, {
                path: '/sysConfigPage',
                component: () => import(/* webpackChunkName: "form" */ '../components/page/system/sysConfigPage.vue'),
                meta: { title: '参数配置' }
            },

            //-----------测试--------------
            // {
            //     path: '/ceshi',
            //     component: () => import(/* webpackChunkName: "form" */ '../components/page/ceshi.vue'),
            // },
        ]
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
        meta: { title: '登录' }
    },
    {
        path: '/MemberInformationIndex',
        component: () => import('../components/page/M.vue'),
        meta: { title: '会员' }
    },
    {
        path: '*',
        redirect: '/404'
    },
]


const router = new Router({
    mode: 'history',
    // mode: 'hash',
    base: process.env.BASE_URL,
    // base: '/',
    // base: '/dist',
    routes
})

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    console.log("_",to.path,from)
    //标题头
    if (to.meta.title) {
        document.title = `${to.meta.title}`; // | vue-manage-system
    }
    //令牌
    const role = localStorage.getItem('access_token');
    if (role) {
        Store.dispatch('GET_USER_PERMISSION');
        next();
        return
    }
    if (!role && to.path !== '/login') {
        // next();
        //单独处理(/MemberInformationIndex)页面
        //MemberInformationIndex?id=6267782099
        if (!to.path.includes('/MemberInformationIndex')) {
            next('/login')
            // console.log("_+_")
        } else {
            // console.log("_1login_")
            next()
        }
    } else if (!role && to.path === '/login') {
        // console.log("_2login_")
        next();
    } else if (to.meta.permission) {
        // console.log("_3login_")
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin' ? next() : next('/403');
    } else {
        // console.log("_4login_")
        // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
        if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/addArticle') {
            Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
                confirmButtonText: '确定'
            });
        } else {
            next();
        }
    }
});



export default router
