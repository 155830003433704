<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.memberId"
          placeholder="会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table :data="tableData" border class="table" ref="multipleTable">
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.sex == '0'">男</p>
            <p v-if="scope.row.sex == '1'">女</p>
          </template>
        </el-table-column>
        <el-table-column prop="pubContent" label="文本内容" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="pubAddr" label="发布地点"></el-table-column>
        <el-table-column prop="workAddrCityName" label="城市"></el-table-column>
        <el-table-column label="创建时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <p v-if="scope.row.createTime">
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-view"
              @click="handleDynamicLook(scope.row)"
              size="mini"
              type="warning"
              >查看</el-button
            >
            <el-button
              @click="handleDynamicDel(scope.row)"
              size="mini"
              type="danger"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 视频 -->
    <el-dialog
      title="查看视频"
      :visible.sync="videoEditVisible"
      @close="onLookClose"
    >
      <el-form :model="formVideo" label-width="70px" class="editArticleRules">
        <el-form-item label="会员ID">
          <p>{{ formVideo.memberId }}</p>
        </el-form-item>
        <el-form-item label="视频内容" v-if="formVideo.pubContent">
          <p>{{ formVideo.pubContent }}</p>
        </el-form-item>
        <el-form-item label="视频" v-if="formVideo">
          <video
            :src="url + formVideo.pubVideoUrl"
            class="video-avatar"
            controls="controls"
          >
            您的浏览器不支持视频播放
          </video>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { memberVideoPage, memberVideoDel } from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";

import env from "@/api/env.js";
export default {
  name: "videoMange",
  data() {
    return {
      query: {
        memberId: "",
        pageNum: 1,
        pageSize: 10,
      },
      formVideo: {},
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
      /**
       * 测试
       */
      url:env.ossUrl,
      tableData: [],
      videoEditVisible: false,
      pageTotal: 0,
    };
  },
  created() {},
  methods: {
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async getData() {
      const { code, data } = await memberVideoPage({
        memberId: this.query.memberId,
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
    handlePageChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
    handleDynamicLook(row) {
      this.formVideo = row;
      this.videoEditVisible = true;
    },
    onLookClose() {
      this.videoEditVisible = false;
    },
    handleDynamicDel(row) {
      this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, msg } = await memberVideoDel({
            id: row.id,
          });
          if (code == 0) {
            this.tkL();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData();
          } else {
            this.tkL();
            this.$message.error(msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

/**视频查看框 */
.editArticleRules {
  height: 500px;
  padding-right: 10px;
  overflow: auto;
}

.video-avatar {
  // width: 500px;
  height: 400px;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  // width:12px;
  width: 8px;
  // background-color: aqua;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ececec;
}
</style>
