import request from "@/utils/request.js";

//获取临牌
export const subTk = ((data) => {
    return request.post('/system-server/sys/sub/tk', data)
})
//当前系统用户信息
export const userInfo = ((data) => {
    return request.get('/system-server/sys/user/info', data)
})
// 意见反馈 
export const sysFbPage = ((data) => {
    return request.post('/system-server/sys/fb/page', data)
})
//变更意见反馈 
export const sysFbUpdate = ((data) => {
    return request.post('/system-server/sys/fb/update', data)
})

//菜单权限树
export const sysMenuTree = ((data) => {
    return request.gettLL('/system-server/sys/menu/tree', data)
})