// permission.js
import Store from '../store/index.js'

// const checkPermission = (productProviderName, productName, action) => {
//     //   const permissionObj = Store.getters.permissions
//     const permissionObj = ['aliyun', 'ecs', 'PURCHASE']
//     let a = [{
//         '00': '1',
//     }, '2', '3']
//     console.log(a['00'], permissionObj, productProviderName, permissionObj[productProviderName]);
//     //   console.log('===',permissionObj[productProviderName]);
//     if (!permissionObj[productProviderName]) return false

//     const permissions = permissionObj[productProviderName][productName]
//     if (!permissions) return false

//     return permissions.indexOf(action) > -1
// }
const checkPermission = (productProviderName) => {

    const permissionObj = Store.getters.permissions
    // console.log('___', permissionObj);
    if (productProviderName == "dashboard") {
        return true
    } else if (permissionObj.indexOf(productProviderName) == -1) {
        return false
    } else {
        return true
    }
}
function fn(el, binding) {
    let permission = binding.value // 获取到 v-permission 所绑定的值
    // console.log('__', binding.value);
    if (typeof permission === 'string') {
        if (permission) {
            // const arr = permission.split('.')
            // console.log('__', arr);
            // if (arr.length === 3) {
            //     let hasPermission = checkPermission(arr[0], arr[1], arr[2])
            //     //  hasPermission = false
            //     console.log('__', hasPermission);
            //     if (!hasPermission) {
            //         // 没有权限则移除 dom 元素
            //         el.parentNode && el.parentNode.removeChild(el)
            //     } else {
            //         // 有权限则追加之前缓存的 dom 元素
            //         el.cacheParentElement && el.cacheParentElement.appendChild(el.cacheElement)
            //     }
            // } else {
            //     console.error(`The value bound to v-permission should be like v-permission="'aliyun.ecs.PURCHASE'".`)
            // }
            // if (permission == "dashboard") {
            //     el.cacheParentElement && el.cacheParentElement.appendChild(el.cacheElement)
            // }
            let hasPermission = checkPermission(permission)
            if (!hasPermission) {

                el.parentNode && el.parentNode.removeChild(el)
                // el.classList.add('disabled'); // 添加禁止样式
                // console.log(el);
                // el.addEventListener('click', (event) => {
                //     if (event) {
                //         event && event.preventDefault(); // 阻止事件行为
                //         event && event.stopPropagation(); // 阻止事件冒泡
                //     } else if (window.event) {
                //         window.event.cancelBubble = true;  // ie
                //     }
                // }, true);
                //-------
                // el.classList.add('btn-disabled'); // 添加禁止样式
                // el.addEventListener('click', (event) => {
                //     if (event) {
                //         event && event.preventDefault(); // 阻止事件行为
                //         event && event.stopPropagation(); // 阻止事件冒泡
                //     } else if (window.event) {
                //         window.event.cancelBubble = true;  // ie
                //     }
                // }, true);
                //============
            } else {
                // 有权限则追加之前缓存的 dom 元素
                el.cacheParentElement && el.cacheParentElement.appendChild(el.cacheElement)
            }
        } else {
            console.error('The value bound to v-permission cannot be empty.')
        }
    } else {
        console.error('The value type bound to v-permission should be String.')
    }
}

const permission = {
    inserted: function (el, binding) {
        // ！！！敲重点
        el.cacheElement = el // 缓存本节点
        el.cacheParentElement = el.parentNode // 缓存父节点
        fn(el, binding)
    },
    update: function (el, binding) {
        fn(el, binding)
    }
}

export default permission
