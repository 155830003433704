<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.memberId"
          placeholder="关注会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-input
          v-model="query.followMemberId"
          placeholder="被关注会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <!-- header-cell-class-name="table-header"
        @selection-change="handleSelectionChange" -->
      <el-table :data="tableData" border class="table" ref="multipleTable">
        <!-- <el-table-column prop="id" label="#"></el-table-column> -->
        <!-- <el-table-column prop="memberId" label="会员ID"></el-table-column>  -->
        <el-table-column prop="memberId" label="会员ID"></el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.sex == '0'">男</p>
            <p v-if="scope.row.sex == '1'">女</p>
          </template>
        </el-table-column>
        <el-table-column prop="height" label="身高"></el-table-column>
        <el-table-column prop="workAddrCityName" label="城市"></el-table-column>
        <el-table-column prop="occBigCategory" label="职业"></el-table-column>
        <!-- <el-table-column label="头像">
          <template slot-scope="scope">
            <el-image style="width:100px;height:100px" fit="cover" :src="url + scope.row.avatar"></el-image>
          </template>
        </el-table-column> -->
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <p v-if="scope.row.createTime">
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { memberFollowPage } from "@/api/member/member.js";
import env from "@/api/env.js";
export default {
  name: "memberFollow",
  data() {
    return {
      query: {
        memberId: "",
        followMemberId: "",
        pageNum: 1,
        pageSize: 10,
      },
      form: {},
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
      url: env.ossUrl,
      tableData: [],
      editVisible: false,
      pageTotal: 0,
    };
  },
  created() {},
  methods: {
    async getData() {
      const { code, data } = await memberFollowPage({
        memberId: this.query.memberId,
        followMemberId: this.query.followMemberId,
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
    handlePageChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
