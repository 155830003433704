<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="newly"
          >新增</el-button
        >
      </div>
      <!-- 表格        :key="Math.random()"-->
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column prop="categoryName" label="分类昵称"></el-table-column>
        <el-table-column prop="categoryFlag" label="分类标识"></el-table-column>
        <el-table-column label="分类状态">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="form.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类标识">
          <el-input v-model="form.categoryFlag"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序">
          <el-input-number
            v-model="form.orderNum"
            @change="handleChange"
            :min="0"
            :max="999"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="分类描述">
          <el-input v-model="form.categoryDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditaCatUpdate">确定</el-button>
      </span>
    </el-dialog>
    <!-- 新增弹出框 -->
    <el-dialog title="新增" :visible.sync="newlya" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="form.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类标识">
          <el-input v-model="form.categoryFlag"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序">
          <el-input-number
            v-model="form.orderNum"
            @change="handleChange"
            :min="0"
            :max="999"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="分类描述">
          <el-input v-model="form.categoryDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newlya = false">取 消</el-button>
        <el-button type="primary" @click="saveEdita">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  catPage,
  catAdd,
  catDel,
  catUpdateStatus,
  catUpdate,
} from "@/api/article/article.js"; //
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  name: "ArrticleClassification",
  data() {
    return {
      query: {
        categoryName: "",
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      newlya: false,
      pageTotal: 0,
      form: {
        categoryDesc: "",
        categoryFlag: "",
        categoryName: "",
        orderNum: 0,
      },
      token: "",
      state: "",
      idx: -1,
      id: -1,
      loading: false,
    };
  },
  created() {
    this.getData();
    this.tkL();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async switchChange(e, index, row) {
      console.log(index);
      let a = {
        id: row.id,
        status: e,
      };
      const { code, data } = await catUpdateStatus(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.$set(row, "status", e);
        this.$message.success("更改状态成功");
      } else {
        this.tkL();
        this.$message.error("更改状态失败");
      }
    },
    // 获取 easy-mock 的模拟数据
    async getData() {
      try {
        this.loading = true;
        const { code, data } = await catPage({
          categoryName: this.query.categoryName,
          pageNum: this.query.pageNum,
          pageSize: this.query.pageSize,
        });
        if (code == 0) {
          this.loading = false;
          this.tableData = data.records;
          this.pageTotal = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    newly() {
      this.newlya = true;
      this.form = {
        categoryDesc: "",
        categoryFlag: "",
        categoryName: "",
        orderNum: 0,
      };
    },
    handleDelete(index, row) {
      this.idx = index;
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let a = {
            id: row.id,
          };
          const { code, data } = await catDel(a);
          if (code == 0) {
            this.$message.success("删除成功");
            console.log(data);
            // this.tableData.splice(index, 1);
            this.getData();
            this.tkL();
          } else {
            this.$message.warning("删除失败");
            this.tkL();
          }
        })
        .catch(() => {});
    }, // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = JSON.parse(JSON.stringify(row));
      this.editVisible = true;
    },
    handlePageChange(val) {
      this.query.pageNum = val;
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
    saveEdita() {
      this.addList();
    },
    saveEditaCatUpdate() {
      this.catUpdate();
      this.editVisible = false;
    },
    async catUpdate() {
      let a = {
        categoryDesc: this.form.categoryDesc,
        categoryFlag: this.form.categoryFlag,
        categoryName: this.form.categoryName,
        id: this.form.id,
        orderNum: this.form.orderNum,
      };
      const { code, data } = await catUpdate(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.getData();
        this.$message.success("修改成功");
      } else {
        this.tkL();
        this.$message.error("修改失败");
      }
    },
    async addList() {
      let a = {
        categoryDesc: this.form.categoryDesc,
        categoryFlag: this.form.categoryFlag,
        categoryName: this.form.categoryName,
        orderNum: this.form.orderNum,
      };
      const { code, data, msg } = await catAdd(a);
      if (code == 0) {
        console.log(data);
        this.getData();
        this.tkL();
        this.newlya = false;
      } else {
        this.$message.warning(msg);
        this.tkL();
      }
    },
    handleChange() {},
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 10px;
}
.red {
  color: #ff0000;
}
</style>
