<template>
  <div class="sidebar">
    <el-menu
      v-loading="loading"
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      active-text-color="#20a0ff"
      text-color="#bfcbd9"
      unique-opened
      router
    >
      <!--   -->
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu
            :index="item.index"
            :key="item.index"
            v-permission="item.prems"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs" v-permission="subItem.prems">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
                :class="subItem.prems"
                v-permission="subItem.prems"
              >
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  :class="threeItem.prems"
                  v-permission="threeItem.prems"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                :class="subItem.prems"
                v-permission="subItem.prems"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :index="item.index"
            :key="item.index"
            :class="item.prems"
            v-permission="item.prems"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../common/bus";
import { sysMenuTree } from "@/api/common.js";
export default {
  data() {
    return {
      collapse: false,
      loading: false,
      items: [
        {
          icon: "el-icon-s-home",
          index: "dashboard",
          title: "系统首页",
          prems: "dashboard",
        },
        {
          icon: "el-icon-tickets",
          index: "2",
          title: "文章管理",
          prems: "sys:article",
          subs: [
            {
              index: "ArticleQuery",
              title: "文章查询",
              prems: "sys:article",
            },
            {
              index: "ArticleClassification",
              title: "文章分类",
              prems: "sys:articlecategory",
            },
          ],
        },

        {
          icon: "el-icon-user-solid",
          index: "3",
          title: "用户管理",
          prems: "dashboard",
          subs: [
            {
              index: "guAccount",
              title: "系统用户",
              prems: "sys:user",
            },
            {
              // index: "puAccount",
              index: "3-2",
              title: "会员管理",
              prems: "member:info",
              subs: [
                {
                  index: "puAccount",
                  title: "会员",
                  prems: "member:info",
                },
                {
                  index: "report",
                  title: "会员举报",
                  prems: "member:inform",
                },
                {
                  index: "memberLikePage",
                  title: "会员点赞",
                  prems: "member:like",
                },
                {
                  index: "memberPointList",
                  title: "会员观点",
                  prems: "member:point",
                },
                {
                  index: "memberRecommendList",
                  title: "会员推荐",
                  prems: "member:album",
                },
                {
                  index: "memberServiceAppPage",
                  title: "会员服务",
                  prems: "member:album",
                },
                {
                  index: "memberFollowPage",
                  title: "会员关注",
                  prems: "member:album",
                },
                {
                  index: "videoMange",
                  title: "视频管理",
                  prems: "member:album",
                },
                {
                  index: "dynamic",
                  title: "动态管理",
                  prems: "member:album",
                },
              ],
            },
          ],
        },
        {
          icon: "el-icon-s-goods",
          index: "5",
          title: "商品信息",
          prems: "good",
          subs: [
            {
              index: "good",
              title: "商品信息",
              prems: "good",
            },
            {
              index: "goodCategory",
              title: "商品分类",
              prems: "good:category",
            },
            //  {
            //   index: "also",
            //   title: "也在币使用记录",
            //   prems: "member:album",
            // },
          ],
        },
        {
          icon: "el-icon-s-order",
          index: "6",
          title: "订单管理",
          prems: "member:album",
          subs: [
            {
              index: "order",
              title: "订单信息",
              prems: "member:album",
            },
            {
              index: "vipServiceRef",
              title: "开通信息",
              prems: "member:album",
            },
          ],
        },
        {
          icon: "el-icon-s-marketing",
          index: "8",
          title: "情感管理",
          prems: "sys:emoque",
          subs: [
            {
              index: "feeling",
              title: "问题管理",
              prems: "sys:emoque",
            },
            {
              index: "stdList",
              title: "情感分析",
              prems: "sys:emoque",
            },
            {
              index: "topic",
              title: "话题",
              prems: "sys:emoque",
            },
          ],
        },
        {
          icon: "el-icon-s-shop",
          index: "7",
          title: "门店管理",
          prems: "member:shop",
          subs: [
            {
              index: "storePage",
              title: "门店信息",
              prems: "member:shop",
            },
          ],
        },
        {
          icon: "el-icon-question",
          index: "9",
          title: "意见反馈",
          prems: "sys:feedback",
          subs: [
            {
              index: "feedback",
              title: "意见反馈",
              prems: "sys:feedback",
            },
          ],
        },
        {
          icon: "el-icon-s-tools",
          index: "4",
          title: "系统管理",
          prems: "dashboard",
          subs: [
            {
              index: "role",
              title: "角色管理",
              prems: "sys:role",
            },
            {
              index: "power",
              title: "权限管理",
              prems: "sys:menu",
            },
            {
              index: "sysConfigPage",
              title: "参数配置",
              prems: "sys:config",
            },
            {
              index: "DigitalDictionary",
              title: "字典管理",
              prems: "sys:dict",
            },
            {
              index: "org",
              title: "机构管理",
              prems: "sys:org",
            },
          ],
        },
      ],
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {
    // this.getMunu();
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
  },
  methods: {
    async getMunu() {
      try {
        this.loading = true;
        const { code, data } = await sysMenuTree({
          needBtns: false,
        });
        if (code == 0) {
          console.log(data);
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  user-select: none; /* CSS3属性 */
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
/* .el-menu-item.is-active {
  background-color: rgb(231, 235, 240) !important;
} */
::v-deep .is-active {
  color: #20a0ff !important;
  font-weight: bolder;
  border-bottom-color: black;
}
</style>
