
//本地
// export default {
//     baseUrl: 'http://192.168.100.117:8081', //请求地址
//     /**
//      * 阿里云
//      */
//     bucket: 'yezai',
//     ossUrl: 'https://yezai.oss-cn-shenzhen.aliyuncs.com/',
//     /**
//      * 上传图片
//      */
//     //文章
//     uploadUrlVideo: 'dev/back/video/',
//     uploadUrlCover: 'dev/back/cover/',

//     //话题
//     uploadUrlTopicList: 'dev/topic/list/',
//     uploadUrlTopicBanner: 'dev/topic/banner/',

//     //门店
//     uploadUrlStorePicture: 'dev/back/StorePicture/',

//     //后台头像
//     uploadUrlAvatar: 'dev/back/avatar/',
// }

//线上
export default {
    baseUrl: 'https://api.yezai.love', //请求地址
    /**
     * 阿里云
     */
    bucket: 'yezai-prod',
    ossUrl: 'https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/',
    /**
     * 上传图片
     */
    uploadUrlVideo: 'prod/back/video/',
    uploadUrlCover: 'prod/back/cover/',

    //话题
    uploadUrlTopicList: 'prod/topic/list/',
    uploadUrlTopicBanner: 'prod/topic/banner/',

    //门店
    uploadUrlStorePicture: 'prod/back/StorePicture/',

    //后台头像
    uploadUrlAvatar: 'prod/back/avatar/',
}