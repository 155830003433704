import axios from "axios";
import Qs from "qs";
// import router from '@/router';
import local from "@/api/common/local.js"
import { message } from '@/assets/js/resetMessage.js';

import env from "@/api/env.js"

const instance = axios.create({});
// if (process.env.NODE_ENV == "development") {
//     instance.defaults.baseURL = 'https://api.yezai.love';
//     // instance.defaults.baseURL = 'http://192.168.100.115:8081';
// } else if (process.env.NODE_ENV == "production") {
//     instance.defaults.baseURL = 'https://api.yezai.love';
// }
/**
 * 测试
 */
instance.defaults.baseURL = env.baseUrl
//指定请求超时的毫秒数
instance.defaults.timeout = 5000;

// 请求拦截器
instance.interceptors.request.use(config => {
    let token = local.get("access_token");
    if (token) {
        config.headers.Authorization = local.get("access_token")
    }
    return config;
})
//发情求,做处理
instance.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效

    // if (response.data.code === 10010 || response.data.code === 10011) {
    if (response.data.code === 2) {
        // if (response.data.msg === "没有权限，请联系管理员授权") {
        // localStorage.Remove('access_token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
        // this.$router.replace({
        //     path: '/login' // 到登录页重新获取token
        // })
        // window.location.href = '/login'
        // local.clear()
        // router.push('/login')
        message.error({
            message: "没有权限，请联系管理员授权"
        })
    } else if (response.data.access_token) { // 判断token是否存在，如果存在说明需要更新token
        Storage.localSet('access_token', response.data.access_token) // 覆盖原来的token(默认一天刷新一次)
    }
    return response

}, function (error) {
    // Message.error("网络请求超时,请稍后再试")
    message.error({
        message: "网络请求超时,请稍后再试..........",
    })


    return Promise.reject(error)

})

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance.get(url,
            {
                headers: {
                    "access-token": params,
                }
            }).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(err)
            })
    })
}


const gett = (url, params) => {
    return new Promise((resolve, reject) => {
        // let token = local.get("access_token");
        instance.get(url,
            {
                headers: {
                    "access-token": `${params.token}`,

                }
            }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const gettL = (url) => {
    return new Promise((resolve, reject) => {
        // let token = local.get("access_token");
        instance.get(url).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const gettLL = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.get(`${url}?${Qs.stringify(data)}`, {
            headers: {
                "access-token": `${token}`,
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const gettLLX = (url) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        let tk = local.SessionGet('sub_tk')
        instance.get(`${url}`, {
            headers: {
                "access-token": `${token}`,
                'sub-tk': `${tk}`
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const gettLLL = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.get(url, {
            headers: {
                "access-token": `${token}`,
            },
            params: data
        }).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}
const post = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        let tk = local.SessionGet('sub_tk')
        instance.post(url, data, { headers: { 'Content-Type': 'application/json', 'access-token': `${token}`, 'sub-tk': `${tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const postt = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        let tk = local.SessionGet('sub_tk')
        instance.post(url, Qs.stringify(data), { headers: { 'access-token': `${token}`, 'sub-tk': `${tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const posttL = (url, data) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.post(url, Qs.stringify(data), { headers: { 'access-token': `${token}`, 'sub-tk': `${data.tk}` } }
        ).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

const DELETE = (url) => {
    return new Promise((resolve, reject) => {
        let token = local.get("access_token");
        instance.delete(url, { headers: { 'access-token': `${token}` } }
        ).then((response) => {
            resolve(response);
        }).catch((err) => {
            reject(err)
        })
    })
}

//极验
const Axiosget = (url) => {
    return new Promise((resolve, reject) => {
        instance.get(url).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err)
        })
    })
}

export default {
    get,
    post,
    postt,
    gett,
    posttL,
    gettL,
    gettLL,
    gettLLL,
    gettLLX,
    DELETE,
    Axiosget,
}
