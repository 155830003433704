<template>
  <div class="MemberInfo">
    <!-- 左边页面 -->
    <div class="MemberInfo_left" ref="memberLeft">
      <div class="MemberInfo_left_top">
        <div class="MemberInfo_left_top-top">
          <div class="MemberInfo_left_top-top-left">
            <div class="MemberInfo_left_top-top-left_N_T">
              <p>{{ nickName }}</p>
              <div class="MemberInfo_left_top-top-left_icon">
                <img
                  v-if="UisVipMember == 1"
                  src="../../../assets/images/yezai_member_auth_aa.png"
                  alt=""
                  title="也在会员"
                />
                <img
                  v-if="realAuthStatus == 2"
                  src="../../../assets/images/yezai_auth_aa.png"
                  alt=""
                  title="实名认证"
                />
              </div>
            </div>
            <p class="MemberInfo_left_top-top-left_id">{{ memberId }}</p>
            <p class="MemberInfo_left_top-top-left_label">
              <span v-if="sex">{{ sex == 0 ? "男" : "女" }} </span>
              <span v-if="age">| {{ age }}岁 </span>
              <span v-if="nativePlaceCityName"
                >| {{ nativePlaceCityName }}
              </span>
              <span v-if="education">| {{ education }} </span>
              <span v-if="maritalStatus">| {{ maritalStatus }} </span>
              <span v-if="height"> | {{ height }}cm </span>
              <span v-if="monthIncome">| {{ monthIncome }} </span>
            </p>
          </div>
          <div class="MemberInfo_left_top-top-right">
            <img src="../../../assets/images/loginDate.png" alt="" />
            <p v-if="loginDate">{{ loginDate }}</p>
            <p v-else>近期未登录</p>
          </div>
        </div>
        <div class="MemberInfo_left_top-bottom">
          <div class="MemberInfo_left_top-bottom_img">
            <img :src="avatar" alt="" />
          </div>
          <div class="info-info-lunbo" v-if="isImg">
            <el-carousel
              :loop="false"
              :autoplay="false"
              indicator-position="none"
              arrow="always"
              height="80px"
              style="width: 600px"
            >
              <el-carousel-item
                class="el-car-item"
                v-for="(list, index) in items"
                :key="index"
              >
                <el-image
                  :preview-src-list="listImg"
                  v-for="(imgList, index2) in list"
                  :key="index2"
                  class="top-img"
                  :src="imgList.imgage"
                  alt=""
                >
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="no-photo" v-if="!isImg">
            <span class="icon"></span>
            {{ `${sex == 0 ? "他" : "她"}还没发布照片` }}
          </div>
        </div>
      </div>

      <div class="MemberInfo_left_bottom">
        <!-- 选项卡 -->
        <div class="MemberInfo_left_bottom-tab">
          <p
            class="default-tab"
            :class="{ 'active-tab': index == 1 }"
            @click="index = 1"
          >
            我的主页
          </p>
          <p
            class="default-tab"
            :class="{ 'active-tab': index == 2 }"
            @click="index = 2"
          >
            操作日志
          </p>
        </div>

        <div class="MemberInfo_left_bottom-HomePage" v-if="index == 1">
          <div class="info-zi left_bottom-HomePage_height" ref="HomePageHeight">
            <div class="zi-left">
              <div class="left-box">
                <p>内心独白</p>
                <div class="detailed-introduce">
                  {{
                    introduce || `${sex == 0 ? "他" : "她"}还很懒,还没内心独白`
                  }}
                </div>
                <!-- 加省略 -->
                <!-- <div
                  :class="showTotal ? 'total-introduce' : 'detailed-introduce'"
                >
                  <div class="intro-content" :title="introduce" ref="desc">
                    <span class="merchant-desc">
                      {{
                        introduce ||
                        `${sex == 0 ? "他" : "她"}还很懒,还没内心独白`
                      }}
                    </span>
                    <div
                      class="unfold"
                      @click="showTotalIntro"
                      v-if="showExchangeButton"
                    >
                      <p>{{ exchangeButton ? "展开" : "收起" }}</p>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="left-box">
                <p>个人资料</p>
                <div class="box2">
                  <div class="info_div">
                    <!-- v-if="info.maritalStatus" -->
                    <div class="m-btn purple" v-if="info.maritalStatus">
                      {{ info.maritalStatus }}
                    </div>
                    <div class="m-btn purple" v-if="info.education">
                      {{ info.education }}
                    </div>
                    <div class="m-btn purple" v-if="info.age">
                      {{ info.age + "岁" }}
                    </div>
                    <div class="m-btn purple" v-if="info.workAddrCityName">
                      {{ "工作地区:" + info.workAddrCityName }}
                    </div>
                    <div class="m-btn purple" v-if="info.monthIncome">
                      {{ info.monthIncome }}
                    </div>
                    <div class="m-btn purple" v-if="info.weight">
                      {{
                        `体重:${info.weight > 29 ? info.weight : "30"}kg${
                          info.weight > 29 ? "" : "以下"
                        }`
                      }}
                    </div>
                    <!-- <div class="m-btn purple" v-if="info.shap"> -->
                    <div class="m-btn purple" v-if="Boolean(info.shape)">
                      {{ "体型:" + info.shape }}
                    </div>
                    <!-- info.constellatory -->
                    <div class="m-btn purple" v-if="info.constellatory">
                      {{ info.constellatory }}
                    </div>
                    <div class="m-btn purple" v-if="info.nation">
                      {{ info.nation }}
                    </div>
                    <div class="m-btn purple" v-if="info.height">
                      {{ info.height + "cm" }}
                    </div>
                  </div>
                  <div class="info_box">
                    <div class="m-btn purple" v-if="info.nativePlaceCityName">
                      {{ "籍贯:" + info.nativePlaceCityName }}
                    </div>
                    <div class="m-btn purple" v-if="info.wantChildren">
                      {{ "是否想要孩子:" + info.wantChildren }}
                    </div>
                    <div class="m-btn purple" v-if="info.marriedTime">
                      {{ "何时结婚:" + info.marriedTime }}
                    </div>
                    <div class="m-btn purple" v-if="info.occBigCategory">
                      {{ "职业:" + info.occBigCategory }}
                    </div>
                    <div class="m-btn purple" v-if="info.buyCarStatus">
                      {{ "是否买车:" + info.buyCarStatus }}
                    </div>
                    <div class="m-btn purple" v-if="info.houseStatus">
                      {{ "住房情况:" + info.houseStatus }}
                    </div>

                    <div class="m-btn purple" v-if="info.doDrink">
                      {{ "是否喝酒:" + info.doDrink }}
                    </div>
                    <div class="m-btn purple" v-if="info.doSmake">
                      {{ "是否吸烟:" + info.doSmake }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-box">
                <p>兴趣爱好</p>
                <div class="box3">
                  <p>
                    喜欢的一道菜：<span v-if="info.favoriteDishes">{{
                      info.favoriteDishes
                    }}</span>
                    <span v-if="!info.favoriteDishes">未填写</span>
                  </p>
                  <p>
                    欣赏的一个名人：
                    <span v-if="info.favoriteCelebrities">{{
                      info.favoriteCelebrities
                    }}</span>
                    <span v-if="!info.favoriteCelebrities">未填写</span>
                  </p>
                  <p>
                    喜欢的一首歌：
                    <span v-if="info.favoriteSongs">{{
                      info.favoriteSongs
                    }}</span>
                    <span v-if="!info.favoriteSongs">未填写</span>
                  </p>
                  <p>
                    喜欢的一本书：
                    <span v-if="info.favoriteBooks">{{
                      info.favoriteBooks
                    }}</span>
                    <span v-if="!info.favoriteBooks">未填写</span>
                  </p>
                  <p class="jjj">
                    喜欢做的事：<span v-if="info.likeDoing">{{
                      info.likeDoing
                    }}</span>
                    <span v-if="!info.likeDoing">未填写</span>
                  </p>
                </div>
              </div>
              <div class="left-box">
                <p>择偶条件</p>
                <div class="box4">
                  <!-- 年龄 -->
                  <div
                    class="m-btn"
                    v-if="
                      !(
                        mateSelection.ageMin == null &&
                        mateSelection.ageMax == null
                      )
                    "
                  >
                    {{ mateSelection.ageMin }}{{ mateSelection.ageMax }}
                  </div>
                  <!-- 身高 -->
                  <div
                    class="m-btn"
                    v-if="
                      !(
                        mateSelection.heightMin == null &&
                        mateSelection.heightMax == null
                      )
                    "
                  >
                    {{ mateSelection.heightMin + mateSelection.heightMax }}
                  </div>
                  <!-- 薪资 -->
                  <div
                    class="m-btn"
                    v-if="
                      !(
                        mateSelection.monthIncomeMax == null &&
                        mateSelection.monthIncomeMin == null
                      )
                    "
                  >
                    月薪:{{ mateSelection.monthIncomeMin
                    }}{{ mateSelection.monthIncomeMax }}
                  </div>
                  <!-- 学历 -->
                  <div class="m-btn" v-if="mateSelection.education">
                    {{ mateSelection.education }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.maritalStatus">
                    {{ mateSelection.maritalStatus }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.shape">
                    {{ "体型:" + mateSelection.shape }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.workAddrCityName">
                    {{ "工作地区:" + mateSelection.workAddrCityName }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.existsChildren">
                    {{ mateSelection.existsChildren }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.wantChildren">
                    {{ "是否想要孩子:" + mateSelection.wantChildren }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.doSmake">
                    {{ mateSelection.doSmake }}
                  </div>
                  <div class="m-btn" v-if="mateSelection.doDrink">
                    {{ mateSelection.doDrink }}
                  </div>
                  <div class="m-btn" v-if="iSmateSelection">不限</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 操作日志 -->
        <div class="MemberInfo_left_bottom-OperationLog" v-if="index == 2">
          <div
            class="left_bottom-OperationLog left_bottom-OperationLog_height"
            ref="HomePageHeight"
          ></div>
        </div>
      </div>
    </div>

    <!-- 右边页面  -->
    <div class="MemberInfo_right" ref="memberRight">
      <el-tabs type="border-card" v-model="active" @tab-click="handleClickBig">
        <el-tab-pane label="看过我" name="one">
          <el-tabs
            v-model="activeName"
            @tab-click="handleClickOne"
            class="MemberInfo_right-tab"
          >
            <el-tab-pane label="谁看过我" name="first">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightA"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listA"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.browseAvatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.browseNickName }}</p>
                      <img
                        v-if="item.browseIsVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.browseRealAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.browseRealAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.browseAge">{{
                        item.browseAge + "岁"
                      }}</span>
                      <span v-if="item.browseWorkAddrCityName"
                        >&nbsp;|&nbsp;{{ item.browseWorkAddrCityName }}</span
                      >
                      <span v-if="item.browseOccBigCategory">
                        &nbsp;|&nbsp; {{ item.browseOccBigCategory }}
                      </span>
                      <span v-if="item.browseHeight"
                        >&nbsp;|&nbsp;{{ item.browseHeight + "cm" }}
                      </span>
                      <span v-if="item.browseEducation == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.browseEducation == 1"
                        >&nbsp;|&nbsp;中专
                      </span>
                      <span v-if="item.browseEducation == 2"
                        >&nbsp;|&nbsp;大专
                      </span>
                      <span v-if="item.browseEducation == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.browseEducation == 4"
                        >&nbsp;|&nbsp;硕士
                      </span>
                      <span v-if="item.browseEducation == 5"
                        >&nbsp;|&nbsp;博士
                      </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.updateTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.updateTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.updateTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.updateTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.updateTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.updateTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.updateTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.updateTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.updateTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChange"
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我看过谁" name="second">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightB"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listB"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.browseAvatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.browseNickName }}</p>
                      <img
                        v-if="item.browseIsVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.browseRealAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.browseRealAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.browseAge">{{
                        item.browseAge + "岁"
                      }}</span>
                      <span v-if="item.browseWorkAddrCityName"
                        >&nbsp;|&nbsp;{{ item.browseWorkAddrCityName }}</span
                      >
                      <span v-if="item.browseOccBigCategory">
                        &nbsp;|&nbsp; {{ item.browseOccBigCategory }}
                      </span>
                      <span v-if="item.browseHeight"
                        >&nbsp;|&nbsp;{{ item.browseHeight + "cm" }}
                      </span>
                      <span v-if="item.browseEducation == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.browseEducation == 1"
                        >&nbsp;|&nbsp;中专
                      </span>
                      <span v-if="item.browseEducation == 2"
                        >&nbsp;|&nbsp;大专
                      </span>
                      <span v-if="item.browseEducation == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.browseEducation == 4"
                        >&nbsp;|&nbsp;硕士
                      </span>
                      <span v-if="item.browseEducation == 5"
                        >&nbsp;|&nbsp;博士
                      </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.updateTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.updateTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.updateTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.updateTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.updateTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.updateTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.updateTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.updateTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.updateTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeA"
                ></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="关注我" name="two">
          <el-tabs
            v-model="activeNameA"
            @tab-click="handleClickTwo"
            class="MemberInfo_right-tab"
          >
            <el-tab-pane label="谁关注我" name="firstA">
              <div
                v-loading="loading"
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightC"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listC"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.avatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.nickName }}</p>
                      <img
                        v-if="item.isVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.age">{{ item.age + "岁" }}</span>
                      <span v-if="item.workAddrCityName"
                        >&nbsp;|&nbsp;{{ item.workAddrCityName }}</span
                      >
                      <span v-if="item.occBigCategory">
                        &nbsp;|&nbsp; {{ item.occBigCategory }}
                      </span>
                      <span v-if="item.height"
                        >&nbsp;|&nbsp;{{ item.height + "cm" }}
                      </span>
                      <span v-if="item.education == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.education == 1">&nbsp;|&nbsp;中专 </span>
                      <span v-if="item.education == 2">&nbsp;|&nbsp;大专 </span>
                      <span v-if="item.education == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.education == 4">&nbsp;|&nbsp;硕士 </span>
                      <span v-if="item.education == 5">&nbsp;|&nbsp;博士 </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeB"
                ></el-pagination></div
            ></el-tab-pane>
            <el-tab-pane label="我关注谁" name="secondA">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightD"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listD"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.avatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.nickName }}</p>
                      <img
                        v-if="item.isVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.age">{{ item.age + "岁" }}</span>
                      <span v-if="item.workAddrCityName"
                        >&nbsp;|&nbsp;{{ item.workAddrCityName }}</span
                      >
                      <span v-if="item.occBigCategory">
                        &nbsp;|&nbsp; {{ item.occBigCategory }}
                      </span>
                      <span v-if="item.height"
                        >&nbsp;|&nbsp;{{ item.height + "cm" }}
                      </span>
                      <span v-if="item.education == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.education == 1">&nbsp;|&nbsp;中专 </span>
                      <span v-if="item.education == 2">&nbsp;|&nbsp;大专 </span>
                      <span v-if="item.education == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.education == 4">&nbsp;|&nbsp;硕士 </span>
                      <span v-if="item.education == 5">&nbsp;|&nbsp;博士 </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeC"
                ></el-pagination></div
            ></el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="赞过我" name="three">
          <el-tabs
            v-model="activeNameB"
            @tab-click="handleClickThree"
            class="MemberInfo_right-tab"
          >
            <el-tab-pane label="谁赞过我" name="firstB">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightE"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listE"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.avatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <!-- <div>{{ item.nickName }}</div> -->
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.nickName }}</p>
                      <img
                        v-if="item.isVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.age">{{ item.age + "岁" }}</span>
                      <span v-if="item.workAddrCityName"
                        >&nbsp;|&nbsp;{{ item.workAddrCityName }}</span
                      >
                      <span v-if="item.education == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.education == 1">&nbsp;|&nbsp;中专 </span>
                      <span v-if="item.education == 2">&nbsp;|&nbsp;大专 </span>
                      <span v-if="item.education == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.education == 4">&nbsp;|&nbsp;硕士 </span>
                      <span v-if="item.education == 5">&nbsp;|&nbsp;博士 </span>
                      <span v-if="item.occBigCategory">
                        &nbsp;|&nbsp; {{ item.occBigCategory }}
                      </span>
                      <span v-if="item.height"
                        >&nbsp;|&nbsp;{{ item.height + "cm" }}
                      </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeD"
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我赞过谁" name="secondB">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightF"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listF"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img :src="url + item.likedAvatar" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.likedNickName }}</p>
                      <img
                        v-if="item.likedIsVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.likedRealAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.likedRealAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.likedAge">{{
                        item.likedAge + "岁"
                      }}</span>
                      <span v-if="item.likedWorkAddrCityName"
                        >&nbsp;|&nbsp;{{ item.likedWorkAddrCityName }}</span
                      >
                      <span v-if="item.likedOccBigCategory">
                        &nbsp;|&nbsp; {{ item.likedOccBigCategory }}
                      </span>
                      <span v-if="item.likedHeight"
                        >&nbsp;|&nbsp;{{ item.likedHeight + "cm" }}
                      </span>
                      <span v-if="item.likedEducation == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.likedEducation == 1"
                        >&nbsp;|&nbsp;中专
                      </span>
                      <span v-if="item.likedEducation == 2"
                        >&nbsp;|&nbsp;大专
                      </span>
                      <span v-if="item.likedEducation == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.likedEducation == 4"
                        >&nbsp;|&nbsp;硕士
                      </span>
                      <span v-if="item.likedEducation == 5"
                        >&nbsp;|&nbsp;博士
                      </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeE"
                ></el-pagination></div
            ></el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="评论我" name="four">
          <el-tabs
            v-model="activeNameC"
            @tab-click="handleClickFour"
            class="MemberInfo_right-tab"
          >
            <el-tab-pane label="谁评论我" name="firstC">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                ref="MemberInfoRightG"
                v-loading="loading"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listG"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img v-if="item.avatar" :src="url + item.avatar" alt="" />
                    <img v-else :src="tou" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.nickName }}</p>
                      <img
                        v-if="item.isVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.realAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.age">{{ item.age + "岁" }}</span>
                      <span v-if="item.workAddrCityName"
                        >&nbsp;|&nbsp;{{ item.workAddrCityName }}</span
                      >
                      <span v-if="item.occBigCategory">
                        &nbsp;|&nbsp; {{ item.occBigCategory }}
                      </span>
                      <span v-if="item.height"
                        >&nbsp;|&nbsp;{{ item.height + "cm" }}
                      </span>
                      <span v-if="item.education == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.education == 1">&nbsp;|&nbsp;中专 </span>
                      <span v-if="item.education == 2">&nbsp;|&nbsp;大专 </span>
                      <span v-if="item.education == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.education == 4">&nbsp;|&nbsp;硕士 </span>
                      <span v-if="item.education == 5">&nbsp;|&nbsp;博士 </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeF"
                ></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我评论谁" name="secondC">
              <div
                class="MemberInfo_right-v"
                v-show="!(total == 0)"
                v-loading="loading"
                ref="MemberInfoRightH"
              >
                <div
                  class="MemberInfo_right-common"
                  v-for="item in listH"
                  :key="item.id"
                >
                  <div class="MemberInfo_right-common-left">
                    <img v-if="item.pavatar" :src="url + item.pavatar" alt="" />
                    <img v-else :src="tou" alt="" />
                  </div>
                  <div class="MemberInfo_right-common-right">
                    <div class="MemberInfo_right-common-right-icon">
                      <p class="common-right-ft">{{ item.pnickName }}</p>
                      <img
                        v-if="item.pisVipMember == 1"
                        :src="memberVip"
                        alt=""
                      />
                      <p
                        v-if="item.prealAuthStatus == 2"
                        class="common-right-icon_left"
                      >
                        人脸认证
                      </p>
                      <p
                        v-if="item.prealAuthStatus == 2"
                        class="common-right-icon_right"
                      >
                        实名认证
                      </p>
                    </div>
                    <p class="MemberInfo_right-common-right-label">
                      <span v-if="item.page">{{ item.page + "岁" }}</span>
                      <span v-if="item.pworkAddrCityName"
                        >&nbsp;|&nbsp;{{ item.pworkAddrCityName }}</span
                      >
                      <span v-if="item.poccBigCategory">
                        &nbsp;|&nbsp; {{ item.poccBigCategory }}
                      </span>
                      <span v-if="item.pheight"
                        >&nbsp;|&nbsp;{{ item.pheight + "cm" }}
                      </span>
                      <span v-if="item.peducation == 0"
                        >&nbsp;|&nbsp;高中及以下
                      </span>
                      <span v-if="item.peducation == 1"
                        >&nbsp;|&nbsp;中专
                      </span>
                      <span v-if="item.peducation == 2"
                        >&nbsp;|&nbsp;大专
                      </span>
                      <span v-if="item.peducation == 3"
                        >&nbsp;|&nbsp;大学本科
                      </span>
                      <span v-if="item.peducation == 4"
                        >&nbsp;|&nbsp;硕士
                      </span>
                      <span v-if="item.peducation == 5"
                        >&nbsp;|&nbsp;博士
                      </span>
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).format("H:mm ") }}
                    </p>
                    <p
                      class="MemberInfo_right-common-right-time"
                      v-else-if="
                        new Date(item.createTime).getDate() ==
                          new Date().getDate() - 1 &&
                        new Date(item.createTime).getMonth() ==
                          new Date().getMonth() &&
                        new Date(item.createTime).getYear() ==
                          new Date().getYear()
                      "
                    >
                      {{ $moment(item.createTime).calendar() }}
                    </p>
                    <p class="MemberInfo_right-common-right-time" v-else>
                      {{ $moment(item.createTime).format("MMMDoH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="pagination" v-show="!(total == 0)">
                <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="handlePageChangeG"
                ></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { query } from "@/api/member/member.js";
import { ossL } from "@/api/common/oss/ossL.js";
import {
  memberLikePage,
  memberFollowPage,
  memberViewBrowingPage,
  memberCommentPage,
} from "@/api/member/member.js";

import env from "@/api/env.js";
export default {
  // name: "MemberInfo",
  data() {
    return {
      activeName: "first",
      activeNameA: "firstA",
      activeNameB: "firstB",
      activeNameC: "firstC",
      active: "one",
      /**
       * 图片地址
       */
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
      url: env.ossUrl,
      /**
       * 当前用户信息
       */
      avatar: "",
      nickName: "", //昵称
      memberId: "", //ID: 锤子
      loginDate: "",
      isImg: false, //相册状态
      UisVipMember: "0",
      realAuthStatus: "0",
      sex: "0",
      age: "",
      nativePlaceCityName: "",
      education: "",
      maritalStatus: "",
      height: "",
      monthIncome: "",
      introduce: "", // 内心独白
      /**
       * 内心独白
       */
      // 是否展示所有文本内容
      showTotal: true,
      // 显示展开还是收起
      exchangeButton: true,
      // 是否显示展开收起按钮
      showExchangeButton: false,
      rem: "",
      /**
       * end
       */
      items: [], //相册
      listImg: [],
      zIndex: 9999,
      /**
       * 选项卡初始状态
       */
      index: 1,
      /**
       * 择偶条件
       */
      mateSelection: {},
      iSmateSelection: true,
      /**
       * 基础资料
       */
      info: {},
      /**
       * 数据处理
       */
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      constellations: [
        {
          value: "牡羊座(03.21-04.20)",
          label: 0,
        },
        {
          value: "金牛座(04.21-05.20)",
          label: 1,
        },
        {
          value: "双子座(05.21-06.21)",
          label: 2,
        },
        {
          value: "巨蟹座(06.22-07.22)",
          label: 3,
        },
        {
          value: "狮子座(07.23-08.22)",
          label: 4,
        },
        {
          value: "处女座(08.23-09.22)",
          label: 5,
        },
        {
          value: "天秤座(09.23-10.22)",
          label: 6,
        },
        {
          value: "天蝎座(10.23-11.21)",
          label: 7,
        },
        {
          value: "射手座(11.22-12.21)",
          label: 8,
        },
        {
          value: "摩羯座(12.22-01.19)",
          label: 9,
        },
        {
          value: "水瓶座(01.20-02.19)",
          label: 10,
        },
        {
          value: "双鱼座(02.20-03.20)",
          label: 11,
        },
      ], //星座
      shapes: [
        {
          value: "保密",
          label: "0",
        },
        {
          value: "一般",
          label: "1",
        },
        {
          value: "瘦长",
          label: "2",
        },
        {
          value: "运动员型",
          label: "3",
        },
        {
          value: "比较胖",
          label: "4",
        },
        {
          value: "体格魁梧",
          label: "5",
        },
        {
          value: "壮实",
          label: "6",
        },
        {
          value: "苗条",
          label: "7",
        },
        {
          value: "高大美丽",
          label: "8",
        },
        {
          value: "丰满",
          label: "9",
        },
        {
          value: "富线条美",
          label: "10",
        },
      ], //体型
      existsChildrens: [
        {
          value: "没有孩子",
          label: "0",
        },
        {
          value: "有孩子且住在一起",
          label: "1",
        },
        {
          value: "有孩子且偶尔会在一起住",
          label: "2",
        },
        {
          value: "有孩子但不在身边",
          label: "3",
        },
      ], //有无孩子
      wantChildrens: [
        {
          value: "视情况而定",
          label: "0",
        },
        {
          value: "想要孩子",
          label: "1",
        },
        {
          value: "不想要孩子",
          label: "2",
        },
        {
          value: "以后再告诉你",
          label: "3",
        },
        {
          value: "以后再告诉你",
          label: "4",
        },
      ], //是否想要孩子
      //是否吸烟
      doSmakess: [
        {
          value: "不要吸烟",
          label: "0",
        },
        {
          value: "可以吸烟",
          label: "1",
        },
      ],
      doSmakes: [
        // {
        //   value: "不限",
        //   label: null,
        // },
        {
          value: "不吸烟",
          label: "0",
        },
        {
          value: "稍微抽一点烟",
          label: "1",
        },
        {
          value: "烟抽得很多",
          label: "2",
        },
        {
          value: "社交场合会抽烟",
          label: "3",
        },
      ],
      doDrinks: [
        // {
        //   value: "不限",
        //   label: null,
        // },
        {
          value: "不喝酒",
          label: "0",
        },
        {
          value: "稍微喝一点酒",
          label: "1",
        },
        {
          value: "酒喝得很多",
          label: "2",
        },
        {
          value: "社交场合会喝酒",
          label: "3",
        },
      ],
      //是否喝酒
      doDrinkss: [
        {
          value: "不要喝酒",
          label: "0",
        },
        {
          value: "可以喝酒",
          label: "1",
        },
      ],
      //有无照片
      avaters: [
        {
          value: "有",
          label: "1",
        },
      ],
      marriedTimes: [
        {
          value: "认同闪婚",
          label: 0,
        },
        {
          value: "一年内",
          label: 1,
        },
        {
          value: "两年内",
          label: 2,
        },
        {
          value: "三年内",
          label: 3,
        },
        {
          value: "时机成熟就结婚",
          label: 4,
        },
      ], //何时结婚
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ], //买车
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      marriages: [
        {
          value: "未婚",
          label: "0",
        },
        {
          value: "离异",
          label: "1",
        },
        {
          value: "丧偶",
          label: "2",
        },
      ], //婚姻
      /**
       * end
       */
      list: [],
      listA: [],
      listB: [],
      listC: [],
      listD: [],
      listE: [],
      listF: [],
      listG: [],
      listH: [],

      total: 0,
      pageNum: 1,
      pageSize: 10,
      /**
       * 模态
       */
      loading: false,
      /**
       * 图标
       */
      memberVip: require("@/assets/images/yezai_member_auth_aa.png"),
      memberRealAuthStatus: require("@/assets/images/yezai_auth_aa.png"),
      tou: require("@/assets/images/tou.png"),
    };
  },
  mounted() {
    this.cHeight();
    if (this.$route.query.id) {
      this.UserInfo();
      this.ViewBrowingA();
    } else {
      this.$router.go(-1); //返回上一层
    }
  },
  methods: {
    /**
     * 可视化高度
     */
    cHeight() {
      this.$nextTick(function () {
        let a = this.$refs.memberRight.clientHeight - 182;
        // this.$refs.MemberInfoRightV.style.height = a + "px";
        this.$refs.MemberInfoRightA.style.height = a + "px";
        this.$refs.MemberInfoRightB.style.height = a + "px";
        this.$refs.MemberInfoRightC.style.height = a + "px";
        this.$refs.MemberInfoRightD.style.height = a + "px";
        this.$refs.MemberInfoRightE.style.height = a + "px";
        this.$refs.MemberInfoRightF.style.height = a + "px";
        this.$refs.MemberInfoRightG.style.height = a + "px";
        this.$refs.MemberInfoRightH.style.height = a + "px";
        let b = this.$refs.memberLeft.clientHeight - 320;
        this.$refs.HomePageHeight.style.height = b + "px";
      });
    },
    // 获取用户详情
    async UserInfo() {
      const { code, data } = await query(this.$route.query.id);
      if (code == 0) {
        console.log(data);
        this.loginDate = data.user.loginDate; //最近登录时间
        this.avatar = ossL(data.user.avatar);
        this.sex = data.user.sex;
        this.nickName = data.user.nickName; //昵称
        this.memberId = `ID: ${data.user.memberId}`; //id
        this.UisVipMember = data.user.isVipMember;
        this.realAuthStatus = data.user.realAuthStatus;
        this.age = data.user.age;
        this.height = data.user.height; //身高
        this.nativePlaceCityName = data.user.workAddrProvinceName; //籍贯
        this.introduce = data.user.introduce; // 内心独白
        /**
         * 基础资料
         */
        this.info = data.user;
        this.mateSelection = data.user.mateSelection;

        // 薪资
        let c = data.user.monthIncome;
        if (c == 0) {
          this.monthIncome = "3000元以下";
          this.info.monthIncome = "3000元以下";
        } else if (c == 1) {
          this.monthIncome = "3001-5000元";
          this.info.monthIncome = "3001-5000元";
        } else if (c == 2) {
          this.monthIncome = "5001-8000元";
          this.info.monthIncome = "5001-8000元";
        } else if (c == 3) {
          this.monthIncome = "8001-12000元";
          this.info.monthIncome = "8001-12000元";
        } else if (c == 4) {
          this.monthIncome = "12001-20000元";
          this.info.monthIncome = "12001-20000元";
        } else if (c == 5) {
          this.monthIncome = "20000-50000元";
          this.info.monthIncome = "20000-50000元";
        } else if (c == 6) {
          this.monthIncome = "50000元以上";
          this.info.monthIncome = "50000元以上";
        }
        /**
         *
         */
        //年龄区间处理
        if (
          this.mateSelection.ageMax == null &&
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "岁以上";
          this.mateSelection.ageMax = "";
        } else if (
          this.mateSelection.ageMax != null &&
          this.mateSelection.ageMin == null
        ) {
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁以下";
          this.mateSelection.ageMin = "";
        } else if (
          this.mateSelection.ageMax != null ||
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "-";
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁";
        }
        //身高
        if (
          this.mateSelection.heightMax == null &&
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin =
            this.mateSelection.heightMin + "cm以上";
          this.mateSelection.heightMax = "";
        } else if (
          this.mateSelection.heightMax != null &&
          this.mateSelection.heightMin == null
        ) {
          this.mateSelection.heightMax =
            this.mateSelection.heightMax + "cm以下";
          this.mateSelection.heightMin = "";
        } else if (
          this.mateSelection.heightMax != null ||
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin = this.mateSelection.heightMin + "-";
          this.mateSelection.heightMax = this.mateSelection.heightMax + "cm";
        }
        //收入
        if (
          this.mateSelection.monthIncomeMax == null &&
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "元以上";
          this.mateSelection.monthIncomeMax = "";
        } else if (
          this.mateSelection.monthIncomeMax != null &&
          this.mateSelection.monthIncomeMin == null
        ) {
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元以下";
          this.mateSelection.monthIncomeMin = "";
        } else if (
          this.mateSelection.monthIncomeMax != null ||
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "-";
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元";
        }
        //星座
        if (data.user.constellatory) {
          this.constellations.forEach((item) => {
            if (data.user.constellatory == item.label) {
              this.info.constellatory = item.value;
              console.log(
                "体型",
                data.user.constellatory,
                this.info.constellatory
              );
            }
          });
        }
        //体型
        this.shapes.forEach((item) => {
          if (data.user.shape == item.label) {
            this.info.shape = item.value;
          }
        });
        this.shapes.forEach((item) => {
          if (data.user.mateSelection.shape == item.label) {
            this.mateSelection.shape = item.value;
          }
        });
        //有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.existsChildren == item.label) {
            this.info.existsChildren = item.value;
          }
        });
        //择偶有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.mateSelection.existsChildren == item.label) {
            this.mateSelection.existsChildren = item.value;
          }
        });
        //是否想要孩子
        this.wantChildrens.forEach((item) => {
          if (data.user.wantChildren == item.label) {
            this.info.wantChildren = item.value;
            console.log("0是否想要孩子0", this.info.wantChildren);
          }
        });
        this.wantChildrens.forEach((item) => {
          if (data.user.mateSelection.wantChildren == item.label) {
            this.mateSelection.wantChildren = item.value;
          }
        });
        //何时结婚
        this.marriedTimes.forEach((item) => {
          if (data.user.marriedTime == item.label) {
            this.info.marriedTime = item.value;
          }
        });
        //是否买车
        this.buyCarStatus.forEach((item) => {
          if (data.user.buyCarStatus == item.label) {
            this.info.buyCarStatus = item.value;
          }
        });
        //住房情况
        this.houseStatus.forEach((item) => {
          if (data.user.houseStatus == item.label) {
            this.info.houseStatus = item.value;
          }
        });
        //吸烟
        this.doSmakes.forEach((item) => {
          if (data.user.doSmake == item.label) {
            this.info.doSmake = item.value;
          }
        });
        this.doSmakess.forEach((item) => {
          if (data.user.mateSelection.doSmake == item.label) {
            this.mateSelection.doSmake = item.value;
          }
        });
        // 喝酒
        this.doDrinks.forEach((item) => {
          if (data.user.doDrink == item.label) {
            this.info.doDrink = item.value;
          }
        });
        this.doDrinkss.forEach((item) => {
          if (data.user.mateSelection.doDrink == item.label) {
            this.mateSelection.doDrink = item.value;
          }
        });
        //学历
        this.educations.forEach((item) => {
          if (data.user.mateSelection.education == item.label) {
            this.mateSelection.education = item.value;
          }
        });
        this.educations.forEach((item) => {
          if (data.user.education == item.label) {
            this.education = item.value;
            this.info.education = item.value;
          }
        });
        //婚姻基本信息
        this.marriages.forEach((item) => {
          if (data.user.maritalStatus == item.label) {
            this.maritalStatus = item.value;
            this.info.maritalStatus = item.value;
          }
        });
        //婚姻择偶
        this.marriages.forEach((item) => {
          if (data.user.mateSelection.maritalStatus == item.label) {
            this.mateSelection.maritalStatus = item.value;
          }
        });
        let mateSelectionLL = {
          ageMax: this.mateSelection.ageMax,
          ageMin: this.mateSelection.ageMin,
          shape: this.mateSelection.shape,
          monthIncomeMin: this.mateSelection.monthIncomeMin,
          monthIncomeMax: this.mateSelection.monthIncomeMax,
          maritalStatus: this.mateSelection.maritalStatus,
          isAvatar: this.mateSelection.isAvatar,
          heightMin: this.mateSelection.heightMin,
          heightMax: this.mateSelection.heightMax,
          existsChildren: this.mateSelection.existsChildren,
          education: this.mateSelection.education,
          doSmake: this.mateSelection.doSmake,
          doDrink: this.mateSelection.doDrink,
          wantChildren: this.mateSelection.wantChildren,
          workAddrCityId: this.mateSelection.workAddrCityId,
          workAddrCityName: this.mateSelection.workAddrCityName,
          workAddrDistrictId: this.mateSelection.workAddrDistrictId,
          workAddrDistrictName: this.mateSelection.workAddrDistrictName,
          workAddrProvinceId: this.mateSelection.workAddrProvinceId,
          workAddrProvinceName: this.mateSelection.workAddrProvinceName,
        };
        let aL = 0;
        for (let i in mateSelectionLL) {
          if (
            mateSelectionLL[i] == null ||
            mateSelectionLL[i] == "" ||
            mateSelectionLL == undefined
          ) {
            aL = aL + 1;
          }
        }
        if (aL == 20) {
          this.iSmateSelection = true;
        } else {
          this.iSmateSelection = false;
        }
        /**
         * 用户相册
         */
        if (data.albums) {
          data.albums.map(async (item) => {
            this.items.push(
              Object.assign({}, item, {
                imgage: ossL(item.imgUrl),
              })
            );
          });
          let newDataList = [];
          let current = 0;
          if (this.items && this.items.length > 0) {
            for (let i = 0; i <= this.items.length - 1; i++) {
              if (i % 6 !== 0 || i === 0) {
                if (!newDataList[current]) {
                  newDataList.push([this.items[i]]);
                } else {
                  newDataList[current].push(this.items[i]);
                }
              } else {
                current++;
                newDataList.push([this.items[i]]);
              }
            }
          }
          data.albums.map(async (item) => {
            this.listImg.push(ossL(item.imgUrl));
          });
          this.items = [...newDataList];
          if (this.items.length == 0) {
            this.isImg = false;
          } else {
            this.isImg = true;
          }
        }
      }
    },
    handleClickBig(tab) {
      this.total = 0;
      this.cHeight();
      if (tab.name == "one") {
        this.ViewBrowingA();
      } else if (tab.name == "two") {
        this.FollowA();
      } else if (tab.name == "three") {
        this.LikeA();
      } else if (tab.name == "four") {
        this.commentA();
      }
    },
    handleClickOne(tab) {
      this.total = 0;

      this.pageNum = 1;
      if (tab.name == "first") {
        this.ViewBrowingA();
      } else if (tab.name == "second") {
        this.ViewBrowingB();
      }
    },
    handleClickTwo(tab) {
      this.total = 0;
      this.pageNum = 1;
      if (tab.name == "firstA") {
        this.FollowA();
      } else if (tab.name == "secondA") {
        this.FollowB();
      }
    },
    handleClickThree(tab) {
      this.total = 0;
      this.pageNum = 1;
      if (tab.name == "firstB") {
        this.LikeA();
      } else if (tab.name == "secondB") {
        this.LikeB();
      }
    },
    handleClickFour(tab) {
      this.total = 0;
      this.pageNum = 1;
      // this.cHeight()
      if (tab.name == "firstC") {
        this.commentA();
      } else if (tab.name == "secondC") {
        this.commentB();
      }
    },

    /**
     * 看过
     */
    async ViewBrowingA() {
      // this.listA = [];
      try {
        this.loading = true;
        const { code, data } = await memberViewBrowingPage({
          objId: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: 10,
          viewType: 1,
        });
        if (code == 0) {
          this.loading = false;
          this.listA = data.records;
          this.total = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    async ViewBrowingB() {
      const { code, data } = await memberViewBrowingPage({
        memberId: this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: 10,
        viewType: 0,
      });
      if (code == 0) {
        this.listB = data.records;
        this.total = data.total;
      }
    },
    /**
     * 关注
     */
    async FollowA() {
      try {
        this.loading = true;
        const { code, data } = await memberFollowPage({
          followMemberId: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: 10,
        });
        if (code == 0) {
          this.loading = false;
          this.listC = data.records;
          this.total = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    async FollowB() {
      const { code, data } = await memberFollowPage({
        memberId: this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: 10,
      });
      if (code == 0) {
        this.listD = data.records;
        this.total = data.total;
      }
    },
    /**
     * 赞过
     */
    async LikeA() {
      const { code, data } = await memberLikePage({
        likeMemberId: this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: 10,
      });
      if (code == 0) {
        console.log(data);
        this.listE = data.records;
        this.total = data.total;
      }
    },
    async LikeB() {
      const { code, data } = await memberLikePage({
        memberId: this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: 10,
      });
      if (code == 0) {
        this.listF = data.records;
        this.total = data.total;
      }
    },

    /**
     * 评论
     */
    async commentA() {
      try {
        this.loading = true;
        const { code, data } = await memberCommentPage({
          pMemberId: this.$route.query.id,
          pageNum: this.pageNum,
          pageSize: 10,
        });
        if (code == 0) {
          this.loading = false;
          this.listG = data.records;
          this.total = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    async commentB() {
      const { code, data } = await memberCommentPage({
        memberId: this.$route.query.id,
        pageNum: this.pageNum,
        pageSize: 10,
      });
      if (code == 0) {
        this.listH = data.records;
        this.total = data.total;
      }
    },

    /**
     * 分页
     */
    handlePageChange(val) {
      this.pageNum = val;
      this.ViewBrowingA();
      //  this.commentB();
    },
    handlePageChangeA(val) {
      this.pageNum = val;
      this.ViewBrowingB();
    },
    handlePageChangeB(val) {
      this.pageNum = val;
      this.FollowA();
    },
    handlePageChangeC(val) {
      this.pageNum = val;
      this.FollowB();
    },
    handlePageChangeD(val) {
      this.pageNum = val;
      this.LikeA();
    },
    handlePageChangeE(val) {
      this.pageNum = val;
      this.LikeB();
    },
    handlePageChangeF(val) {
      this.pageNum = val;
      this.commentA();
    },
    handlePageChangeG(val) {
      this.pageNum = val;
      this.commentB();
    },
    /**
     * 内心独白省略(处理)
     */
    getRem() {
      const defaultRem = 16;
      let winWidth = window.innerWidth;
      console.log("winWidth:" + winWidth);
      let rem = (winWidth / 375) * defaultRem;
      return rem;
    },
    showTotalIntro() {
      console.log(this.showTotal);
      this.showTotal = !this.showTotal;
      this.exchangeButton = !this.exchangeButton;
    },
  },
  watch: {
    introduce: function () {
      this.$nextTick(
        function () {
          // console.log("nextTick");
          // 判断介绍是否超过四行
          let rem = parseFloat(this.getRem());
          // console.log("watch 中的rem", rem);
          if (!this.$refs.desc) {
            // console.log("desc null");
            return;
          }
          let descHeight = window
            .getComputedStyle(this.$refs.desc)
            .height.replace("px", "");
          // console.log("descHeight:" + descHeight);
          // console.log("如果 descHeight 超过" + 1.25 * rem + "就要显示展开按钮");
          if (descHeight > 1.25 * rem) {
            // console.log("超过了四行");
            // 显示展开收起按钮
            this.showExchangeButton = true;
            this.exchangeButton = true;
            // 不是显示所有
            this.showTotal = false;
          } else {
            // 不显示展开收起按钮
            this.showExchangeButton = false;
            // 没有超过四行就显示所有
            this.showTotal = true;
            // console.log("showExchangeButton", this.showExchangeButton);
            // console.log("showTotal", this.showTotal);
          }
        }.bind(this)
      );
    },
    $route(newValue, oldValue) {
      console.log("ss", oldValue);
      // this.setTags(newValue);
      this.$router.go(0);
    },
    index() {
      this.cHeight();
    },
  },
};
</script>

<style lang="less" scoped>
.MemberInfo {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  /**
  会员信息
  */
  .MemberInfo_left {
    flex: 1;
    background-color: #fff;
    margin-right: 20px;
    height: 100%;
    min-width: 810px;
    .MemberInfo_left_top {
      // background-color: #000;
      // height: 182px;
      .MemberInfo_left_top-top {
        background-color: #5faef7;
        height: 90px;
        display: flex;
        align-items: flex-end;
        padding: 0px 30px;
        justify-content: space-between;
        padding-bottom: 8px;
        .MemberInfo_left_top-top-left {
          margin-left: 174px;
          .MemberInfo_left_top-top-left_N_T {
            display: flex;
            p {
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
              line-height: 22px;
            }
            .MemberInfo_left_top-top-left_icon {
              display: flex;
              align-items: center;
              margin-left: 8px;
              img {
                width: 14px;
                height: 14px;
                margin: 0px 5px;
              }
            }
          }
          .MemberInfo_left_top-top-left_id {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 17px;
          }
          .MemberInfo_left_top-top-left_label {
            font-size: 14px;
            color: #ffffff;
          }
        }
        .MemberInfo_left_top-top-right {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #ffffff;
          line-height: 20px;
          img {
            width: 16px;
            height: 15px;
            margin-right: 7px;
          }
        }
      }
      .MemberInfo_left_top-bottom {
        padding: 0px 30px;
        position: relative;
        .MemberInfo_left_top-bottom_img {
          width: 162px;
          height: 162px;
          position: absolute;
          top: -70px;
          background-color: aliceblue;
          img {
            width: 162px;
            height: 162px;
            border-radius: 4px;
          }
        }
        /**
        轮播
        */
        .info-info-lunbo {
          display: flex;
          margin-left: 172px;
          height: 92px;
          padding-top: 12px;
          box-sizing: border-box;
          width: 600px;
          /deep/ .el-carousel__arrow {
            display: block !important;
            position: relative;
          }
          /deep/ .el-carousel__arrow--left {
            left: -40px;
            left: -38px;
            font-size: 20px !important;
            background-color: rgba(255, 255, 255, 0);
            i {
              color: #666;
            }
          }
          /deep/ .el-carousel__arrow--right {
            position: absolute;
            right: 0px;
            font-size: 20px !important;
            background-color: rgba(255, 255, 255, 0);
            i {
              color: #666;
            }
          }
          /deep/ .el-carousel {
            padding-left: 30px;
          }
          /deep/ .el-carousel__arrow:hover {
            background-color: none;
          }

          .el-car-item {
            display: flex;
            .top-img {
              width: 80px;
              height: 80px;
              margin-right: 10px;
              display: flex;
              align-items: flex-end;
              background-color: #feeff0;
              /deep/ .el-image__inner {
                cursor: url(../../../assets/images/big.ea3f1e7.png), pointer;
              }
            }
          }
        }
        .no-photo {
          display: flex;
          height: 92px;
          font-size: 14px;
          margin-left: 172px;
          color: #999;
          align-items: center;
          .icon {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            width: 13px;
            height: 13px;
            background: #fff;
            border: 1px solid #c6c6c6;
            border-radius: 50%;
          }
          .icon::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 5px;
            height: 5px;
            background: #d8d8d8;
            border-radius: 50%;
          }
        }
      }
    }
    .MemberInfo_left_bottom {
      // height: 300px;
      padding-top: 40px;
      padding-left: 30px;
      padding-right: 30px;
      background-color: #fff;
      // box-sizing: border-box;
      .MemberInfo_left_bottom-tab {
        display: flex;
        align-items: center;
        height: 50px;
        .default-tab {
          font-size: 14px;
          color: #67b1fd;
          line-height: 20px;
          margin-right: 40px;
          cursor: pointer;
          opacity: 0.6;
        }
        .active-tab {
          font-size: 18px;
          font-weight: bold;
          color: #67b1fd;
          line-height: 25px;
          margin-right: 40px;
          opacity: 1;
        }
      }
      /**
      我的主页
      */
      .MemberInfo_left_bottom-HomePage {
        padding-top: 30px;
        padding-bottom: 10px;
        // height: 100%;
        .info-zi {
          display: flex;
          justify-content: space-between;
          position: relative;
          .zi-left {
            margin-right: 20px;
            position: relative;
            box-sizing: border-box;
            .left-box {
              position: relative;
              p {
                font-size: 16px;
                margin-left: 12px;
                font-weight: bold;
                color: #333333;
              }
              p::before {
                content: "";
                width: 4px;
                height: 18px;
                position: absolute;
                top: 2px;
                left: 0px;
                // left: -6px;
                background: #67b1fd;
              }
              .box1 {
                padding: 10px;
                text-align: justify;
                min-height: 50px;
                display: flex;
                align-items: center;
                text-indent: 2em;
                color: #666;
              }
              .box2 {
                margin-bottom: 18px;
                margin-top: 10px;
                .info_div {
                  display: flex;
                  flex-wrap: wrap;
                  .m-btn {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    padding: 0 20px;
                    background: #f2f2f2;
                    color: #666;
                    border-radius: 15px;
                    margin-left: 10px;
                    margin-top: 10px;
                  }
                  .purple {
                    background: #feeff0ff;
                  }
                }
                .info_box {
                  margin-top: 10px;
                  display: flex;
                  flex-wrap: wrap;
                  .m-btn {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    padding: 0 20px;
                    background: #f2f2f2;
                    border-radius: 15px;
                    margin-left: 10px;
                    margin-top: 10px;
                  }
                  .purple {
                    background: #eaebffff;
                  }
                }
              }
              .box3 {
                margin: 18px 0px;
                margin-left: 10px;
                min-height: 100px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                p {
                  font-size: 14px;
                  color: #666;
                  // margin-left: 50px;
                  min-width: 200px;
                }
                // :nth-child(1) {
                //   margin-left: 10px;
                // }
                // p:last-child {
                //   margin-left: 10px;
                // }
                span {
                  color: #333;
                }
                // .jjj {
                //   width: 500px;
                // }
              }
              .box4 {
                margin-top: 10px;
                min-height: 45px;
                // background-color: aquamarine;
                display: flex;
                flex-wrap: wrap;
                .m-btn {
                  display: inline-block;
                  height: 28px;
                  line-height: 28px;
                  padding: 0 20px;
                  background: #f5f5f5;
                  color: #666;
                  border-radius: 15px;
                  margin-left: 10px;
                  margin-top: 10px;
                }
                .purple {
                  background: #f5f5f5;
                }
              }

              /**
              隐藏内心独白
              */
              .detailed-introduce {
                font-size: 14px;
                color: #434343;
                position: relative;
                overflow: hidden;
                margin: 10px;
                .intro-content {
                  // 最大高度设为4倍的行间距
                  max-height: 84px;
                  line-height: 21px;
                  word-wrap: break-word;
                  /*强制打散字符*/
                  word-break: break-all;
                  background: #ffffff;
                  /*同背景色*/
                  color: #ffffff;
                  overflow: hidden;
                  .merchant-desc {
                    width: 100%;
                    line-height: 21px;
                  }

                  &:after,
                    // 这是展开前实际显示的内容
                  &:before {
                    content: attr(title);
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    color: #434343;

                    // overflow: hidden;
                  }
                  // 把最后最后一行自身的上面三行遮住
                  &:before {
                    display: block;
                    overflow: hidden;
                    z-index: 1;
                    max-height: 63px;
                    background: #ffffff;
                    text-align: justify;
                  }
                  &:after {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    height: 81px;
                    /*截取行数*/
                    -webkit-line-clamp: 4;
                    text-overflow: ellipsis;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    /*行首缩进字符数，值为[(截取行数-1)*尾部留空字符数]*/
                    text-indent: -12em;
                    /*尾部留空字符数*/
                    padding-right: 4em;
                  }
                  .unfold {
                    z-index: 11;
                    width: 40px;
                    height: 21px;
                    outline: 0;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    cursor: pointer;
                    // text-align: justify;
                    p {
                      margin: 0;
                      line-height: 21px;
                      // color: #7fbe87;
                      font-size: 14px;
                      color: #999;
                      text-align: right;
                    }
                    p::before {
                      content: "";
                      width: 0px;
                      //   width: 4px;
                      //    height: 18px;
                      //  position: absolute;
                      //    top: 2px;
                      //     left: -6px;
                      //    background: #67b1fd;
                    }
                  }
                }
              }
              .total-introduce {
                height: auto;
                overflow: hidden;
                font-size: 14px;
                color: #434343;
                margin: 10px;
                text-align: justify;
                .intro-content {
                  .merchant-desc {
                    width: 100%;
                    line-height: 21px;
                  }
                }
                .unfold {
                  display: block;
                  z-index: 11;
                  float: right;
                  width: 40px;
                  height: 21px;
                  cursor: pointer;
                  p {
                    margin: 0;
                    line-height: 21px;
                    // color: #7fbe87;
                    font-size: 14px;
                    color: #999;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
        .left_bottom-HomePage_height {
          overflow: auto;
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          // width:12px;
          width: 8px;
          // background-color: aqua;
        }

        /* 滚动槽 */
        ::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #ececec;
        }
      }
      /**
      操作日志
      */
      .MemberInfo_left_bottom-OperationLog {
        // height: 400px;
        // background-color: aqua;
        padding-top: 30px;
        padding-bottom: 10px;
        // .left_bottom-OperationLog {

        // }
        .left_bottom-OperationLog_height {
          overflow: auto;
          // background-color: #409eff;
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          // width:12px;
          width: 8px;
          // background-color: aqua;
        }

        /* 滚动槽 */
        ::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background: #ececec;
        }
      }
    }
  }
  /**
  会员赞过,看过,评论,关注数据
  */
  .MemberInfo_right {
    flex: 1;
    // background-color: bisque;
    // background-color: #fff;
    // height: 100%;
    /**
     选项卡设置
     */
    /deep/ .el-tabs {
      height: 100%;
      // background-color: #fff;
      box-shadow: 0 0 0 0;
      border: none;
    }
    /deep/ .el-tabs--border-card > .el-tabs__header {
      background-color: #fff;
      border-bottom: 1px solid #409eff !important;
    }
    /deep/ .el-tabs__nav {
      padding-top: 1px;
    }
    /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      border-top-color: #409eff;
      border-right-color: #409eff;
      border-left-color: #409eff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 0px;
    }
    /deep/ .el-tabs__content {
      padding: 0px;
    }
    /deep/ .MemberInfo_right-tab > .el-tabs__header {
      padding: 15px;
      margin: 0px;
    }
    // /deep/.el-tabs>.el-tabs__content> .MemberInfo_right-tab_c{
    //   height: 100%;
    // }
    /**
    内容设置
    */
    .MemberInfo_right-v {
      // height: 600px;
      // height: 634px;
      overflow: auto;
      // overflow-x: scroll;
      //       overflow-y: scroll;
    }
    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
      // width:12px;
      width: 8px;
      // background-color: aqua;
    }

    /* 滚动槽 */
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #ececec;
    }
    .MemberInfo_right-common {
      // width: 100%;
      display: flex;
      // height: 128px;
      height: 120px;
      padding-left: 20px;
      border-bottom: 1px solid #ececec;
      box-sizing: border-box;
      align-items: center;
      // box-sizing: border-box;
      .MemberInfo_right-common-left {
        width: 80px;
        height: 80px;
        img {
          width: 80px;
          height: 80px;
        }
        margin-right: 12px;
      }
      .MemberInfo_right-common-right {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .MemberInfo_right-common-right-icon {
          display: flex;
          align-items: center;
          .common-right-ft {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
          img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
          }
          .common-right-icon_left {
            padding: 2px 6px;
            background-color: #dfd6fe;
            color: #9579fe;
            margin-left: 5px;
          }
          .common-right-icon_right {
            padding: 2px 6px;
            background-color: #c5f3ee;
            color: #40d9c7;
            margin-left: 5px;
          }
        }
        .MemberInfo_right-common-right-label {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
        .MemberInfo_right-common-right-time {
          font-size: 14px;
          color: #adadad;
          line-height: 20px;
        }
      }
    }
  }
  // .MemberInfo_right_height{
  //   height: 100%;
  // }
}
</style>