<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.searchCond"
          placeholder="也在币使用记录"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="memberId" label="会员ID"></el-table-column>
        <el-table-column prop="createTime" label="被关注人ID"></el-table-column>
        <el-table-column
          prop="createTime"
          label="被关注人昵称"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="被关注人性别"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="被关注人年龄"
        ></el-table-column>
        <el-table-column prop="createTime" label="关注时间"></el-table-column>

        <!-- <el-table-column prop="serviceFlag" label="服务标记"></el-table-column> -->
        <!-- <el-table-column
          prop="targetId"
          label="目标ID"
          :formatter="sfktFormate"
        ></el-table-column> -->
        <!-- <el-table-column
          prop="serviceFlag"
          label="服务标记"
          :formatter="marr"
        ></el-table-column> -->
        <!-- <el-table-column prop="status" label="服务状态"></el-table-column> -->

        <!-- width="100" -->
        <el-table-column label="被关注人昵称" class="table-hand">
          <template slot-scope="scope">
            <p style="text-align: center">
              <span v-if="scope.row.status == '0'">正常</span>
              <span v-if="scope.row.status == '1'">停用</span>
            </p>
          </template>
        </el-table-column>

        <!-- :prop="" -->
        <!-- <el-table-column
          prop="status"
          label="状态"
          width="55"
        ></el-table-column> -->

        <!-- width="180" -->

        <el-table-column label="操作" class="table-hand" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="账号状态">
          <el-select
            v-model="form.status"
            placeholder="状态"
            class="handle-select mr10"
          >
            <el-option key="0" label="正常" value="0"></el-option>
            <el-option key="1" label="停用" value="1"></el-option>
          </el-select>
          <!-- <el-input v-model="form.name"></el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { memberFollowPage } from "@/api/member/member.js";

export default {
  name: 'also',
  data() {
    return {
      query: {
        searchCond: "",
        pageSize: 10,
        pageNum: 0,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取会员服务申请信息
    async getData() {
      let d = {
        // viewType: 1,
        cond: this.query.searchCond,
        pageSize: this.query.pageSize,
        pageNum: this.query.pageNum,
      };
      const { code, data } = await memberFollowPage(d);
      if (code == 0) {
        console.log(data, "关注");
        this.tableData = data;
      }
    },
    power(item) {
      console.log(item, "点击当前用");
      // if (this.state == 1) {
      //   this.form.delivery == false;
      // } else {
      //   this.form.delivery == true;
      // }
    },

    // 触发搜索按钮
    async handleSearch() {
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      console.log("ss", row, index);
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
          //调用接口
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },
    delAllSelection() {
      // const length = this.multipleSelection.length;
      // let str = '';
      // this.delList = this.delList.concat(this.multipleSelection);
      // for (let i = 0; i < length; i++) {
      //     str += this.multipleSelection[i].name + ' ';
      // }
      // this.$message.error(`删除了${str}`);
      // this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      console.log("ss", index, row);
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      console.log("当前页", val);
      this.query.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  /* width: 100%; */
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.table-hand {
  flex: 1;
}
</style>
