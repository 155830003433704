<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="newly"
          >新增</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        :key="Math.random()"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column prop="shopName" label="门店名称"></el-table-column>
        <el-table-column prop="shopAddr" label="门店地址"></el-table-column>
        <!-- <el-table-column
          prop="shopIntroduce"
          label="门店简介"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="handleDetails(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑"
      :visible.sync="editVisible"
      @close="onPermissionsDialogClose"
      width="30%"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="门店名称">
          <el-input v-model="form.shopName" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="门店地址">
          <el-input v-model="form.shopAddr" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input
            v-model="form.lat"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            class="w100"
          ></el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input
            v-model="form.lon"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            class="w100"
          ></el-input>
        </el-form-item>

        <el-form-item label="门店简介">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.shopIntroduce"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="服务电话">
          <el-input v-model="form.phone" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="服务时间">
          <el-input v-model="form.workTime" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onPermissionsDialogClose">取 消</el-button>
        <el-button type="primary" @click="saveEditaCatUpdate">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增弹出框 -->
    <el-dialog
      title="新增"
      :visible.sync="newlya"
      width="500px"
      @close="onPermissionsDialogClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="门店名称">
          <el-input v-model="form.shopName" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="门店地址">
          <el-input v-model="form.shopAddr" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input
            v-model="form.lat"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            class="w100"
          ></el-input>
        </el-form-item>

        <el-form-item label="经度">
          <el-input
            v-model="form.lon"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            class="w100"
          ></el-input>
        </el-form-item>
        <el-form-item label="门店简介">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.shopIntroduce"
          >
          </el-input>
        </el-form-item>
        <div class="cion">
          <el-form-item label="门店图片"> </el-form-item>
          <el-upload
            action
            :http-request="UpLoad"
            list-type="picture-card"
            :limit="numLL"
            :file-list="fileList"
            :on-success="handleAvatarSuccess2"
            :on-remove="handleRemoveLL"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <el-form-item label="服务电话">
          <el-input v-model="form.phone" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="服务时间">
          <el-input v-model="form.workTime" class="w100"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onPermissionsDialogClose">取 消</el-button>
        <el-button type="primary" @click="saveEdita">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { shopPage, shopAdd, shopDel, shopUpdate } from "@/api/store/store.js";
import { client, getFileNameUUID } from "@/api/common/oss/oss.js"; //client,getFileNameUUID是前面的oss.js文件内的两个封装函数
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";

import env from "@/api/env.js";
export default {
  data() {
    return {
      query: {},
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      newlya: false,
      pageTotal: 0,
      form: {
        lat: "",
        lon: "",
        phone: "",
        remark: "",
        shopAddr: "",
        shopIntroduce: "",
        shopName: "",
        workTime: "",
      },
      token: "",
      state: "",
      idx: -1,
      id: -1,
      loading: false,
      numLL: 1,
      fileList: [],
      fileImg: "",
      jubaoImg: [],
    };
  },
  created() {
    this.getData();
    this.tkL();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { code, data } = await shopPage({
          pageNum: 1,
          pageSize: 10,
        });
        if (code == 0) {
          this.loading = false;
          this.tableData = data.records;
          this.pageTotal = data.total;
        }
      } finally {
        this.loading = false;
      }
    },
    newly() {
      this.newlya = true;
    },
    //上传相册
    UpLoad(file) {

      const isLtSize = file.file.size / 1024 / 1024 < 2;
      const isJPG =
        file.file.type === "image/jpg" ||
        file.file.type === "image/png" ||
        file.file.type === "image/jpeg";
      if (!isLtSize) {
        return this.$message({
          message: "上传图片大小不能超过 2MB!",
          type: "error",
          offset: 100,
        });
      }
      if (!isJPG) {
        setTimeout(function () {
          window.location.reload(); //02
        }, 700);
        return this.$message({
          message: "上传图片只能为jpg或png格式",
          type: "error",
          offset: 100,
        });
      }

      //-------------
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        // that.dataObj
        let fileName = getFileNameUUID() + "." + fileFormat;
        client()
          .multipartUpload(
            // process.env.NODE_ENV === "production"
            //   ? `prod/back/StorePicture/${fileName}`
            //   : `dev/back/StorePicture/${fileName}`,
            `${env.uploadUrlStorePicture}${fileName}`,
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileList.push({
              name: res.name,
              url: client().signatureUrl(res.name),
            });
            // that.fileList.push(res.name);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    handleAvatarSuccess2(file) {
      console.log("成功", file);
    },
    //删除数据(图片)
    removeByValue(arr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    //删除图片
    removeByValueLL(arr, value) {
      arr.forEach((item, i) => {
        if (item == value) {
          arr.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
        }
      });
    },
    async handleRemoveLL(file) {
      console.log("0", file);
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b;
      if (String(file.name).match(new RegExp("^/.*$"))) {
        b = a.substring(tempL + 4, a.length);
      } else {
        b = a.substring(tempL + 5, a.length);
      }
      this.removeByValue(this.fileList, b);
      // this.removeByValueLL(this.jubaoImg, b);
    },
    handleDelete(index, row) {
      console.log("ss", row, index);
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let a = {
            shopId: row.id,
          };
          const { code, data } = await shopDel(a);
          if (code == 0) {
            this.$message.success("删除成功");
            console.log(data);
            this.getData();
            // this.tableData.splice(index, 1);
            this.tkL();
          } else {
            this.$message.warning("删除失败");
            this.tkL();
          }
        })
        .catch(() => {});
    }, // 编辑操作
    async handleDetails(index, row) {
      console.log(index, row);
      this.editVisible = true;
      this.form = JSON.parse(JSON.stringify(row));

      // this.$router.push({ path: "/storeDetails", query: row });
    },
    handlePageChange() {},
    async saveEdita() {
      this.tkL();
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.jubaoImg.indexOf(this.fileList[i]) < 0)
          this.jubaoImg.push(this.fileList[i].name);
      }
      this.fileImg = this.jubaoImg.join(",");
      const { code, msg } = await shopAdd({
        lat: this.form.lat,
        lon: this.form.lon,
        phone: this.form.phone,
        remark: this.form.remark,
        shopAddr: this.form.shopAddr,
        shopImg: this.fileImg,
        shopIntroduce: this.form.shopIntroduce,
        shopName: this.form.shopName,
        status: 0,
        workTime: this.form.workTime,
      });
      if (code == 0) {
        this.$message.success("添加成功");
        this.form = {};
        this.fileList = [];
        this.fileImg = "";
        this.newlya = false;
        this.getData();
      } else {
        this.$message.error(msg);
        this.getData();
        this.fileImg = "";
        this.fileList = [];
        // this.form = {};
        // this.fileList = [];
        // this.newlya = false;
      }
    },
    async fn() {},
    onPermissionsDialogClose() {
      this.form = {};
      this.fileImg = "";
      this.fileList = [];
      this.newlya = false;
      this.editVisible = false;
      this.getData();
    },

    async saveEditaCatUpdate() {
      this.tkL();
      const { code, msg } = await shopUpdate({
        id: this.form.id,
        lat: this.form.lat,
        lon: this.form.lon,
        phone: this.form.phone,
        remark: this.form.remark,
        shopAddr: this.form.shopAddr,
        // shopImg: this.fileImg,
        shopIntroduce: this.form.shopIntroduce,
        shopName: this.form.shopName,
        status: 0,
        workTime: this.form.workTime,
        shopImg: this.form.shopImg,
      });
      if (code == 0) {
        this.$message.success("修改成功");
        this.tkL();
        this.editVisible = false;
        this.getData();
      } else {
        this.$message.error(msg);
        this.tkL();
        // this.getData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  margin-top: 10px;
}
.red {
  color: #ff0000;
}
.blue {
  color: blue;
}
.w100 {
  width: 200px;
}
// .newlya{
//   // width: 500px;
//   width: 50%;
// }
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.cion {
  display: flex;
  margin-bottom: 10px;
}
</style>