<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="newly()"
          >新增</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          prop="categoryName"
          label="商品分类名称"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="goodName"
          label="商品名称"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="数量" align="center">
          <template slot-scope="scope">
            <!-- <p>{{ Number(scope.row.goodNum) / 100 }}</p> -->
            <p>{{ Number(scope.row.goodNum) }}</p>
          </template>
        </el-table-column>

        <el-table-column label="单价" align="center">
          <template slot-scope="scope">
            <p>{{ Number(scope.row.goodPrice) }} {{ scope.row.goodUnit }}</p>
          </template>
        </el-table-column>

        <el-table-column label="账号状态" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          prop="goodDesc"
          label="商品描述"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="goodIntroduce"
          label="商品介绍"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="操作"
          width="300px"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >
              编辑</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="新增"
      :visible.sync="editVisible"
      width="35%"
      @close="onPermissionsDialogClose"
    >
      <el-form ref="form" :model="form" label-width="70px" :rules="rules">
        <el-form-item label="商品分类" prop="categoryId">
          <template>
            <el-select
              v-model="form.categoryId"
              placeholder="请选择"
              class="aaa"
            >
              <el-option
                v-for="item in options"
                :key="Number(item.id)"
                :label="item.categoryName"
                :value="Number(item.id)"
              >
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodName">
          <el-input v-model="form.goodName"></el-input>
        </el-form-item>
        <el-form-item label="商品数量">
          <el-input-number
            v-model="form.goodNum"
            :min="1"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>

        <!-- oninput="value=value.replace(/[^0-9.]/g,'')" -->
        <el-form-item label="商品单价" prop="goodPrice">
          <el-input v-model="form.goodPrice"></el-input>
        </el-form-item>
        <el-form-item label="商品描述" prop="goodDesc">
          <el-input v-model="form.goodDesc"></el-input>
        </el-form-item>
        <el-form-item label="商品介绍" prop="goodIntroduce">
          <el-input v-model="form.goodIntroduce"></el-input>
        </el-form-item>
        <el-form-item label="商品标识" prop="goodKey">
          <el-input v-model="form.goodKey"></el-input>
        </el-form-item>
        <el-form-item label="商品状态">
          <el-select v-model="form.status" placeholder="商品状态">
            <el-option key="0" label="正常" value="0"></el-option>
            <el-option key="1" label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="显示顺序">
          <el-input-number
            v-model="form.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="苹果产品">
          <el-input v-model="form.productId"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="goodAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//
import { goodPage } from "@/api/store/store.js";
import {
  newcommodity,
  delgoods,
  goodstatus,
  goodlist,
  goodUpdate,
} from "@/api/store/store.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  name: "basetable",
  data() {
    const checkDeptTel = (rule, value, callback) => {
      console.log("_", rule);
      const pattern =
        /(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/;
      if (value !== "") {
        if (pattern.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确价格"));
        }
      } else {
        callback(new Error("请输入价格"));
      }
    };
    return {
      query: {
        address: "",
        id: "",
        pageNum: 1,
        pageIndex: 1,
        pageSize: 10,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {
        categoryId: "",
        goodDesc: "",
        goodName: "",
        goodPrice: "",
        goodNum: 1,
        goodIntroduce: "",
        goodKey: "",
        orderNum: 0,
        status: "0",
        goodUnit: "元",
        remark: "",
        productId: "", //苹果产品id
      },
      value: "",
      options: [],
      idx: -1,
      id: -1,
      rules: {
        categoryId: [
          { required: true, message: "商品分类为空", trigger: "blur" },
        ],
        goodDesc: [
          { required: true, message: "商品描述为空", trigger: "blur" },
        ],
        goodIntroduce: [
          { required: true, message: "商品介绍为空", trigger: "blur" },
        ],
        goodName: [
          { required: true, message: "商品昵称为空", trigger: "blur" },
        ],
        goodPrice: [
          { required: true, validator: checkDeptTel, trigger: "blur" },
        ],
        goodKey: [{ required: true, message: "商品标识为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 获取 easy-mock 的模拟数据
    async getData() {
      let a = {
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      };
      const { code, data } = await goodPage(a);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    async newly() {
      this.editVisible = true;
      // let a = {};
      // const { code, data } = await goodlist(a);
      // if (code == 0) {
      //   this.options = data;
      // }
      this.godlist();
    },
    async godlist() {
      const { code, data } = await goodlist();
      if (code == 0) {
        this.options = data;
      }
    },
    handleUpdate(row) {
      this.editVisible = true;
      console.log("__r", row);
      this.form = JSON.parse(JSON.stringify(row));
      this.godlist();
    },
    onPermissionsDialogClose() {
      this.form = {
        categoryId: "",
        goodDesc: "",
        goodName: "",
        goodPrice: "",
        goodNum: 1,
        goodIntroduce: "",
        goodKey: "",
        orderNum: 0,
        status: "0",
        goodUnit: "元",
        remark: "",
        productId: "", //苹果产品id
        id: "",
      };
    },
    goodAdd() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) return false;
        if (!this.form.id) {
          this.addList();
        } else {
          this.updateGod();
        }
      });
    },
    async addList() {
      this.tkL();
      const { code, msg } = await newcommodity({
        categoryId: this.form.categoryId,
        goodDesc: this.form.goodDesc,
        goodName: this.form.goodName,
        goodNum: this.form.goodNum,
        goodIntroduce: this.form.goodIntroduce,
        goodKey: this.form.goodKey,
        orderNum: this.form.orderNum,
        status: this.form.status,
        remark: this.form.remark,
        productId: this.form.productId,
        goodPrice: this.form.goodPrice,
        goodUnit: "元",
      });
      if (code == 0) {
        this.getData();
        this.tkL();
        this.$message.success("添加商品成功");
      } else {
        this.tkL();
        this.$message.error(msg);
      }
      this.editVisible = false;
    },
    async updateGod() {
      const { code, msg } = await goodUpdate({
        categoryId: this.form.categoryId,
        goodDesc: this.form.goodDesc,
        goodName: this.form.goodName,
        goodNum: this.form.goodNum,
        goodIntroduce: this.form.goodIntroduce,
        goodKey: this.form.goodKey,
        orderNum: this.form.orderNum,
        status: this.form.status,
        remark: this.form.remark,
        productId: this.form.productId,
        goodPrice: this.form.goodPrice,
        goodUnit: "元",
        id: this.form.id,
      });
      if (code == 0) {
        this.getData();
        this.tkL();
        this.$message.success("添加商品成功");
      } else {
        this.tkL();
        this.$message.error(msg);
      }
      this.editVisible = false;
    },
    // 商品状态
    async switchChange(e, index, row) {
      console.log(e, index, row);
      let a = {
        ids: row.id,
        status: e,
      };
      const { code, data } = await goodstatus(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.$message.success("修改商品状态成功");
        // this.$set(row, "status", e);
      } else {
        this.tkL();
        this.$message.error("修改商品状态失败");
      }
    },
    // 删除操作
    handleDelete(index, row) {
      this.tkL();
      console.log("ss", row);
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
          let a = {
            ids: row.id,
          };
          const { code, data } = delgoods(a);
          if (code == 0) {
            console.log(data, "删除成功");
            this.getData();
          }
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.aaa {
  width: 100% !important;
}
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
