// index.js
import permission from './permission'

const directives = {
  permission
}

export default {
  install(Vue) {
    // 批量注册自定义指令
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key])
    })
  }
}

