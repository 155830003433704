<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="form.status"
          placeholder="待处理"
          class="handle-select mr10"
        >
          <el-option key="0" label="待处理" value="0"></el-option>
          <el-option key="1" label="已审核" value="1"></el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- <el-table-column prop="createId" label="创建人ID"></el-table-column> -->
        <el-table-column prop="memberId" label="申请人会员ID"></el-table-column>
        <!-- <el-table-column prop="serviceFlag" label="服务标记"></el-table-column> -->
        <!-- :formatter="serve"    prop="status" -->
        <el-table-column label="服务状态">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 0">待审核</p>
            <p v-if="scope.row.status == 1">已审核</p>
          </template>
        </el-table-column>
        <el-table-column prop="targetId" label="目标会员ID"></el-table-column>
        <el-table-column label="申请时间">
          <template slot-scope="scope" v-if="scope.row.appTime">
            <p>
              {{ $moment(scope.row.appTime).format("YYYY-MM-DD HH:mm:ss") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="更新时间">
          <template slot-scope="scope" v-if="scope.row.updateTime">
            <p>
              {{ $moment(scope.row.updateTime).format("YYYY-MM-DD HH:mm:ss") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="handleDetails(scope.$index, scope.row)"
              >处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="form.pageNum"
          :page-size="form.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="处理" :visible.sync="editVisible" width="30%">
      <el-form :model="query" label-width="70px">
        <el-form-item label="状态">
          <el-select
            v-model="query.status"
            placeholder="待处理"
            class="handle-select mr10"
          >
            <el-option key="0" label="待审核" value="0"></el-option>
            <el-option key="1" label="已审核" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="query.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { serviceAppPage } from "@/api/member/member.js";
import {serviceAppUpdate} from "@/api/member/member.js"
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  data() {
    return {
      query: {
        id: "",
        serviceFlag: "",
        status: "",
        remark: "",
      },
      show: true,
      tableData: [],
      editVisible: false,
      pageTotal: 0,
      form: {
        status: "",
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    async getData() {
      let a = {
        status: this.form.status,
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
      };
      const { code, data } = await serviceAppPage(a);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
        console.log(this.tableData, "商品列表");
      }
    },
    serve(row) {
      if (row.status == 0) {
        return "待审核";
      } else if (row.status == 1) {
        return "已审核";
      }
    },
    // 获取提交令牌
    async subTk() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    // 通过审核
    async handleDetails(index, row) {
      console.log(index, row);
      this.query.id = row.id;
      this.editVisible = true;
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.form, "pageNum", 1);
      this.getData();
    },
    // 分页导航
    handlePageChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    async saveEdit() {
      this.subTk();
      let a = {
        id: this.query.id,
        serviceFlag: this.query.serviceFlag,
        remark: this.query.remark,
        status: this.query.status,
      };
      const { code, data } = await serviceAppUpdate(a);
      if (code == 0) {
        this.$message.success("修改成功");
        console.log(data, "提交申请");
        this.getData();
        this.editVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
