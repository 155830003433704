<template>
  <div>
    <!-- <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 商品分类
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="container">
      <div class="handle-box">
        <!-- <el-input
          v-model="query.id"
          placeholder="商品ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        > -->
        <el-button type="primary" icon="el-icon-plus" @click="newly()"
          >新增</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          prop="categoryName"
          label="商品分类名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="categoryKey" label="商品分类标识" align="center">
        </el-table-column>
        <el-table-column label="账号状态" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
          <template slot-scope="scope">
            <p>
              {{ $moment(scope.row.createTime).format("lll") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="handleDelete(scope.$index, scope.row)"
              align="center"
            >
              删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="新增"
      :visible.sync="newlya"
      width="30%"
      @close="onPermissionsDialogClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="商品分类名称">
          <el-input v-model="form.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="商品分类标识">
          <el-input v-model="form.categoryKey"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newlya = false">取 消</el-button>
        <el-button type="primary" @click="add()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { categoryPage } from "@/api/store/store.js";
import { newclassify, delcategory, categoryStatus } from "@/api/store/store.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        address: "",
        id: "",
        pageNum: 1,
        pageIndex: 1,
        pageSize: 10,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {
        categoryName: "",
        categoryKey: "",
      },
      idx: -1,
      id: -1,
      newlya: false,
      loading: true,
    };
  },
  created() {
    this.getData();
    this.tkL();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 获取 easy-mock 的模拟数据
    async getData() {
      let a = {
        id: this.query.id,
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      };
      const { code, data } = await categoryPage(a);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
        console.log(this.tableData, "商品列表");
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageNum", 1);
      this.getData();
    },
    newly() {
      this.newlya = true;
    },
    onPermissionsDialogClose() {
      this.form = {};
    },
    // 新增商品
    async add() {
      this.tkL();
      let a = {
        categoryName: this.form.categoryName,
        categoryKey: this.form.categoryKey,
      };
      this.form = {};
      const { code, msg } = await newclassify(a);
      if (code == 0) {
        this.$message.success("添加商品分类成功");
        this.getData();
      } else {
        this.$message.error(msg);
      }
      this.newlya = false;
    },
    // 状态
    async switchChange(e, index, row) {
      console.log(e, index, row);
      let a = {
        ids: row.id,
        status: e,
      };
      const { code, data } = await categoryStatus(a);
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.$message.success("修改商品分类状态成功");
        // this.$set(row, "status", e);
      } else {
        this.tkL();
        this.$message.error("修改商品分类状态失败");
      }
    },
    // 删除操作
    handleDelete(index, row) {
      this.tkL();
      console.log("ss", row);

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");

          this.tableData.splice(index, 1);
          let a = {
            categoryId: row.id,
          };
          const { code, data } = delcategory(a);
          if (code == 0) {
            console.log(data, "删除成功");
            this.getData();
          }
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = row;
      this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
// /deep/.el-input__inner {
//   // width: 300px;
//   // margin: -30px 0px 0px 30px;
// }
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
