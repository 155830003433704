import request from "@/utils/request.js";

//添加数据字典
export const dictAdd = ((data) => {
    return request.post('/system-server/sys/dict/add', data)
})
//删除数据字典
export const dictDel = ((data) => {
    return request.postt('/system-server/sys/dict/del', data)
})
//查询字典数
export const dictTree = ((data) => {
    return request.gettLLL('/system-server/sys/dict/tree', data)
})
//查询字典数
export const dictManageList = ((data) => {
    return request.gettLLL('/system-server/sys/dict/manage/list', data)
})
//更新字典 
export const dictUpdate = ((data) => {
    return request.post('/system-server/sys/dict/update', data)
})
//更新字典状态
export const dictUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/dict/update/status', data)
})
//---------------------------------------------------------
//系统角色分页
export const rolePage = ((data) => {
    return request.post('/system-server/sys/role/page', data)
})
//系统角色列表 
export const roleList = ((data) => {
    return request.postt('/system-server/sys/role/list', data)
})
//添加系统角色
export const roleAdd = ((data) => {
    return request.post('/system-server/sys/role/add', data)
})
//删除系统角色
export const roleDel = ((data) => {
    return request.postt('/system-server/sys/role/del', data)
})
//修改系统角色状态 
export const roleUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/role/update/status', data)
})
//设置角色权限
export const rolePerSet = ((data) => {
    return request.postt('/system-server/sys/role/per/set', data)
})
//
export const roleUpdate = ((data) => {
    return request.post('/system-server/sys/role/update', data)
})
//---------------------------------------------------
//系统用户分页
export const userPage = ((data) => {
    return request.postt('/system-server/sys/user/page', data)
})
//添加系统用户
export const userAdd = ((data) => {
    return request.post('/system-server/sys/user/add', data)
})
//删除系统用户 
export const userDel = ((data) => {
    return request.gettLLX(`/system-server/sys/user/del/${data}`)
})
//修改系统用户
export const userUpdate = ((data) => {
    return request.post('/system-server/sys/user/update', data)
})
//修改系统用户密码  
export const userUpdatePwd = ((data) => {
    return request.postt('/system-server/sys/user/update/pwd', data)
})
//修改系统用户状态
export const userUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/user/update/status', data)
})
//系统用户重置密码 
export const userRsetPwd = ((data) => {
    return request.postt('/system-server/sys/user/rset/pwd', data)
})
//修改系统用户角色 
export const userUpdateRole = ((data) => {
    return request.postt('/system-server/sys/user/update/role', data)
})
//修改系统用户所属组织机构ID 
export const userUpdateOrg = ((data) => {
    return request.postt('/system-server/sys/user/update/org', data)
})

//----------------------------------------------
//系统组织机构分页 
export const orgPage = ((data) => {
    return request.post('/system-server/sys/org/page', data)
})
//新增组织机构
export const orgAdd = ((data) => {
    return request.post('/system-server/sys/org/add', data)
})
//删除组织机构 
export const orgDel = ((data) => {
    return request.postt('/system-server/sys/org/del', data)
})
//获取组织机构信息  
export const orgInfo = ((data) => {
    return request.gettLLX(`/system-server/sys/org/info/${data}`)
})
//更新组织机构 
export const orgUpdate = ((data) => {
    return request.post('/system-server/sys/org/update', data)
})
//修改组织机构状态  
export const orgUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/org/update/status', data)
})
//组织机构列表 
export const orgList = ((data) => {
    return request.postt('/system-server/sys/org/list', data)
})
//------------------------------------------
//系统参数分页 
export const sysConfigPage = ((data) => {
    return request.postt('/system-server/sys/config/page', data)
})
//更新系统配置 json(格式)
export const sysConfigUpdate = ((data) => {
    return request.post('/system-server/sys/config/update', data)
})
//添加系统配置
export const sysConfigAdd = ((data) => {
    return request.post('/system-server/sys/config/add', data)
})
// 删除系统配置 
export const sysConfigDel = ((data) => {
    return request.postt('/system-server/sys/config/del', data)
})
//-----------------权限菜单
export const sysMenuListRecursion = ((data) => {
    return request.postt('/system-server/sys/menu/list/recursion', data)
})
//添加菜单权限
export const sysMenuAdd = ((data) => {
    return request.post('/system-server/sys/menu/add', data)
})
//删除菜单权限
export const sysMenuDel = ((data) => {
    return request.post('/system-server/sys/menu/del', data)
})

export const sysMenuList = ((data) => {
    return request.post('/system-server/sys/menu/list', data)
})

//修改菜单权限
export const sysMenuUpdate = ((data) => {
    return request.post('/system-server/sys/menu/update', data)
})