<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <!-- <el-input
          v-model="query.searchCond"
          placeholder="查询会员ID或昵称"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        > -->
        <el-button
          v-permission="'sys:emoque:add'"
          icon="el-icon-plus"
          type="primary"
          @click="Newfeelings"
          >新增问题</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column label="问题类型">
          <template slot-scope="scope">
            <p v-if="scope.row.type == '0'">情感问题</p>
            <p v-if="scope.row.type == '1'">安全测试</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column
          label="操作"
          class="table-hand"
          align="center"
          width="300px"
        >
          <template slot-scope="scope">
            <el-button
              v-permission="'sys:emoque:update'"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleQueEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-view"
              v-permission="'sys:emoque:update'"
              @click="handleDetails(scope.row)"
              >详情</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              v-permission="'sys:emoque:update'"
              @click="handleQueDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 新增情感弹出框 -->
    <el-dialog
      title="新增问题"
      :visible.sync="editVisible"
      width="30%"
      @close="onAddVisibleClose"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="formRules">
        <el-form-item label="问题" prop="content">
          <el-input v-model="form.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="form.type"
            placeholder="类型"
            clearable
            class="handle-select mr10"
          >
            <el-option key="0" label="情感问题" value="0"></el-option>
            <el-option key="1" label="安全测试" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="序列">
          <el-input-number
            v-model="form.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改情感内容 -->
    <el-dialog title="修改问题" :visible.sync="revise" width="30%">
      <el-form
        ref="form"
        :model="amend"
        label-width="100px"
        :rules="amendRules"
      >
        <el-form-item label="问题" prop="content">
          <el-input v-model="amend.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="amend.type"
            placeholder="类型"
            clearable
            class="handle-select mr10"
          >
            <el-option key="0" label="情感问题" value="0"></el-option>
            <el-option key="1" label="安全测试" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列">
          <el-input-number
            v-model="amend.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="revise = false">取 消</el-button>
        <el-button type="primary" @click="modify">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 问题详情 -->
    <el-dialog
      title="问题详情"
      :visible.sync="detailsVisible"
      @close="ondetailsVisibleClose"
    >
      <el-col style="margin-bottom: 10px">
        <el-button
          v-permission="'sys:emoque:add'"
          icon="el-icon-plus"
          type="primary"
          @click="addQueAnsVisible = true"
          >新增答案</el-button
        >
      </el-col>
      <!------------ 开始 ------->
      <el-card class="box-card" shadow="never" v-loading="loading">
        <div slot="header" class="clearfix" v-if="tableDataAns.content">
          <span class="role-span">问题:{{ tableDataAns.content }}</span>
        </div>
        <el-table :data="tableDataAns.answers" border>
          <el-table-column prop="answerKey" label="答案选项"> </el-table-column>
          <el-table-column prop="answerValue" label="答案评分">
          </el-table-column>
          <el-table-column
            prop="answerContent"
            label="答案内容"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="answerFlag" label="答案标签">
          </el-table-column>
          <el-table-column prop="answerDesc" label="答案描述">
          </el-table-column>

          <el-table-column
            label="操作"
            class="table-hand"
            align="center"
            width="200px"
          >
            <template slot-scope="scope">
              <el-button
                v-permission="'sys:emoque:update'"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleQueAnsEdit(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-permission="'sys:emoque:update'"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <!-- end -->
      <!-- </el-col> -->
    </el-dialog>

    <el-dialog
      title="问题详情"
      :visible.sync="detailsVisibleT"
      @close="ondetailsVisibleCloseT"
    >
      <el-col style="margin-bottom: 10px">
        <el-button
          v-permission="'sys:emoque:add'"
          icon="el-icon-plus"
          type="primary"
          @click="addQueAnsVisibleT = true"
          >新增答案</el-button
        >
      </el-col>
      <el-card class="box-card" shadow="never" v-loading="loading">
        <div slot="header" class="clearfix" v-if="tableDataAns.content">
          <span class="role-span">问题:{{ tableDataAns.content }}</span>
        </div>
        <el-table :data="tableDataAns.answers" border>
          <el-table-column prop="answerKey" label="答案选项"> </el-table-column>
          <el-table-column
            prop="answerContent"
            label="答案内容"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column label="答案">
            <template slot-scope="scope">
              <p v-if="scope.row.answerValue == '0'">错误</p>
              <p v-if="scope.row.answerValue == '1'">正确</p>
            </template>
          </el-table-column>
          <el-table-column prop="answerDesc" label="答案描述"  :show-overflow-tooltip="true">
          </el-table-column>

          <el-table-column
            label="操作"
            class="table-hand"
            align="center"
            width="200px"
          >
            <template slot-scope="scope">
              <el-button
                v-permission="'sys:emoque:update'"
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="handleQueAnsEditT(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-permission="'sys:emoque:update'"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDel(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-dialog>

    <!-- 新增问题答案 -->
    <el-dialog
      title="情感问题答案"
      :visible.sync="addQueAnsVisible"
      width="30%"
      @close="onaddQueAnsVisibleClose"
    >
      <el-form
        ref="addQueAnsRules"
        :model="addQueAns"
        label-width="100px"
        :rules="addQueAnsRules"
      >
        <el-form-item label="答案选项" prop="answerKey">
          <el-input maxlength="1" v-model="addQueAns.answerKey"></el-input>
        </el-form-item>
        <el-form-item label="答案内容" prop="answerContent">
          <el-input v-model="addQueAns.answerContent"></el-input>
        </el-form-item>

        <el-form-item label="答案标签" prop="answerFlag">
          <el-input v-model="addQueAns.answerFlag"></el-input>
        </el-form-item>
        <el-form-item label="答案评分" prop="answerValue">
          <el-input v-model="addQueAns.answerValue"></el-input>
        </el-form-item>
        <el-form-item label="序列">
          <el-input-number
            v-model="addQueAns.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答案描述" prop="answerDesc">
          <el-input v-model="addQueAns.answerDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQueAnsVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAddQueAns">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="问题答案"
      :visible.sync="addQueAnsVisibleT"
      width="30%"
      @close="onaddQueAnsVisibleCloseT"
    >
      <el-form
        ref="addQueAnsRules"
        :model="addQueAns"
        label-width="100px"
        :rules="addQueAnsRules"
      >
        <el-form-item label="答案选项" prop="answerKey">
          <el-input maxlength="1" v-model="addQueAns.answerKey"></el-input>
        </el-form-item>
        <el-form-item label="答案内容" prop="answerContent">
          <el-input v-model="addQueAns.answerContent"></el-input>
        </el-form-item>

        <!-- <el-form-item label="答案标签" prop="answerFlag">
          <el-input v-model="addQueAns.answerFlag"></el-input>
        </el-form-item> -->
        <el-form-item label="答案" prop="answerValue">
          <el-select
            v-model="addQueAns.answerValue"
            placeholder="答案"
            clearable
            class="handle-select mr10"
          >
            <el-option key="0" label="错误" value="0"></el-option>
            <el-option key="1" label="正确" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列">
          <el-input-number
            v-model="addQueAns.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答案描述" prop="answerDesc">
          <el-input v-model="addQueAns.answerDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQueAnsVisibleT = false">取 消</el-button>
        <el-button type="primary" @click="saveAddQueAns">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改情感问题答案 -->
    <el-dialog
      title="情感问题答案"
      :visible.sync="editQueAnsVisible"
      width="30%"
      @close="oneditQueAnsVisibleClose"
    >
      <el-form
        ref="editQueAnsRules"
        :model="editQueAns"
        label-width="100px"
        :rules="editQueAnsRules"
      >
        <el-form-item label="答案内容" prop="answerContent">
          <el-input v-model="editQueAns.answerContent"></el-input>
        </el-form-item>

        <el-form-item label="答案标签" prop="answerFlag">
          <el-input v-model="editQueAns.answerFlag"></el-input>
        </el-form-item>
        <el-form-item label="答案评分" prop="answerValue">
          <el-input v-model="editQueAns.answerValue"></el-input>
        </el-form-item>
        <el-form-item label="序列">
          <el-input-number
            v-model="editQueAns.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答案描述" prop="answerDesc">
          <el-input v-model="editQueAns.answerDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editQueAnsVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditQueAns">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="情感问题答案"
      :visible.sync="editQueAnsVisibleT"
      width="30%"
      @close="oneditQueAnsVisibleCloseT"
    >
      <el-form
        ref="editQueAnsRules"
        :model="editQueAns"
        label-width="100px"
        :rules="editQueAnsRules"
      >
        <!-- <el-form-item label="答案选项" prop="answerKey">
          <el-input maxlength="1" v-model="editQueAns.answerKey"></el-input>
        </el-form-item> -->
        <el-form-item label="答案内容" prop="answerContent">
          <el-input v-model="editQueAns.answerContent"></el-input>
        </el-form-item>

        <el-form-item label="答案标签" prop="answerFlag">
          <el-input v-model="editQueAns.answerFlag"></el-input>
        </el-form-item>
        <el-form-item label="答案" prop="answerValue">
          <el-select
            v-model="editQueAns.answerValue"
            placeholder="答案"
            clearable
            class="handle-select mr10"
          >
            <el-option key="0" label="错误" value="0"></el-option>
            <el-option key="1" label="正确" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列">
          <el-input-number
            v-model="editQueAns.orderNum"
            :min="0"
            :max="1000"
            label="描述文字"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="答案描述" prop="answerDesc">
          <el-input v-model="editQueAns.answerDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editQueAnsVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditQueAns">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  quePage,
  queAdd,
  queDel,
  queUpdate,
  queInfo,
  queAnsAdd,
  queAnsDel,
  queAnsUpdate,
} from "@/api/emotion/emotion.js";
// import { goodstatus } from "@/api/emotion/emotion.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  // name: 'basetable',
  data() {
    const checkDeptTel = (rule, value, callback) => {
      console.log("_", rule);
      // const pattern = /[^0-9.]/g;         /^\d*\.{0,1}\d{0,1}$/;
      const pattern = /^(?=.*?[a-zA-Z]).*$/;
      if (value !== "") {
        if (pattern.test(value)) {
          callback();
        } else {
          callback(new Error("答案选项必须为字母"));
        }
      } else {
        callback(new Error("答案选项为空"));
      }
    };
    const checkDeptNumber = (rule, value, callback) => {
      console.log(rule);
      const pattern = /^(?=.*?\d).*$/;
      if (value !== "") {
        if (pattern.test(value)) {
          callback();
        } else {
          callback(new Error("评分为数字"));
        }
      } else {
        callback(new Error("评分为空"));
      }
    };
    return {
      query: {
        searchCond: "",
        pageSize: 10,
        pageNum: 1,
        articleType: "1",
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      revise: false,
      //问题答案
      detailsVisible: false, //问题详情
      detailsVisibleT: false, //问题详情

      addQueAnsVisible: false,
      addQueAnsVisibleT: false,

      //修改问题答案
      editQueAnsVisible: false,
      editQueAnsVisibleT: false,
      editQueAns: {
        id: "",
        answerContent: "",
        answerDesc: "",
        answerFlag: "",
        answerKey: "",
        answerValue: "",
        orderNum: 0,
      },
      questionAnsId: "",
      tableDataAns: {
        content: "",
        answers: [],
      },
      pageTotal: 0,
      form: {
        content: "",
        type: "",
        orderNum: 0,
      },
      amend: {
        id: "",
        type: "",
        content: "",
        orderNum: 0,
      },
      formRules: {
        content: [{ required: true, message: "参数名称为空", trigger: "blur" }],
        type: [{ required: true, message: "类型为空", trigger: "blur" }],
      },
      amendRules: {
        content: [{ required: true, message: "参数名称为空", trigger: "blur" }],
        type: [{ required: true, message: "类型为空", trigger: "blur" }],
      },
      addQueAns: {
        answerContent: "",
        answerDesc: "",
        answerFlag: "标签",
        answerKey: "",
        answerValue: "",
        orderNum: 0,
      },
      questionId: "",
      addQueAnsRules: {
        answerContent: [
          { required: true, message: "答案内容为空", trigger: "blur" },
        ],
        answerDesc: [
          { required: true, message: "答案描述为空", trigger: "blur" },
        ],
        answerFlag: [
          { required: true, message: "答案标签为空", trigger: "blur" },
        ],
        answerKey: [
          // { required: true, message: "答案选项为空", trigger: "blur" },
          { required: true, validator: checkDeptTel, trigger: "blur" },
        ],
        answerValue: [
          //  message: "答案值为空",
          { required: true, validator: checkDeptNumber, trigger: "blur" },
        ],
      },
      editQueAnsRules: {
        answerContent: [
          { required: true, message: "答案内容为空", trigger: "blur" },
        ],
        answerDesc: [
          { required: true, message: "答案描述为空", trigger: "blur" },
        ],
        answerFlag: [
          { required: true, message: "答案标签为空", trigger: "blur" },
        ],
        answerKey: [
          // { required: true, message: "答案选项为空", trigger: "blur" },
          { required: true, validator: checkDeptTel, trigger: "blur" },
        ],
        answerValue: [
          //  message: "答案值为空",
          { required: true, validator: checkDeptNumber, trigger: "blur" },
        ],
      },
      loading: false,
      // idx: -1,
      // id: -1,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取提交令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    //详情
    ondetailsVisibleClose() {
      this.questionId = "";
      this.detailsVisible = false;
    },
    ondetailsVisibleCloseT() {
      this.questionId = "";
      this.detailsVisibleT = false;
    },
    //添加问题答案
    onaddQueAnsVisibleClose() {
      this.addQueAns = {
        answerContent: "",
        answerDesc: "",
        answerFlag: "标签",
        answerKey: "",
        answerValue: "",
        orderNum: 0,
      };
      this.addQueAnsVisible = false;
    },
    onaddQueAnsVisibleCloseT() {
      this.addQueAns = {
        answerContent: "",
        answerDesc: "",
        answerFlag: "标签",
        answerKey: "",
        answerValue: "",
        orderNum: 0,
      };
      this.addQueAnsVisible = false;
    },
    oneditQueAnsVisibleClose() {
      this.editQueAnsVisible = false;
    },
    oneditQueAnsVisibleCloseT() {
      this.editQueAnsVisibleT = false;
    },
    //
    async handleDetails(row) {
      console.log(row, "________");
      try {
        if (row.type == "0") {
          this.detailsVisible = true;
          console.log("___++++++");
        } else {
          this.detailsVisibleT = true;
          console.log("__++==========");
        }

        this.loading = true;
        this.questionId = row.id;
        const { code, data } = await queInfo(row.id);
        if (code == 0) {
          console.log(data);
          this.tableDataAns = data;
          this.loading = false;
        }
      } finally {
        // this.detailsVisible = true;
        if (row.type == "0") {
          this.detailsVisible = true;
        } else {
          this.detailsVisibleT = true;
        }
        this.loading = false;
      }
    },
    handleQueDel(row) {
      this.tkL();
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code } = await queDel({
            id: row.id,
          });
          if (code == 0) {
            this.$message.success("删除成功");
            this.tkL();
            this.getData();
          }
        })
        .catch(() => {});
    },
    // 获取情感问题分页
    async getData() {
      let d = {
        status: 0,
        pageSize: this.query.pageSize,
        pageNum: this.query.pageNum,
      };

      const { code, data } = await quePage(d);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    //问题答案删除
    handleDel(row) {
      this.tkL();
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code } = await queAnsDel({
            id: row.id,
          });
          if (code == 0) {
            this.$message.success("删除成功");
            this.tkL();
            this.handleDetails({
              id: this.questionId,
            });
          } else {
            this.$message.error("删除失败");
            this.tkL();
          }
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },

    // 修改情感问题答案
    handleQueAnsEdit(row) {
      this.editQueAnsVisible = true;
      this.editQueAns = JSON.parse(JSON.stringify(row));
      // console.log(row);
    },
    handleQueAnsEditT(row) {
      this.editQueAnsVisibleT = true;
      this.editQueAns = JSON.parse(JSON.stringify(row));
    },
    handleQueEdit(row) {
      this.amend = JSON.parse(JSON.stringify(row));
      this.revise = true;
    },
    saveEditQueAns() {
      this.$refs["editQueAnsRules"].validate(async (valid) => {
        if (!valid) return false;
        try {
          const { code, msg } = await queAnsUpdate({
            answerContent: this.editQueAns.answerContent,
            answerDesc: this.editQueAns.answerDesc,
            answerFlag: this.editQueAns.answerFlag,
            // answerKey: this.editQueAns.answerKey.toUpperCase(),
            answerValue: this.editQueAns.answerValue,
            orderNum: this.editQueAns.orderNum,
            id: this.editQueAns.id,
            questionId: this.questionId,
          });
          if (code == 0) {
            this.tkL();
            this.$message.success("修改答案成功");
            this.editQueAns = {
              answerContent: "",
              answerDesc: "",
              answerFlag: "",
              answerKey: "",
              answerValue: "",
              orderNum: 0,
            };
            // this.handleDetails(this.questionId);
            this.handleDetails({
              id: this.questionId,
            });
          } else {
            this.tkL();
            this.$message.error(msg);
            this.editQueAns = {
              answerContent: "",
              answerDesc: "",
              answerFlag: "",
              answerKey: "",
              answerValue: "",
              orderNum: 0,
            };
            // this.handleDetails(this.questionId);
            this.handleDetails({
              id: this.questionId,
            });
          }
        } finally {
          this.editQueAns = {
            answerContent: "",
            answerDesc: "",
            answerFlag: "",
            answerKey: "",
            answerValue: "",
            orderNum: 0,
          };
          this.editQueAnsVisible = false;
          this.editQueAnsVisibleT = false;
          // this.handleDetails(this.questionId);
          this.handleDetails({
              id: this.questionId,
            });
        }
        // this.addQueAns.answerKey = this.addQueAns.answerKey.toUpperCase();
        // console.log("添加情感问题答案", this.addQueAns.answerKey);
      });
    },
    // 修改情感问题
    async modify() {
      this.tkL();
      const { code } = await queUpdate({
        content: this.amend.content,
        id: this.amend.id,
        type: this.amend.type,
        orderNum: this.amend.orderNum,
      });

      if (code == 0) {
        this.getData();
        this.revise = false;
        this.tkL();
        this.$message.success("修改成功");
      } else {
        this.tkL();
        this.revise = false;
        this.$message.error("修改失败");
      }
    },
    // 打开新增问题弹框
    Newfeelings() {
      this.editVisible = true;
    },
    onAddVisibleClose() {
      this.editVisible = false;
      this.form = {
        content: "",
        type: "",
        orderNum: 0,
      };
    },
    // 添加情感问题
    async saveEdit() {
      try {
        let a = {
          content: this.form.content,
          orderNum: this.form.orderNum,
          type: this.form.type,
        };
        const { code, msg } = await queAdd(a);
        if (code == 0) {
          this.$message.success("添加情感问题成功");
          this.tkL();
          this.getData();
        } else {
          this.tkL();
          this.$message.error(msg);
        }
        this.form = {
          content: "",
          type: "",
          orderNum: 0,
        };
        this.editVisible = false;
      } finally {
        this.editVisible = false;
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.getData();
    },

    // 添加问题答案
    saveAddQueAns() {
      this.$refs["addQueAnsRules"].validate(async (valid) => {
        if (!valid) return false;
        try {
          const { code, msg } = await queAnsAdd({
            answerContent: this.addQueAns.answerContent,
            answerDesc: this.addQueAns.answerDesc,
            answerFlag: this.addQueAns.answerFlag,
            answerKey: this.addQueAns.answerKey.toUpperCase(),
            answerValue: this.addQueAns.answerValue,
            orderNum: this.addQueAns.orderNum,
            questionId: this.questionId,
          });
          if (code == 0) {
            this.tkL();
            this.$message.success("添加答案成功");
            this.addQueAns = {
              answerContent: "",
              answerDesc: "",
              answerFlag: "标签",
              answerKey: "",
              answerValue: "",
              orderNum: 0,
            };
            // this.handleDetails(this.questionId);
            this.handleDetails({
              id: this.questionId,
            });
          } else {
            this.tkL();
            this.$message.error(msg);
            this.addQueAns = {
              answerContent: "",
              answerDesc: "",
              answerFlag: "标签",
              answerKey: "",
              answerValue: "",
              orderNum: 0,
            };
          }
        } finally {
          this.addQueAns = {
            answerContent: "",
            answerDesc: "",
            answerFlag: "标签",
            answerKey: "",
            answerValue: "",
            orderNum: 0,
          };
          this.addQueAnsVisible = false;
          this.addQueAnsVisibleT = false;
          // this.handleDetails(this.questionId);
          this.handleDetails({
              id: this.questionId,
            });
        }
        this.addQueAns.answerKey = this.addQueAns.answerKey.toUpperCase();
        console.log("添加情感问题答案", this.addQueAns.answerKey);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  /* width: 100%; */
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.table-hand {
  flex: 1;
}
/deep/ .el-card__body {
  padding: 0%;
}
/deep/ .el-dialog__body {
  padding-top: 10px;
}
.clearfix {
  display: flex;
  justify-content: center;
}
</style>
