<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>订单信息
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.orderStatus"
          placeholder="支付状态"
          class="handle-select mr10"
        >
          <el-option key="0" label="待支付" value="0"></el-option>
          <el-option key="1" label="已支付" value="1"></el-option>
          <el-option key="2" label="已取消" value="2"></el-option>
        </el-select>
        <el-select
          v-model="query.orderSource"
          placeholder="订单来源"
          class="handle-select mr10"
        >
          <el-option key="0" label="小程序" value="0"></el-option>
          <el-option key="1" label="PC" value="1"></el-option>
          <el-option key="2" label="H5" value="2"></el-option>
          <el-option key="3" label="安卓" value="3"></el-option>
          <el-option key="5" label="ios" value="5"></el-option>
        </el-select>
        <el-select
          v-model="query.payMethod"
          placeholder="支付方式"
          class="handle-select mr10"
        >
          <el-option key="0" label="微信" value="1"></el-option>
          <el-option key="1" label="支付宝" value="2"></el-option>
        </el-select>
        <el-input
          v-model="query.buyerId"
          placeholder="购买人ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="buyerId" label="购买人ID" align="center">
        </el-table-column>
        <el-table-column
          prop="categoryName"
          label="商品分类名称"
        ></el-table-column>
        <el-table-column
          prop="goodName"
          label="商品名称"
        ></el-table-column>
        
        <el-table-column label="商品价格">
          <template slot-scope="scope">
            <p>¥{{ Number(scope.row.goodSum) / 100 }}</p>
          </template></el-table-column
        >
        <el-table-column label="支付">
          <template slot-scope="scope">
            <p v-if="scope.row.orderSource == '0'">
              小程序<span v-if="scope.row.payMethod">{{
                scope.row.payMethod == 0 ? "_微信" : "_支付宝"
              }}</span>
            </p>
            <p v-if="scope.row.orderSource == '1'">
              PC<span v-if="scope.row.payMethod">{{
                scope.row.payMethod == 0 ? "_微信" : "_支付宝"
              }}</span>
            </p>
            <p v-if="scope.row.orderSource == '2'">
              H5<span v-if="scope.row.payMethod">{{
                scope.row.payMethod == 0 ? "_微信" : "_支付宝"
              }}</span>
            </p>
            <p v-if="scope.row.orderSource == '3'">
              安卓<span v-if="scope.row.payMethod">{{
                scope.row.payMethod == 0 ? "_微信" : "_支付宝"
              }}</span>
            </p>
            <p v-if="scope.row.orderSource == '5'">
              ios<span v-if="scope.row.payMethod">{{
                scope.row.payMethod == 0 ? "_微信" : "_支付宝"
              }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="支付状态">
          <template slot-scope="scope">
            <p v-if="scope.row.orderStatus == '0'">待支付</p>
            <p v-if="scope.row.orderStatus == '1'">已支付</p>
            <p v-if="scope.row.orderStatus == '2'">已取消</p>
          </template>
        </el-table-column>
        <el-table-column label="购买时间">
          <template slot-scope="scope">
            <p>{{ $moment(scope.row.createTime).format("lll") }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { orderPage } from "@/api/store/store.js";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        buyerId: "", //购买人ID
        payMethod: "", //支付方式
        orderSource: "", //订单来源
        orderStatus: "", //订单状态
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      pageTotal: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let a = {
        buyerId: this.query.buyerId, //购买人ID
        payMethod: this.query.payMethod, //支付方式
        orderSource: this.query.orderSource, //订单来源
        orderStatus: this.query.orderStatus, //订单状态
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      };
      const { code, data } = await orderPage(a);
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      console.log("ss", row);
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.$message.success("删除成功");
          this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.query.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
