<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.memberId"
          placeholder="会员ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <!-- header-cell-class-name="table-header"
        @selection-change="handleSelectionChange" -->
      <el-table :data="tableData" border class="table" ref="multipleTable">
        <!-- <el-table-column prop="id" label="#"></el-table-column> -->
        <!-- <el-table-column prop="memberId" label="会员ID"></el-table-column>  -->
        <el-table-column prop="title" label="问题"></el-table-column>
        <el-table-column prop="content" label="观点"></el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            <p v-if="scope.row.updateTime">{{ $moment(scope.row.updateTime).format("YYYY-MM-DD HH:mm:ss") }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-view"
              @click="handleDetails(scope.$index, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import { memberPointList } from "@/api/member/member.js";
export default {
  name: "basetable",
  data() {
    return {
      query: {
        memberId: "",
      },
      form: {},
      tableData: [],
      editVisible: false,
      pageTotal: 0,
    };
  },
  created() {},
  methods: {
    async getData() {
      const { code, data } = await memberPointList({
        memberId: this.query.memberId,
      });
      if (code == 0) {
        this.tableData = data
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
