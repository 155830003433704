<template>
  <div>
    <div class="container">
      <div class="handle-box">

        <el-select
          v-model="query.good"
          placeholder="商品"
          value-key="id"
          clearable
          style="margin-right:10px"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.goodName"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="query.memberId"
          placeholder="购买人ID"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="memberId" label="购买人ID" align="center">
        </el-table-column>
        <el-table-column
          prop="goodName"
          label="商品名称"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column label="服务来源">
          <template slot-scope="scope">
            <p v-if="scope.row.serviceSource == '0'">购买支付</p>
            <p v-if="scope.row.serviceSource == '1'">服务赠送</p>
          </template>
        </el-table-column>

        <el-table-column label="服务状态">
          <template slot-scope="scope">
            <p v-if="scope.row.status == '0'">未开始</p>
            <p v-if="scope.row.status == '1'">服务中</p>
            <p v-if="scope.row.status == '2'">已失效</p>
          </template>
        </el-table-column>
        <el-table-column label="会员期限" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <p>
              {{ $moment(scope.row.startTime).format("ll") }} -
              {{ $moment(scope.row.endTime).format("ll") }}
            </p>
          </template>
        </el-table-column>

        <el-table-column label="购买时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <p>{{ $moment(scope.row.createTime).format("lll") }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { vipServiceRefPage, goodList } from "@/api/store/store.js";
export default {
  data() {
    return {
      query: {
        memberId: "", //购买人ID
        payMethod: "", //支付方式
        orderSource: "", //订单来源
        orderStatus: "", //订单状态
        pageNum: 1,
        pageSize: 10,
        good: {
          goodKey: "",
          goodName: "",
        },
      },
      tableData: [],
      pageTotal: 0,
      options: [],
    };
  },
  created() {
    this.getData();
    this.payServeSyn();
  },
  methods: {
    async getData() {
      const { code, data } = await vipServiceRefPage({
        goodKey: this.query.good.goodKey,
        goodName: this.query.good.goodName,
        memberId: this.query.memberId,
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    async payServeSyn() {
      const { code, data } = await goodList();
      if (code == 0) {
        this.options = data;
      }
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, "pageNum", 1);
      this.query.pageNum = 1;
      this.getData();
    },
    // 删除操作
    handleDelete(row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          console.log(row);
          this.$message.success("删除成功");
        })
        .catch(() => {});
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.query.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
