import request from "@/utils/request.js"
//登录
export const loginAuth = ((data) => {
    return request.post('/yezai-auth/member/auth', data)
})
//登出
export const logout = ((data) => {
    return request.DELETE('/yezai-auth/member/logout',data)
})
// //
// export const infocur = ((data) => {
//     return request.get('/member-server/member/info/info/cur', data)
// })