<template>
  <div>
    <el-card>
      <el-table
        :data="analStdData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="answerFlag" label="情感标签"></el-table-column>

        <el-table-column prop="oneFlag" label="第一档标签"></el-table-column>
        <el-table-column prop="oneDesc" label="第一档描述"></el-table-column>
        <el-table-column prop="oneScore" label="第一档分值"></el-table-column>

        <el-table-column prop="twoFlag" label="第二档标签"></el-table-column>
        <el-table-column prop="twoDesc" label="第二档描述"></el-table-column>
        <el-table-column prop="twoScore" label="第二档分值"></el-table-column>

        <el-table-column prop="threeFlag" label="第三档标签"></el-table-column>
        <el-table-column prop="threeDesc" label="第三档描述"></el-table-column>
        <el-table-column prop="threeScore" label="第三档分值"></el-table-column>

        <el-table-column prop="fourFlag" label="第四档标签"></el-table-column>
        <el-table-column prop="fourDesc" label="第四档描述"></el-table-column>
        <el-table-column prop="fourScore" label="第四档分值"></el-table-column>

        <el-table-column prop="fiveFlag" label="第五档标签"></el-table-column>
        <el-table-column prop="fiveDesc" label="第五档描述"></el-table-column>
        <el-table-column prop="fiveScore" label="第五档分值"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { analStdList } from "@/api/emotion/emotion.js";
export default {
  data() {
    return {
      analStdData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const { code, data } = await analStdList();
      if (code == 0) {
        console.log(data);
        this.analStdData = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>