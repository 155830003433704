<template>
  <div>
    <!-- <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>审核内容</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="back">
      <el-button type="primary" @click="goBack()">返回</el-button>
    </div>
    <div class="container">
      <div class="form-box">
        <el-form ref="form" :model="detail" label-width="80px">
          <el-form-item label="昵称">
            <el-input v-model="detail.nickName"></el-input>
          </el-form-item>
          <el-form-item label="喜欢的书">
            <el-input v-model="detail.favoriteBooks"></el-input>
          </el-form-item>
          <el-form-item label="欣赏的名人">
            <el-input v-model="detail.favoriteCelebrities"></el-input>
          </el-form-item>
          <el-form-item label="喜欢的菜">
            <el-input v-model="detail.favoriteDishes"></el-input>
          </el-form-item>
          <el-form-item label="喜欢的歌曲">
            <el-input v-model="detail.favoriteSongs"></el-input>
          </el-form-item>
          <el-form-item label="喜欢做的事">
            <el-input v-model="detail.likeDoing"></el-input>
          </el-form-item>
          <el-form-item label="内心独白">
            <el-input
              type="textarea"
              rows="4"
              v-model="form.introduce"
            ></el-input>
          </el-form-item>
          <el-form-item label="头像" v-model="form.avatar"> </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">通过</el-button>
            <el-button type="textarea" @click="refuse">拒绝</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
import { updateAapprove } from "@/api/member/member.js";
export default {
  data() {
    return {
      form: {},
      detail: [],
    };
  },
  created() {
    this.detail = this.$route.query;
    console.log(this.detail, "个人详情");
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 拒绝
    refuse() {
      this.$prompt("拒绝理由", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\D/g,
        inputErrorMessage: "内容格式不正确",
      })
        .then(() => {
          this.$message({
            type: "success",
            // message: "你的邮箱是: " + value,
            message: "提交成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消审核",
          });
        });
    },
    // 通过
    onSubmit() {
      this.tkL();
      let a = {
        avatar: "",
        favoriteBooks: "",
        favoriteCelebrities: "",
        favoriteDishes: "",
        favoriteSongs: "",
        introduce: "",
        likeDoing: "",
        nickName: this.detail.nickName,
      };
      const { code, data } = updateAapprove(a);
      if (code == 0) {
        console.log(data, "aa");
        this.$router.go(-1); //返回上一层
        this.$message.success("审核成功！");
      }
    },
    goBack() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>
<style lang="less" scoped>
.back {
  margin-bottom: 10px;
}
/deep/.el-form-item__label {
  width: 85px !important;
  text-align: left;
}
/deep/.el-input__inner {
  width: 70%;
  margin-left: -70px !important;
}
/deep/.el-textarea__inner {
  width: 70%;
  margin-left: -70px !important;
}
/deep/.el-button--primary {
  margin-right: 80px;
}
</style>
