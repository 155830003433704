<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="8"
        :lg="6"
        :xl="5"
        style="margin-bottom: 10px"
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <div style="text-align: center">
              <div class="el-upload">
                <img
                  :src="form.avatar || deAvatar"
                  title="点击上传头像"
                  class="avatar"
                  @click="toggleShow"
                />
                <!-- 
                  :headers="headers"
                   
                  :url="updateAvatarApi"   url="https://yezai.oss-cn-shenzhen.aliyuncs.com/dev/back/avatar/"-->
                <!-- <myUpload
                  v-model="show"
                  @crop-upload-success="cropUploadSuccess"
                  field="img"
                  url=""
                  @crop-success="cropSuccess"
                />      :value="false"-->
                <my-upload
                  field="img"
                  @crop-success="cropSuccess"
                  :modelValue.sync="show"
                ></my-upload>
              </div>
            </div>
            <ul class="user-info">
              <li>
                <!-- <div style="height: 100%"> -->
                <!-- <svg-icon icon-class="login" /> 登录账号 -->
                <i class="iconfont icon-zhanghao"> 登录账号</i>
                <!-- <div class="user-right">{{ user.username }}</div> -->
                <div class="user-right">{{ form.account }}</div>
                <!-- </div> -->
              </li>
              <li>
                <i class="iconfont icon-nicheng">用户昵称</i>
                <!-- <div class="user-right">{{ user.nickName }}</div> -->
                <div class="user-right">{{ form.userName }}</div>
              </li>
              <!-- <li>
                <i class="iconfont icon-bumen">所属部门</i>
                <div class="user-right">{{ form.orgId }}</div>
              </li> -->
              <li>
                <i class="iconfont icon-shoujihao">手机号码</i>
                <div class="user-right">{{ form.phonenumber || "未填写" }}</div>
              </li>
              <li>
                <i class="iconfont icon-youxiang">用户邮箱</i>
                <!-- <div class="user-right">{{ user.email }}</div> -->
                <div class="user-right">{{ form.email || "未填写" }}</div>
              </li>
              <li>
                <i class="iconfont icon-anquanshezhi">安全设置</i>
                <div class="user-right">
                  <p class="pass" @click="del">修改密码</p>
                  <!-- <a @click="del">修改邮箱</a> -->
                </div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="18" :xl="19">
        <el-card class="box-card">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <!--    用户资料    -->
            <el-tab-pane label="用户资料" name="first">
              <el-form
                ref="form"
                :model="info"
                :rules="rulesUserInfo"
                style="margin-top: 10px"
                size="small"
                label-width="65px"
              >
                <el-form-item label="昵称" prop="userName">
                  <el-input v-model="info.userName" style="width: 35%" />
                  <span style="color: #c0c0c0; margin-left: 10px"
                    >用户昵称不作为登录使用</span
                  >
                </el-form-item>

                <el-form-item label="性别">
                  <el-radio-group v-model="info.sex" style="width: 178px">
                    <el-radio :label="'0'">男</el-radio>
                    <el-radio :label="'1'">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input v-model="info.phonenumber" style="width: 35%" />
                  <span style="color: #c0c0c0; margin-left: 10px"
                    >手机号码不能重复</span
                  >
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="info.email" style="width: 35%" />
                </el-form-item>

                <el-form-item label="">
                  <el-button
                    :loading="saveLoading"
                    size="mini"
                    type="primary"
                    @click="doSubmit"
                    >保存配置</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!--    操作日志    -->
            <el-tab-pane label="操作日志" name="second">
              <!-- <el-table v-loading="loading" :data="data" style="width: 100%;">
                <el-table-column prop="description" label="行为" />
                <el-table-column prop="requestIp" label="IP" />
                <el-table-column :show-overflow-tooltip="true" prop="address" label="IP来源" />
                <el-table-column prop="browser" label="浏览器" />
                <el-table-column prop="time" label="请求耗时" align="center">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.time <= 300">{{ scope.row.time }}ms</el-tag>
                    <el-tag v-else-if="scope.row.time <= 1000" type="warning">{{ scope.row.time }}ms</el-tag>
                    <el-tag v-else type="danger">{{ scope.row.time }}ms</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  align="right"
                >
                  <template slot="header">
                    <div style="display:inline-block;float: right;cursor: pointer" @click="init">创建日期<i class="el-icon-refresh" style="margin-left: 40px" /></div>
                  </template>
                  <template slot-scope="scope">
                    <span>{{ scope.row.createTime }}</span>
                  </template>
                </el-table-column>
              </el-table> -->
            </el-tab-pane>
          </el-tabs>


          
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="密码设置"
      width="500px"
      class="dialogForm"
      :visible.sync="addFormVisible"
    >
      <el-form
        :model="addForm"
        ref="addForm"
        style="width: 400px; margin: 0 auto"
        :rules="rulesPass"
      >
        <el-form-item
          label="旧密码"
          :label-width="formLabelWidth"
          prop="oldPass"
        >
          <el-input v-model="addForm.oldPass"></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          :label-width="formLabelWidth"
          prop="newPass"
        >
          <el-input v-model="addForm.newPass"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="rowSave()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload";
// import { mapGetters } from "vuex";
// import updatePass from "./center/updatePass";
// import updateEmail from "./center/updateEmail";
// import { getToken } from "@/utils/auth";
// import store from "@/store";
// import { isvalidPhone } from "@/utils/validate";
// import crud from "@/mixins/crud";
// import { editUser } from "@/api/system/user";
// import Avatar from "@/assets/images/avatar.png";
import { userInfo } from "@/api/common.js";
import { ossL } from "@/api/common/oss/ossL.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js"; //userUpdate
import { userUpdate, userUpdatePwd } from "@/api/system/system.js"; //userUpdate
import { client, getFileNameUUID } from "@/api/common/oss/oss.js"; //client,getFileNameUUID是前面的oss.js文件内的两个封装函数
// import { ossL } from "@/api/common/oss/ossL.js"; //client,getFileNameUUID是前面的oss.js文件内的两个封装函数

import env from "@/api/env.js";
export default {
  //   name: "Center",
  // components: { updatePass, updateEmail, myUpload },
  components: { myUpload },
  //   mixins: [crud],
  data() {
    // 自定义验证
    // const validPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("请输入电话号码"));
    //   } else if (!isvalidPhone(value)) {
    //     callback(new Error("请输入正确的11位手机号码"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      deAvatar: require("@/assets/img/img.jpg"),
      activeName: "first",
      saveLoading: false,
      //   headers: {
      //     Authorization: getToken(),
      //   },
      show: false,
      form: {
        avatar: "",
      },
      info: {
        email: "",
        phonenumber: "",
        remark: "",
        sex: 0,
        userName: "",
      },
      // form.avatar
      addForm: {
        oldPass: "",
        newPass: "",
      },
      addFormVisible: false,
      formLabelWidth: "80px",
      rulesUserInfo: {
        userName: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
        ],
        // phone: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   {
        //     min: 2,
        //     max: 20,
        //     message: "长度在 2 到 20 个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
      rulesPass: {
        newPass: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        oldPass: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
      afterImg: "",
      dataObj: {}, //存签名信息
      // size: 2.1,
      iSshow: false,
      id: "",
      UserId: "",
    };
  },
  computed: {},
  created() {
    this.getdata();
    this.tkL();
  },
  methods: {
    async getdata() {
      const { code, data } = await userInfo(local.get("access_token"));
      if (code == 0) {
        console.log(data.user);
        this.form = data.user;
        this.info = data.user;
        if (data.user.avatar) {
          this.form.avatar = ossL(data.user.avatar);
          this.UserId = data.user.id;
        }
      }
    },
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    del() {
      this.addFormVisible = true;
    },
    rowSave() {
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].validate(async (valid) => {
          if (valid) {
            // this.saveLoading = true;
            console.log("11");
            const { code, data } = await userUpdatePwd({
              oldPwd: this.addForm.oldPass,
              pwd: this.addForm.newPass,
            });
            if (code == 0) {
              console.log(data);
              this.$message.success("修改成功");
            } else {
              this.$message.error("修改失败");
            }
            this.tkL();
            this.addFormVisible = false;
          } else {
            console.log("22");
          }
        });
      }
    },
    toggleShow() {
      this.show = !this.show;
    },
    handleClick(tab, event) {
      console.log(event);
      if (tab.name === "second") {
        /**
         * 操作日志
         */
        this.$message.success('操作日志')
      }
    },
    cropUploadSuccess(jsonData, field) {
      console.log(jsonData, field);
      // store.dispatch("GetInfo").then(() => {});
    },
    cropSuccess(imgDataUrl) {
      //  imgDataUrl其实就是经过base64转码过的图片
      this.afterImg = imgDataUrl;
      const file = this.base64toFile(this.afterImg, getFileNameUUID());
      const baseToFile = this.base64toFile(
        this.afterImg,
        getFileNameUUID()
      ).name;
      const that = this;
      function multipartUpload() {
        client(that.dataObj)
          .multipartUpload(`${env.uploadUrlAvatar}${baseToFile}`, file)
          .then(async (res) => {
            const { code, data } = await userUpdate({
              avatar: res.name,
              id: that.form.id,
            });
            if (code == 0) {
              console.log(data);
            }
            that.form.avatar = ossL(res.name);
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    /**
     * @description: base64位图片转码文件流
     * @param {type}
     * @Date: 2021-07-25 09:57:22
     */
    base64toFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    doSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) return false;
        const { code, data } = await userUpdate({
          id: this.form.id,
          email: this.info.email,
          phonenumber: this.info.phonenumber,
          sex: this.info.sex,
          userName: this.info.userName,
        });
        if (code == 0) {
          console.log(data);
          this.getdata();
          this.$message.success("保存成功");
          this.tkL();
        } else {
          this.$message.error("保存失败");
          this.tkL();
        }
      });
    },
  },
};
</script>

<style   lang="less" scoped>
.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.user-info {
  padding-left: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #f0f3f4;
    padding: 11px 0;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-right {
    float: right;
    width: 100px;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .pass {
      color: #317ef3;
      cursor: pointer;
    }
  }
}
</style>