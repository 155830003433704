<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="rcommendAdd"
          >添加</el-button
        >
      </div>
      <el-table :data="tableData" border row-key="id" align="left">
        <el-table-column label="头像" width="60px">
          <template slot-scope="scope">
            <el-image
              style="width: 32px; height: 32px"
              fit="cover"
              :preview-src-list="AlbumL"
              :src="url + scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="memberId" label="会员ID" width="120px"></el-table-column>
        <el-table-column prop="nickName" label="会员昵称" width="150px"></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.sex == '0'">男</p>
            <p v-if="scope.row.sex == '1'">女</p>
          </template>
        </el-table-column>
        <el-table-column prop="birthday" label="出生年月" width="120px"></el-table-column>
        <el-table-column prop="height" label="身高"></el-table-column>

        <!-- 学历(0-高中及以下、1-中专、2-大专、3-大学本科、4-硕士、5-博士) -->
        <el-table-column label="学历" min-width="130px">
          <template slot-scope="scope">
            <p v-if="scope.row.education == '0'">高中及以下</p>
            <p v-if="scope.row.education == '1'">中专</p>
            <p v-if="scope.row.education == '2'">大专</p>
            <p v-if="scope.row.education == '3'">大学本科</p>
            <p v-if="scope.row.education == '4'">硕士</p>
            <p v-if="scope.row.education == '5'">博士</p>
          </template>
        </el-table-column>
        <!-- 婚姻状况(0-未婚、1-离婚、2-丧偶) -->
        <el-table-column label="婚姻状态" min-width="80px">
          <template slot-scope="scope">
            <p v-if="scope.row.maritalStatus == '0'">未婚</p>
            <p v-if="scope.row.maritalStatus == '1'">离异</p>
            <p v-if="scope.row.maritalStatus == '2'">丧偶</p>
          </template>
        </el-table-column>
        <!-- 月收入(0-3000元以下、1-3001-5000元、2-5001-8000元、3-8001-12000元、4-12001-20000元、5-20000-50000元、6-50000元以上) -->
        <el-table-column label="月收入" min-width="130px">
          <template slot-scope="scope">
            <p v-if="scope.row.monthIncome == '0'">3000元以下</p>
            <p v-if="scope.row.monthIncome == '1'">3001-5000元</p>
            <p v-if="scope.row.monthIncome == '2'">5001-8000元</p>
            <p v-if="scope.row.monthIncome == '3'">8001-12000元</p>
            <p v-if="scope.row.monthIncome == '4'">12001-20000元</p>
            <p v-if="scope.row.monthIncome == '5'">20000-50000元</p>
            <p v-if="scope.row.monthIncome == '6'">50000元以上</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="workAddrCityName"
          label="工作地址"
          width="80px"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>

        <el-table-column label="拖拽" width="100px" align="center">
          <!-- <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template> -->
          <i class="iconfont icon-tuozhuai"></i>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加会员弹出框 -->
    <el-dialog
      title="会员列表"
      class="editVisible"
      :visible.sync="editVisible"
      @close="oneditVisibleDialogClose"
    >
      <div class="handle-box">
        <el-input
          v-model="form.cond"
          placeholder="根据用户ID或昵称搜索"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="UserTableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
        v-loading="loading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="头像" width="60px" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 32px; height: 32px"
              fit="cover"
              :preview-src-list="UserAlbumL"
              :src="url + scope.row.avatar"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="会员ID" min-width="120px"></el-table-column>
        <el-table-column prop="nickName" label="会员昵称" min-width="150px"></el-table-column>
        <el-table-column prop="age" label="年龄"></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <p v-if="scope.row.sex == '0'">男</p>
            <p v-if="scope.row.sex == '1'">女</p>
          </template>
        </el-table-column>
        <el-table-column prop="birthday" label="出生年月" min-width="120px"></el-table-column>
        <el-table-column prop="height" label="身高"></el-table-column>

        <!-- 学历(0-高中及以下、1-中专、2-大专、3-大学本科、4-硕士、5-博士) -->
        <el-table-column label="学历" min-width="130px">
          <template slot-scope="scope">
            <p v-if="scope.row.education == '0'">高中及以下</p>
            <p v-if="scope.row.education == '1'">中专</p>
            <p v-if="scope.row.education == '2'">大专</p>
            <p v-if="scope.row.education == '3'">大学本科</p>
            <p v-if="scope.row.education == '4'">硕士</p>
            <p v-if="scope.row.education == '5'">博士</p>
          </template>
        </el-table-column>
        <!-- 婚姻状况(0-未婚、1-离婚、2-丧偶) -->
        <el-table-column label="婚姻状态" min-width="80px">
          <template slot-scope="scope">
            <p v-if="scope.row.maritalStatus == '0'">未婚</p>
            <p v-if="scope.row.maritalStatus == '1'">离异</p>
            <p v-if="scope.row.maritalStatus == '2'">丧偶</p>
          </template>
        </el-table-column>
        <!-- 月收入(0-3000元以下、1-3001-5000元、2-5001-8000元、3-8001-12000元、4-12001-20000元、5-20000-50000元、6-50000元以上) -->
        <el-table-column label="月收入" min-width="130px">
          <template slot-scope="scope">
            <p v-if="scope.row.monthIncome == '0'">3000元以下</p>
            <p v-if="scope.row.monthIncome == '1'">3001-5000元</p>
            <p v-if="scope.row.monthIncome == '2'">5001-8000元</p>
            <p v-if="scope.row.monthIncome == '3'">8001-12000元</p>
            <p v-if="scope.row.monthIncome == '4'">12001-20000元</p>
            <p v-if="scope.row.monthIncome == '5'">20000-50000元</p>
            <p v-if="scope.row.monthIncome == '6'">50000元以上</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="workAddrCityName"
          label="工作地址"
          width="80px"
        ></el-table-column>
        <el-table-column label="账号状态" class="table-hand">
          <template slot-scope="scope">
            <p>
              <span v-if="scope.row.status == '0'">正常</span>
              <span v-if="scope.row.status == '1'">停用</span>
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="form.pageNum"
          :page-size="form.pageSize"
          :total="UserPageTotal"
          @current-change="UserHandlePageChange"
        ></el-pagination>
      </div>
      <div class="flexC">
        <el-button @click="delAllSelection">添加</el-button>
        <el-button @click="oneditVisibleDialogClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Sortable from "sortablejs";
import { infoUserPage } from "@/api/member/member.js";
import {
  memberRecommendPage,
  memberRecommendAdd,
  memberRecommendDel,
} from "@/api/member/member.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";

import env from "@/api/env.js";
export default {
  data() {
    return {
      // url:
      //   process.env.NODE_ENV == "development"
      //     ? "https://yezai.oss-cn-shenzhen.aliyuncs.com/"
      //     : "https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/",
      url:env.ossUrl,
      tableData: [],
      UserTableData: [],
      editVisible: false,
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      form: {
        cond: "",
        pageNum: 1,
        pageSize: 6,
      },
      pageTotal: 0,
      UserPageTotal: 0,
      multipleSelection: [],
      loading: false,
      UserAlbumL: [],
      AlbumL: [],
    };
  },
  mounted() {
    this.rowDrop();
    this.columnDrop();
  },
  created() {
    this.getData();
    this.Tkl();
  },
  methods: {
    // 获取临时令牌
    async Tkl() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    async getData() {
      this.AlbumL = [];
      const { code, data } = await memberRecommendPage({
        pageNum: this.query.pageNum,
        pageSize: this.query.pageSize,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
        this.tableData.forEach((i) => {
          this.AlbumL.push(`${this.url + i.avatar}`);
        });
      }
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          console.log("__", _this.tableData);
        },
      });
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex];
          this.dropCol.splice(evt.oldIndex, 1);
          this.dropCol.splice(evt.newIndex, 0, oldItem);
          //   console.log("__", this.tableData);
        },
      });
    },
    rcommendAdd() {
      this.editVisible = true;
      this.handleSearch();
    },
    async handleSearch() {
      try {
        this.loading = true;
        let d = {
          cond: this.form.cond,
          pageSize: this.form.pageSize,
          pageNum: this.form.pageNum,
        };
        this.UserAlbumL = [];
        const { code, data } = await infoUserPage(d);
        if (code == 0) {
          this.loading = false;
          this.UserTableData = data.records;
          this.UserPageTotal = data.total;
          this.UserTableData.forEach((i) => {
            this.UserAlbumL.push(`${this.url + i.avatar}`);
          });
          // this.query.current = data.current;
        }
      } finally {
        this.loading = false;
      }
    },
    oneditVisibleDialogClose() {
      this.UserAlbumL = [];
      this.editVisible = false;
    },
    // 分页导航
    UserHandlePageChange(val) {
      this.form.pageNum = val;
      this.$set(this.form, "pageNum", val);
      this.handleSearch();
    },
    handlePageChange(val) {
      this.query.pageNum = val;
      this.getData();
    },
    s() {},
    selectable(row, index) {
      console.log(row, index);
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [];
      val.forEach((i) => {
        this.multipleSelection.push(i.id);
      });
      //   this.multipleSelection = val.id;
    },
    async delAllSelection() {
      this.Tkl();
      if (this.multipleSelection) {
        this.multipleSelection = this.multipleSelection.join(",");
        const { code, data } = await memberRecommendAdd({
          memberIds: this.multipleSelection,
        });
        if (code == 0) {
          console.log(data);
          this.getData();
          this.editVisible = false;
          this.$message.success("添加推荐成功");
        }
      }
    },
    async handleDelete(index, row) {
      console.log(index, row);

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code, data } = await memberRecommendDel({
            memberIds: row.memberId,
          });

          if (code == 0) {
            console.log(data);
            this.Tkl();
            this.getData();
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
          }
        })
        .catch(() => {
          this.Tkl();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.flexC {
  display: flex;
  justify-content: center;
}
/deep/ .el-dialog {
  // top: 0;
  width: 80%;
}
/deep/ .el-table td.el-table__cell div {
  display: flex;
  align-items: center;
}
// }
</style>
