<template>
  <div>
    <el-card>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        v-loading="loading"
      >
        <el-table-column prop="createId" label="反馈者id"></el-table-column>
        <el-table-column
          prop="content"
          label="反馈内容"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="反馈处理"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="反馈时间">
          <template slot-scope="scope" v-if="scope.row.createTime">
            <p>
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-permission="'sys:emoque:update'"
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)"
              >处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="form.pageNum"
          :page-size="form.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog
      title="反馈处理"
      :visible.sync="handleVisible"
      width="30%"
      @close="onHandleVisibleClose"
    >
      <el-form
        ref="editHandleRules"
        :model="editHandle"
        label-width="100px"
        :rules="editHandleRules"
      >
        <el-form-item label="处理意见" prop="remark">
          <el-input type="textarea" v-model="editHandle.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onHandleVisibleClose">取 消</el-button>
        <el-button type="primary" @click="saveHandle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sysFbPage, sysFbUpdate } from "@/api/common.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  data() {
    return {
      tableData: [],
      form: {
        pageNum: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      loading: false,
      handleVisible: false,
      editHandle: {
        remark: "",
      },
      editHandleRules: {
        remark: [{ required: true, message: "处理意见为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { code, data } = await sysFbPage({
          pageNum: this.form.pageNum,
          pageSize: this.form.pageSize,
        });
        if (code == 0) {
          this.tableData = data.records;
          this.pageTotal = data.total;
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
      this.tkL();
    },
    // 分页导航
    handlePageChange(val) {
      this.form.pageNum = val;
      this.$set(this.form, "pageNum", val);
      this.getData();
    },
    handleEdit(row) {
      this.editHandle = JSON.parse(JSON.stringify(row));
      this.handleVisible = true;
    },
    onHandleVisibleClose() {
      this.handleVisible = false;
      this.editHandle = {};
    },
    saveHandle() {
      this.$refs["editHandleRules"].validate(async (valid) => {
        if (!valid) return false;
        try {
          this.tkL();
          const { code, msg } = await sysFbUpdate({
            id: this.editHandle.id,
            remark: this.editHandle.remark,
          });
          if (code == 0) {
            this.tkL();
            this.handleVisible = false;
            this.$message.success("处理成功");
            this.getData();
          } else {
            this.handleVisible = false;
            this.tkL();
            this.$message.error(msg);
          }
          this.editHandle = {};
        } catch (e) {
          console.log(e);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>