<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.account"
          clearable
          placeholder="根据账号搜索"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
        <el-button type="primary" icon="el-icon-plus" @click="newly"
          >新增</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="account" label="账号"></el-table-column>
        <el-table-column prop="roleName" label="角色名称"></el-table-column>
        <el-table-column
          prop="orgName"
          label="机构名称"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column prop="userName" label="姓名"></el-table-column>
        <el-table-column prop="phonenumber" :show-overflow-tooltip="true" label="电话"></el-table-column>
        <el-table-column label="账号状态">
          <template slot-scope="scope">
            <el-switch
              v-if="scope.row.userName != '超级管理员'"
              @change="switchChange($event, scope.$index, scope.row)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="0"
              inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="email" :show-overflow-tooltip="true" label="邮箱"></el-table-column>
        <!-- width="180"  -->
        <el-table-column label="操作" width="500" align="center">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="primary"
              v-if="scope.row.id != 650948883690076311"
              @click="handleEdit(scope.$index, scope.row)"
              icon="el-icon-edit"
              >编辑</el-button
            > -->
            <!-- 自定义指令(权限按钮:设置是否显示) v-if="scope.row.id != 650948883690076311" -->
            <el-button
              size="mini"
              v-if="scope.row.userName != '超级管理员'"
              @click="RoleDialog(scope.row)"
              type="warning"
              icon="el-icon-edit"
              >角色分配</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.userName != '超级管理员'"
              @click="showAllocationDialog(scope.row)"
              type="warning"
              icon="el-icon-s-shop"
              >机构分配</el-button
            >
            <el-button
              size="mini"
              type="danger"
              v-if="scope.row.userName != '超级管理员'"
              @click="ResetPassword(scope.$index, scope.row)"
              icon="el-icon-key"
              >重置密码</el-button
            >
            <el-button
              size="mini"
              v-if="scope.row.userName != '超级管理员'"
              @click="handleDelete(scope.$index, scope.row)"
              type="danger"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="状态修改"> </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新增弹出框 -->
    <el-dialog title="新增用户" :visible.sync="newlya" width="30%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账号">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phonenumber"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newlya = false">取 消</el-button>
        <el-button type="primary" @click="saveEdita">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加机构 -->
    <el-dialog
      title="机构分配"
      class="permission-dialog"
      :visible.sync="permissionDialog"
      @close="onPermissionsDialogClose"
      width="30%"
    >
      <el-form
        ref="permissionFormRules"
        :model="permissionForm"
        label-width="100px"
        class="permission-form"
        label-position="left"
        :rules="permissionFormRules"
      >
        <!-- <span class="notice">一级机构不用选择</span> -->
        <el-form-item label="机构选择" prop="pid">
          <el-cascader
            v-model="selectPids"
            :options="parentPermissionsTree"
            :props="permissionsProps"
            clearable
            @change="onParentNodeChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button @click="onPermissionsDialogClose">取消</el-button>
          <el-button type="primary" @click="submitPermission"
            >添加机构</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加角色 -->
    <el-dialog
      title="角色分配"
      class="permission-dialog"
      :visible.sync="roleDialog"
      @close="onRoleDialogClose"
      width="30%"
    >
      <el-form
        ref="addPermissionFormRules"
        :model="addPermissionForm"
        label-width="100px"
        class="permission-form"
        label-position="left"
        :rules="addPermissionFormRules"
      >
        <!-- <span class="notice">一级机构不用选择</span> -->
        <el-form-item label="角色" prop="roleId">
          <el-select
            v-model="addPermissionForm.roleId"
            multiple
            placeholder="角色"
          >
            <el-option
              :label="item.roleName"
              v-for="item in rolesList"
              :key="item.id"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onRoleDialogClose">取 消</el-button>
        <el-button type="primary" @click="RoleEdita">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userPage,
  userAdd,
  userUpdateStatus,
  userDel,
  userRsetPwd,
  userUpdateOrg,
  orgList,
  roleList,
  userUpdateRole,
} from "@/api/system/system.js";
import { subTk } from "@/api/common.js";
import local from "@/api/common/local.js";
export default {
  name: "guAccount",
  data() {
    return {
      query: {
        account: "",
        pageNum: 1,
        pageSize: 10,
        phonenumber: "",
        status: "",
        userName: "",
      },
      permissionDialog: false,
      roleDialog: false,
      rolesList: [],
      tableData: [],
      // multipleSelection: [],//多选
      editVisible: false,
      newlya: false,
      pageTotal: 0,

      form: {
        delivery: "",
        phonenumber: "",
        email: "",
        id: "",
        account: "",
        userName: "",
      },
      roleId: "",
      idx: -1,
      id: -1,
      selectPids: [], // 选中的父id数组
      parentPermissionsTree: [], // 父级权限列表数据
      permissionsProps: {
        label: "orgName",
        value: "id",
        checkStrictly: true,
      },
      permissionForm: {
        pid: "",
      },
      UserInfo: {},
      temp: {},
      //机构
      permissionFormRules: {
        pid: [{ required: true, message: "请选择机构", trigger: "blur" }],
      },
      addPermissionForm: {
        roleId: [],
      },
      addPermissionFormRules: {
        roleId: [{ required: true, message: "请选择机构", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getData();
    this.Tkl();
  },
  methods: {
    //---添加机构
    onPermissionsDialogClose() {
      this.UserInfo = {};
      this.selectPids = {};
      this.permissionDialog = false;
      this.permissionForm.pid = "";
    },
    //节点选择
    onParentNodeChange(selectPids) {
      this.permissionForm.pid = selectPids[selectPids.length - 1];
    },
    //将数组转为树形结构
    General(list) {
      // filter筛选符合条件的元素，返回一个包含所有符合条件的元素的新数组
      return list.filter(
        (item1) =>
          !list.find((item2, index) => {
            // 如果有父节点
            if (item1.parentId === item2.id) {
              // 放进它父节点的children数组中；如果children不存在，初始化为空数组
              list[index].children = list[index].children || [];
              list[index].children.push(item1);
            }
            // find返回第一个符合条件的元素，找到后，剩余的元素不再判断
            return item1.parentId === item2.id;
          })
      );
    },
    //---end
    async submitPermission() {
      this.$refs["permissionFormRules"].validate(async (valid) => {
        if (!valid) return false;
        this.Tkl();
        const { code, data } = await userUpdateOrg({
          orgId: this.permissionForm.pid,
          userId: this.UserInfo.id,
        });
        if (code == 0) {
          console.log(data);
          this.$message.success("添加成功");
          this.permissionDialog = false;
          this.getData();
          this.permissionForm.pid = "";
        } else {
          this.$message.error("添加失败");
          this.permissionForm.pid = "";
        }
      });
    },
    // 获取临时令牌
    async Tkl() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    // 获取数据
    async getData() {
      // let d = ;
      const { code, data } = await userPage({
        account: this.query.account,
        pageNum: this.query.pageNum,
        pageSize: 10,
      });
      if (code == 0) {
        this.tableData = data.records;
        this.pageTotal = data.total;
        this.RoleList();
        this.OrgList();
      }
    },
    async OrgList() {
      const { code, data } = await orgList({
        status: 0,
      });
      if (code == 0) {
        console.log(data);
        this.selectPids = this.General(data);
        this.parentPermissionsTree = this.selectPids;
        console.log("____", this.selectPids);
      }
    },
    showAllocationDialog(scopeRow) {
      console.log(scopeRow);
      this.UserInfo = scopeRow;
      // console.log();
      this.permissionDialog = true;
      // this.treeDialog = true;
      // this.currentRoleId = scopeRow.id;
      // this.defaultCheckedNodes = [];
      // this._getAllPermissions();
      // this._getCurrentCheckedPermissionIds(scopeRow.permission, this.defaultCheckedNodes);
    },
    onRoleDialogClose() {
      this.UserInfo = {};
      this.roleId = "";
      this.addPermissionForm.roleId = [];
      this.roleDialog = false;
    },
    RoleDialog(scopeRow) {
      this.UserInfo = scopeRow;

      this.roleDialog = true;
    },
    async RoleList() {
      this.rolesList = [];
      const { code, data } = await roleList({
        status: 0,
      });
      if (code == 0) {
        console.log(data);
        data.map(async (item) => {
          if (!(item.id == 1 || item.id == 3))
            this.rolesList.push(Object.assign({}, item));
        });
      }
    },
    //角色修改
    async RoleEdita() {
      console.log(this.UserInfo.id);
      this.$refs["addPermissionFormRules"].validate(async (valid) => {
        if (!valid) return false;
        const { code, msg } = await userUpdateRole({
          roleIds: this.addPermissionForm.roleId.join(","),
          userId: this.UserInfo.id,
        });
        if (code == 0) {
          this.UserInfo = {};
          this.roleId = "";
          this.addPermissionForm.roleId = [];
          this.roleDialog = false;
          this.getData();
          this.$message.success("修改成功");
          this.Tkl();
        } else {
          this.UserInfo = {};
          this.roleId = "";
          this.addPermissionForm.roleId = [];
          this.roleDialog = false;
          this.Tkl();
          // this.getData();
          this.$message.success(msg);
        }
      });
    },
    // 触发搜索按钮
    handleSearch() {
      // this.$set(this.query, 'pageIndex', 1);
      this.getData();
      console.log(this.query);
    },
    // 删除操作
    handleDelete(index, row) {
      console.log("ss", row, index);
      this.Tkl();
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code, data } = await userDel(row.id);
          if (code == 0) {
            console.log(data);
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
          }
        })
        .catch(() => {});
    },
    ResetPassword(index, row) {
      console.log(index);
      this.Tkl();
      // 二次确认删除
      this.$confirm("确定要重置密码吗？", "提示", {
        type: "warning",
      })
        .then(async () => {
          const { code, data, msg } = await userRsetPwd({
            id: row.id,
          });
          this.Tkl();
          if (code == 0) {
            console.log(data);
            this.$message.success(msg);
            // this.tableData.splice(index, 1);
          } else {
            this.$message.error("重置失败");
          }
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.idx = index;
      this.form = JSON.parse(JSON.stringify(row));
      this.editVisible = true;
    },
    async switchChange(e, index, row) {
      console.log(e, index, row);
      let a = {
        id: row.id,
        status: e,
      };
      const { code, data } = await userUpdateStatus(a);
      if (code == 0) {
        console.log(data);
        this.Tkl();
        // this.$set(row, "status", e);
        this.$message.success("更改状态成功");
      } else {
        this.Tkl();
        this.loading = false;
        this.$message.error("更改状态失败");
      }
    },
    // 打开新增弹窗
    newly() {
      this.newlya = true;
    },
    // 确认新增并关闭弹窗
    async saveEdita() {
      this.Tkl();
      this.newlya = false;
      let reg = /^(([a-z]+[0-9]+)|([0-9]+[a-z]+))[a-z0-9]*$/i;
      if (reg.test(this.form.account)) {
        // const _that = this;
        let a = {
          account: this.form.account,
          avatar: "",
          email: this.form.email,
          phonenumber: this.form.phonenumber,
          remark: "",
          sex: "1",
          status: "0",
          userName: this.form.userName,
        };

        const { code, data, msg } = await userAdd(a);
        if (code == 0) {
          console.log(data, "新增的用户信息", msg);
          // Message.succes("新增系统用户成功");
          this.$message({
            message: "新增系统用户成功",
            type: "success",
          });
        } else {
          // _that.$message.error(msg);
          this.$message({
            message: msg,
            type: "error",
          });
        }
      } else {
        this.$message("账号输入格式不正确,应为数字和字母组合");
      }
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form); //表中的第几行
    },
    // 分页导航
    handlePageChange(val) {
      console.log(val);
      this.query.pageNum = val;
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.el-cascader--small {
  width: 240px;
}
</style>
