<template>
  <div class="container">
    <div class="handle-box">
      <el-button type="primary" icon="el-icon-plus" @click="newly"
        >新增</el-button
      >
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      border
      class="table"
      ref="multipleTable"
      header-cell-class-name="table-header"
    >
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="roleKey" label="角色标识"></el-table-column>
      <el-table-column label="角色状态" class="table-hand">
        <template slot-scope="scope">
          <el-switch
            v-if="scope.row.id != '1' && scope.row.id != '3'"
            @change="switchChange($event, scope.$index, scope.row)"
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="0"
            inactive-value="1"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <!-- align="center" -->
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            v-if="scope.row.iSBtn"
            @click="handleEdit(scope.$index, scope.row)"
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.iSbtn"
            @click="showAllocationDialog(scope.row)"
            type="warning"
            icon="el-icon-edit"
            >分配权限</el-button
          >
          <el-button
            size="mini"
            v-if="scope.row.iSbtn"
            @click="handleDelete(scope.$index, scope.row)"
            type="danger"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page="query.pageNum"
        :page-size="query.pageSize"
        :total="pageTotal"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <!-- 角色新增框 -->
    <el-dialog
      title="新增角色"
      width="500px"
      class="dialogForm"
      :visible.sync="addFormVisible"
    >
      <el-form
        ref="permissionForm"
        :model="addForm"
        style="width: 400px; margin: 0 auto"
        :rules="permissionFormRules"
      >
        <el-form-item
          label="角色名称"
          :label-width="formLabelWidth"
          prop="roleName"
        >
          <el-input
            v-model="addForm.roleName"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色标识"
          :label-width="formLabelWidth"
          prop="roleKey"
        >
          <el-input
            v-model="addForm.roleKey"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="addForm.remark"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="rowSave()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="编辑"
      width="500px"
      class="dialogForm"
      :visible.sync="editFormVisible"
    >
      <el-form
        ref="editPermissionForm"
        :model="editForm"
        style="width: 400px; margin: 0 auto"
        :rules="editPermissionFormRules"
      >
        <el-form-item
          label="角色名称"
          :label-width="formLabelWidth"
          prop="roleName"
        >
          <el-input
            v-model="editForm.roleName"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色标识"
          :label-width="formLabelWidth"
          prop="roleKey"
        >
          <el-input
            v-model="editForm.roleKey"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            v-model="editForm.remark"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRowSave()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 分配权限 -->
    <el-dialog title="分配权限" :visible.sync="treeDialog">
      <el-tree
        ref="treeRef"
        :data="treeList"
        :props="treeProps"
        class="tree-dialog"
        show-checkbox
        node-key="id"
        draggable
        :default-expand-all="true"
        :default-checked-keys="defaultCheckedNodes"
      >
      </el-tree>
      <div class="allocation-btn-row">
        <el-button type="success" size="small" @click="closeAllocationDialog"
          >取消</el-button
        >
        <!-- v-permission="'分配权限'" -->
        <el-button
          type="primary"
          icon="el-icon-edit"
          size="small"
          @click="allocationPermission"
          >分配权限</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  rolePage,
  roleAdd,
  roleDel,
  roleUpdateStatus,
  sysMenuListRecursion,
  rolePerSet,
  roleUpdate,
} from "@/api/system/system.js";
import local from "@/api/common/local.js";
import { subTk } from "@/api/common.js";
export default {
  data() {
    return {
      addFormVisible: false,
      editFormVisible: false,
      formLabelWidth: "80px",
      query: {
        pageNum: 1,
        pageSize: 10,
      },
      editForm: {},
      permissionFormRules: {
        roleKey: [{ required: true, message: "角色标识为空", trigger: "blur" }],
        roleName: [
          { required: true, message: "角色名称为空", trigger: "blur" },
        ],
      },
      editPermissionFormRules: {
        roleKey: [{ required: true, message: "角色标识为空", trigger: "blur" }],
        roleName: [
          { required: true, message: "角色名称为空", trigger: "blur" },
        ],
      },
      tableData: [],
      pageTotal: 0,
      //新增角色
      addForm: {
        dataScope: "",
        remark: "",
        roleKey: "",
        roleName: "",
      },
      loading: false,
      //分配权限
      treeDialog: false,
      treeList: [], // 树形数据
      treeProps: {
        label: "menuName",
        children: "children",
      },
      defaultCheckedNodes: [], // 默认选中的节点
    };
  },
  created() {
    this.getData();
    this.tkL();
    // this.$nextTick(() => {
    //   //这个如果要默认全选就必须加，否则会报错“setCheckedNodes”未定义
    //   this.$refs.treeRef(this.defaultCheckedNodes);
    // });
  },
  methods: {
    //分配权限
    async _getAllPermissions() {
      const { code, data } = await sysMenuListRecursion();
      if (code === 0) {
        this.treeList = data;
        //测试-------
        this.$nextTick(() => {
          //这个如果要默认全选就必须加，否则会报错“setCheckedNodes”未定义
          this.$refs.treeRef.setCheckedNodes(this.treeList);
        });
      }
    },
    allocationPermission() {
      let checkedPermissionKeys = this.$refs["treeRef"].getCheckedKeys();
      let checkHalfPermissionKeys = this.$refs["treeRef"].getHalfCheckedKeys();
      // 包含半选和子节点的节点
      let newKeys = [...checkedPermissionKeys, ...checkHalfPermissionKeys];
      let ridsStr = newKeys.join(",");
      this._allocationPermissionByRoleId(this.currentRoleId, ridsStr);
      console.log("____", ridsStr);
    },
    // 分配权限请求
    async _allocationPermissionByRoleId(roleId, rightIds) {
      console.log(roleId, rightIds);
      this.tkL();
      const { code, data } = await rolePerSet({
        menuIds: rightIds,
        roleId: roleId,
      });
      // let { data: res } = await allocationPermissionByRoleId(roleId, {
      //   rightIds: rightIds,
      // });
      if (code === 0) {
        this.$message.success("分配成功");
        console.log(data);
        this.treeDialog = false;
        this.getData();
        // this.getRoleList();
        // // 更新左侧列表tree
        // this.$store.dispatch("renderNavigationPermissionByRoleId");
      }
    },
    closeAllocationDialog() {
      this.treeDialog = false;
    },
    // 获取当前默认选中的权限id
    _getCurrentCheckedPermissionIds(permissions, nodeArr) {
      permissions.forEach((permission) => {
        if (permission.children.length === 0) {
          // push最后一层元素id
          nodeArr.push(permission.id);
        } else {
          this._getCurrentCheckedPermissionIds(permission.children, nodeArr);
        }
      });
    },
    //-------------------
    // 获取临时令牌
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
        console.log(data, "临时令牌");
      }
    },
    async getData() {
      try {
        this.loading = true;
        let d = {
          pageNum: this.query.pageNum,
          pageSize: this.query.pageSize,
        };
        this.tableData = [];
        const { code, data } = await rolePage(d);
        if (code == 0) {
          data.records.map(async (item) => {
            // if (item.id == 1) {
            if (item.roleKey === "admin-platfrom") {
              this.tableData.push(
                Object.assign({}, item, {
                  iSbtn: false,
                  iSBtn: false,
                })
              );
            // } else if (item.id == 3) {
            } else if (item.roleKey === "member") {
              this.tableData.push(
                Object.assign({}, item, {
                  iSbtn: false,
                  iSBtn: false,
                })
              );
            } else {
              this.tableData.push(
                Object.assign({}, item, {
                  iSbtn: true,
                  iSBtn: true,
                })
              );
            }
          });

          this.pageTotal = data.total;
          this.loading = false;
        }
      } finally {
        this.loading = false;
      }
    },
    newly() {
      this.addFormVisible = true;
    },
    showAllocationDialog(scopeRow) {
      console.log(scopeRow);
      this._getAllPermissions();
      this.treeDialog = true;
      // console.log("__", this.treeList);
      this.currentRoleId = scopeRow.id;
      // this.defaultCheckedNodes = [];
      // this._getAllPermissions();
      // this._getCurrentCheckedPermissionIds(scopeRow.permission, this.defaultCheckedNodes);
    },
    //弹框新增
    async rowSave() {
      this.$refs["permissionForm"].validate(async (valid) => {
        if (!valid) return false;

        try {
          const { code, data } = await roleAdd({
            dataScope: this.addForm.dataScope,
            remark: this.addForm.remark,
            roleKey: this.addForm.roleKey,
            roleName: this.addForm.roleName,
          });
          if (code == 0) {
            console.log(data);
            this.addFormVisible = false;
            this.getData();
            this.tkL();
            this.$message.success("新增角色成功");
          }
        } catch (e) {
          this.tkL();
          if (e && e.response) {
            this.$message.error(e.response.data.message);
          }
        }
      });
    },
    //会员状态修改
    async switchChange(e, index, row) {
      console.log(e, index, row);
      const { code, data } = await roleUpdateStatus({
        id: row.id,
        status: e,
      });
      if (code == 0) {
        console.log(data);
        this.tkL();
        this.$set(row, "status", e);
        this.$message.success("更改状态成功");
      } else {
        this.tkL();
        this.$message.error("更改状态失败");
      }
    },
    //分页
    handlePageChange(val) {
      console.log("当前页", val);
      this.query.pageNum = val;
      this.getData();
    },
    //编辑
    handleEdit(index, row) {
      console.log(index, row);
      this.editFormVisible = true;
      this.editForm = JSON.parse(JSON.stringify(row));
    },
    async editRowSave() {
      // console.log("editRowSave", index, row);
      // console.log("editForm", this.editForm);
      const { code, msg } = await roleUpdate({
        dataScope: this.editForm.dataScope,
        remark: this.editForm.remark,
        roleKey: this.editForm.roleKey,
        roleName: this.editForm.roleName,
        id: this.editForm.id,
      });

      if (code == 0) {
        this.$message.success("修改成功");
        this.editFormVisible = false;
        this.editForm = {};
        this.tkL();
        this.getData();
      } else {
        this.$message.error(msg);
        this.tkL();
      }
    },
    //删除角色
    handleDelete(index, row) {
      this.tkL();
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          const { code, msg } = await roleDel({
            roleId: row.id,
          });
          if (code == 0) {
            this.tkL();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData();
          } else {
            this.tkL();
            this.$message.error(msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}
.tree-dialog {
  height: 400px;
  overflow-y: scroll;
}
.red {
  color: red;
}
</style>