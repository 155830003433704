import request from "@/utils/request.js"

//文章分页
export const artPage = ((data) => {
    return request.post('/system-server/sys/art/page', data)
})
//添加文章 
export const artAdd = ((data) => {
    return request.post('/system-server/sys/art/add', data)
})
//文章删除
export const artDel = ((data) => {
    return request.postt('/system-server/sys/art/del', data)
})
//文章信息
export const artInfo = ((data) => {
    return request.postt(`/system-server/sys/art/info/${data}`)
})
//文章修改
export const artUpdate = ((data) => {
    return request.post('/system-server/sys/art/update', data)
})
//文章状态修改
export const artUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/art/update/status', data)
})

//文章分类增加 
export const catAdd = ((data) => {
    return request.post('/system-server/sys/art/cat/add', data)
})
// 文章分类分页
export const catPage = ((data) => {
    return request.post('/system-server/sys/art/cat/page', data)
})
//文章列表
export const catList = ((data) => {
    return request.post('/system-server/sys/art/cat/list', data)
})
//文章分类删除
export const catDel = ((data) => {
    return request.postt('/system-server/sys/art/cat/del', data)
})
//文章分类状态
export const catUpdateStatus = ((data) => {
    return request.postt('/system-server/sys/art/cat/update/status', data)
})
//文章分类修改
export const catUpdate = ((data) => {
    return request.post('/system-server/sys/art/cat/update', data)
})


