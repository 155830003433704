<template>
  <div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.articleType"
          placeholder="审核状态"
          class="handle-select mr10"
          clearable
        >
          <el-option key="0" label="待审核" value="1"></el-option>
          <el-option key="1" label="审核不通过" value="4"></el-option>
          <el-option key="2" label="审核通过" value="3"></el-option>
        </el-select>
        <el-input
          v-model="query.searchCond"
          placeholder="查询会员ID或昵称"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        stripe
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column prop="id" label="服务申请ID"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column
          prop="appStatus"
          :formatter="status"
          label="审核状态"
        ></el-table-column>

        <el-table-column label="操作" class="table-hand" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.$index, scope.row)"
              >查看详情</el-button
            >
            <!-- icon="el-icon-edit" -->

            <!-- <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >详情</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageNum"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="审核状态">
          <el-select
            v-model="form.status"
            placeholder="状态"
            class="handle-select mr10"
          >
            <el-option key="0" label="正常" value="0"></el-option>
            <el-option key="1" label="停用" value="1"></el-option>
          </el-select>
          <!-- <el-input v-model="form.name"></el-input> -->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { auditor } from "@/api/member/member.js";

export default {
  // name: 'basetable',
  data() {
    return {
      query: {
        searchCond: "",
        pageSize: 10,
        pageNum: 1,
        articleType: "",
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取会员服务申请信息
    async getData() {
      let d = {
        appStatus: this.query.articleType,
        cond: this.query.searchCond,
        pageSize: this.query.pageSize,
        pageNum: this.query.pageNum,
      };

      const { code, data } = await auditor(d);
      if (code == 0) {
        console.log(data, "审核通过");
        this.tableData = data.records;
        this.pageTotal = data.total;
      }
    },
    status(row) {
      if (row.appStatus == 0) {
        return "未提交";
      } else if (row.appStatus == 1) {
        return "待审核";
      } else if (row.appStatus == 2) {
        return "审核中";
      } else if (row.appStatus == 3) {
        return "审核通过";
      } else if (row.appStatus == 4) {
        return "审核不通过";
      }
    },
    power(item) {
      console.log(item, "点击当前用");
      // if (this.state == 1) {
      //   this.form.delivery == false;
      // } else {
      //   this.form.delivery == true;
      // }
    },

    // 触发搜索按钮
    // async handleSearch() {
    //   this.getData();
    // },
    handleSearch() {
      this.$set(this.query, "pageNum", 1);
      this.getData();
    },
    // 多选操作
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },

    // 编辑操作
    handleEdit(index, row) {
      console.log("ss", index, row);
      this.$router.push({ path: "/verifyDetails", query: row });
      // this.idx = index;
      // this.form = row;
      // this.editVisible = true;
    },
    // 保存编辑
    saveEdit() {
      this.editVisible = false;
      // this.$message.success(`修改第 ${this.idx + 1} 行成功`);
      this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航

    handlePageChange(val) {
      this.$set(this.query, "pageNum", val);
      this.getData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  /* width: 300px; */
  width: 200px;
  display: inline-block;
}
.table {
  /* width: 100%; */
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.table-hand {
  flex: 1;
}
</style>
